
import React from 'react'
import styled from 'styled-components'

const ModalContainerStyle = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 10000;
	.Modal {
		position: absolute;
		z-index: 10000;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		.ModalBlackout {
			background: rgba(0, 0, 0, 0.7);
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			z-index: 10001;
			cursor: pointer;
			backdrop-filter: blur(3px);
		}
		.ModalContent {
			border-radius: 8px;
			background: ${props => props.theme.modalBackground};
			color: ${props => props.theme.text};
			padding: 0.8em;
			z-index: 10002;
			transition: background 0.3s ease, color 0.3s ease;
			box-shadow: 0 8px 28px rgba(0, 0, 0, 0.28);
		}
	}
`

export class ModalContainer extends React.Component {
	constructor(props) {
		super(props)
		window.modalContainer = this
		this.state = { modals: [] }
		this.activeModalKey = null
	}
	
	// Completely clear all modals
	clearAll() {
		this.setState({ modals: [] })
		this.activeModalKey = null
	}
	
	// Add a new modal, replacing any existing ones
	push(modal) {
		// Store the key of the active modal
		if (modal && modal.key) {
			this.activeModalKey = modal.key
		}
		
		// Replace any existing modals
		this.setState({ modals: [modal] })
	}
	
	// Remove the top modal
	pop() {
		this.setState({ modals: [] })
		this.activeModalKey = null
	}
	
	// Check if a modal with the given key is already displayed
	hasModal(key) {
		return this.activeModalKey === key
	}
	
	updateContent(modal) {
		if (modal && modal.key) {
			if (this.activeModalKey === modal.key || modal.key.includes(this.activeModalKey)) {
				this.setState({ modals: [modal] });
				this.activeModalKey = modal.key;
				return true;
			}
		}
		return false;
	}
	
	render() {
		if(this.state.modals.length === 0) {
			return <React.Fragment/>
		}
		return (
			<ModalContainerStyle>
				{ this.state.modals }
			</ModalContainerStyle>
		)
	}
}

export default class Modal extends React.Component {
	static present(content, id) {
		if (!window.modalContainer) {
			return () => {};
		}
		
		const uniqueKey = id || `modal-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
		const modalElement = <Modal key={uniqueKey}>{ content }</Modal>;
		
		const baseModalId = id?.split('-')[0];
		const isUpdate = baseModalId && window.modalContainer.activeModalKey?.startsWith(baseModalId);
		
		if (window.modalContainer.hasModal(uniqueKey) || isUpdate) {
			window.modalContainer.updateContent(modalElement);
		} else {
			window.modalContainer.push(modalElement);
		}
		
		return () => {
			if (window.modalContainer) {
				window.modalContainer.pop();
			}
		};
	}
	
	componentWillUnmount() {
		// Component cleanup on unmount
	}
	
	render() {
		return (
			<div className="Modal">
				<div className="ModalContent">
					{ this.props.children }
				</div>
				<div className="ModalBlackout" onClick={ e => {
					if (window.modalContainer) {
						window.modalContainer.pop();
					}
				}}/>
			</div>
		)
	}
}