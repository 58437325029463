import React from 'react'
import styled from 'styled-components'
import { useTheme } from '../ThemeContext'

const StyledSkeleton = styled.div`
  position: relative;
  overflow: hidden;
  background: ${props => props.theme.isDarkMode ? 'rgba(70, 70, 70, 0.12)' : 'rgba(240, 240, 240, 0.35)'};
  border-radius: ${props => props.borderRadius || '4px'};
  backdrop-filter: blur(3px);
`

const Skeleton = ({ width, height, borderRadius, margin, className }) => {
  return (
    <StyledSkeleton 
      className={className}
      style={{ 
        width: width || '100%',
        height: height || '20px',
        borderRadius: borderRadius || '4px',
        margin: margin || '0',
        opacity: 0.6
      }}
    />
  )
}

// Predefined skeleton variants
export const TextSkeleton = (props) => (
  <Skeleton height="16px" width={props.width || '100%'} {...props} />
)

export const CircleSkeleton = (props) => (
  <Skeleton 
    width={props.size || '40px'} 
    height={props.size || '40px'} 
    borderRadius="50%" 
    {...props} 
  />
)

export const CardSkeleton = (props) => (
  <Skeleton 
    height={props.height || '100px'} 
    width={props.width || '100%'} 
    borderRadius={props.borderRadius || "8px"} 
    {...props} 
  />
)

// Event Calendar specific skeletons
export const CalendarToolbarSkeleton = ({ children }) => {
  const { isDarkMode } = useTheme();
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '16px',
      padding: '10px 16px',
      borderRadius: '16px',
      backgroundColor: isDarkMode ? 'rgba(53, 53, 53, 0.15)' : 'rgba(245, 245, 245, 0.4)',
      height: '40px',
      backdropFilter: 'blur(4px)',
      border: `1px solid ${isDarkMode ? 'rgba(80, 80, 80, 0.1)' : 'rgba(230, 230, 230, 0.5)'}`,
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.02)'
    }}>
      {children}
    </div>
  );
};

export const CalendarGridSkeleton = styled.div`
  display: grid;
  grid-template-rows: 40px repeat(6, 1fr);
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  height: calc(100% - 56px);
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  opacity: 0.7;
  
  .header {
    grid-column: span 1;
    background-color: ${props => props.theme.isDarkMode ? 'rgba(42, 42, 42, 0.15)' : 'rgba(245, 245, 245, 0.4)'};
    border-bottom: 1px solid ${props => props.theme.isDarkMode ? 'rgba(80, 80, 80, 0.1)' : 'rgba(200, 200, 200, 0.2)'};
  }
  
  .day {
    grid-column: span 1;
    background-color: ${props => props.theme.isDarkMode ? 'rgba(51, 51, 51, 0.1)' : 'rgba(238, 238, 238, 0.2)'};
    position: relative;
    padding: 8px;
    border: 1px solid ${props => props.theme.isDarkMode ? 'rgba(80, 80, 80, 0.1)' : 'rgba(200, 200, 200, 0.15)'};
  }
  
  .weekend {
    background-color: ${props => props.theme.isDarkMode ? 'rgba(41, 41, 41, 0.15)' : 'rgba(232, 232, 232, 0.25)'};
  }
`;

export const EventCardSkeleton = styled(CardSkeleton)`
  padding: 0;
  background-color: ${props => props.theme.isDarkMode ? 'rgba(42, 42, 42, 0.15)' : 'rgba(248, 248, 248, 0.4)'};
  border-radius: 0.75rem;
  margin-bottom: 1.25rem;
  overflow: hidden;
  backdrop-filter: blur(4px);
  border: 1px solid ${props => props.theme.isDarkMode ? 'rgba(70, 70, 70, 0.1)' : 'rgba(230, 230, 230, 0.5)'};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.02);
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 16px 8px 16px;
  }
  
  .content {
    padding: 0 16px 16px 16px;
  }
  
  .tags {
    display: flex;
    gap: 8px;
    padding: 8px 16px 16px 16px;
  }
`;

export const CalendarSkeleton = ({ height }) => {
  const { isDarkMode } = useTheme();
  
  // Generate month name and year for the toolbar
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 
                 'July', 'August', 'September', 'October', 'November', 'December'];
  const currentDate = new Date();
  const currentMonth = months[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear();
  
  return (
    <div style={{ height: height || "calc(90vh - 20px)" }}>
      <CalendarToolbarSkeleton>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ 
            fontSize: '16px', 
            fontWeight: '700',
            color: isDarkMode ? 'rgba(243, 243, 241, 0.4)' : 'rgba(25, 25, 24, 0.4)',
            opacity: 0.6
          }}>
            {currentMonth}
            <span style={{ 
              marginLeft: '5px',
              fontWeight: '500',
              opacity: 0.5,
              color: isDarkMode ? 'rgba(243, 243, 241, 0.3)' : 'rgba(25, 25, 24, 0.3)'
            }}>
              {currentYear}
            </span>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px'
          }}>
            <div style={{
              width: '32px', 
              height: '32px', 
              borderRadius: '6px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.03)',
              opacity: 0.4
            }}>
              <svg width="18" height="18" viewBox="0 0 24 24" fill="none" 
                   opacity="0.6" style={{ stroke: isDarkMode ? '#fff' : '#000' }}>
                <polyline points="15 18 9 12 15 6" strokeWidth="3"></polyline>
              </svg>
            </div>
            <div style={{
              width: '32px', 
              height: '32px', 
              borderRadius: '6px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.03)',
              opacity: 0.4
            }}>
              <svg width="18" height="18" viewBox="0 0 24 24" fill="none" 
                   opacity="0.6" style={{ stroke: isDarkMode ? '#fff' : '#000' }}>
                <polyline points="9 18 15 12 9 6" strokeWidth="3"></polyline>
              </svg>
            </div>
          </div>
          <div style={{
            height: '32px', 
            padding: '0 14px',
            borderRadius: '6px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.03)',
            fontSize: '14px',
            fontWeight: '600',
            color: isDarkMode ? 'rgba(243, 243, 241, 0.4)' : 'rgba(25, 25, 24, 0.4)',
            opacity: 0.4
          }}>
            Today
          </div>
        </div>
      </CalendarToolbarSkeleton>
      <CalendarGridSkeleton>
        {/* Calendar header cells - Day names */}
        {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day, i) => (
          <div key={`header-${i}`} className={`header ${i > 4 ? 'weekend' : ''}`}>
            <div style={{ 
              textAlign: 'right', 
              padding: '10px 8px', 
              fontSize: '14px', 
              opacity: 0.3,
              color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)'
            }}>
              {day}
            </div>
          </div>
        ))}
        
        {/* Calendar day cells */}
        {Array(42).fill(0).map((_, i) => {
          // Generate day numbers (1-31) with some empty cells at start/end
          const dayNum = i - 3 + 1; // Start 3 days before 1
          const showDay = dayNum > 0 && dayNum <= 31;
          
          return (
            <div key={`day-${i}`} className={`day ${i % 7 > 4 ? 'weekend' : ''}`}>
              {showDay && (
                <div style={{ 
                  textAlign: 'right', 
                  padding: '4px',
                  fontSize: '14px',
                  opacity: 0.4,
                  color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)'
                }}>
                  {dayNum}
                </div>
              )}
              
              {/* Some days have event dots */}
              {showDay && Math.random() > 0.7 && (
                <div style={{ display: 'flex', gap: '4px', marginTop: '4px', marginLeft: '4px' }}>
                  <div style={{
                    width: '8px',
                    height: '8px',
                    borderRadius: '50%',
                    backgroundColor: randomEventColor(isDarkMode),
                    opacity: 0.5
                  }} />
                  {Math.random() > 0.5 && (
                    <div style={{
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                      backgroundColor: randomEventColor(isDarkMode),
                      opacity: 0.5
                    }} />
                  )}
                </div>
              )}
            </div>
          );
        })}
      </CalendarGridSkeleton>
    </div>
  );
};

// Helper function to generate random event dot colors
function randomEventColor(isDarkMode) {
  const colors = [
    isDarkMode ? '#448AFF' : '#0D47A1', // Blue
    isDarkMode ? '#69F0AE' : '#1B5E20', // Green
    isDarkMode ? '#FFEE58' : '#F57F17', // Yellow
    isDarkMode ? '#B388FF' : '#4A148C', // Purple
    isDarkMode ? '#FF80AB' : '#880E4F'  // Pink
  ];
  return colors[Math.floor(Math.random() * colors.length)];
}

export const UpcomingEventSkeleton = () => {
  const { isDarkMode } = useTheme();
  
  // Generate a random pastel color for the skeleton card to mimic event cards with different colors
  const colorIndex = Math.floor(Math.random() * 5);
  const colors = [
    { bg: isDarkMode ? 'rgba(60, 145, 230, 0.08)' : 'rgba(230, 242, 255, 0.5)' },  // Blue
    { bg: isDarkMode ? 'rgba(76, 175, 80, 0.08)' : 'rgba(235, 250, 235, 0.5)' },   // Green
    { bg: isDarkMode ? 'rgba(255, 152, 0, 0.08)' : 'rgba(255, 243, 224, 0.5)' },   // Orange
    { bg: isDarkMode ? 'rgba(156, 39, 176, 0.08)' : 'rgba(243, 229, 245, 0.5)' },  // Purple
    { bg: isDarkMode ? 'rgba(244, 67, 54, 0.08)' : 'rgba(255, 235, 238, 0.5)' }    // Red
  ];
  
  return (
    <div style={{
      padding: '1.5rem',
      borderRadius: '0.75rem',
      backgroundColor: colors[colorIndex].bg,
      marginBottom: '1.25rem',
      border: `1px solid ${isDarkMode ? 'rgba(70, 70, 70, 0.1)' : 'rgba(230, 230, 230, 0.5)'}`,
      backdropFilter: 'blur(4px)',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.01)',
      minHeight: '180px'
    }}>
      {/* Event Header - Date and Category */}
      <div style={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        marginBottom: '1rem' 
      }}>
        <Skeleton width="80px" height="1.25rem" borderRadius="4px" opacity={0.4} />
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Skeleton width="60px" height="0.75rem" borderRadius="4px" opacity={0.4} />
          {Math.random() > 0.5 && (
            <Skeleton width="20px" height="0.65rem" borderRadius="4px" opacity={0.3} />
          )}
        </div>
      </div>
      
      {/* Event Title */}
      <Skeleton 
        width="85%" 
        height="1.125rem" 
        margin="0 0 0.75rem 0" 
        borderRadius="4px" 
        opacity={0.4} 
      />
      
      {/* Event Description */}
      <div style={{ marginBottom: '1rem' }}>
        <Skeleton width="100%" height="0.875rem" margin="0 0 6px 0" opacity={0.3} />
        <Skeleton width="92%" height="0.875rem" margin="0 0 6px 0" opacity={0.3} />
        <Skeleton width="45%" height="0.875rem" margin="0 0 6px 0" opacity={0.3} />
      </div>
      
      {/* Tags - Time and Location */}
      <div style={{ 
        display: 'flex', 
        gap: '0.5rem', 
        marginTop: '1rem' 
      }}>
        {Math.random() > 0.3 && ( // Simulate some events having time
          <Skeleton 
            width="90px" 
            height="32px" 
            borderRadius="10px" 
            opacity={0.25} 
          />
        )}
        <Skeleton 
          width="110px" 
          height="32px" 
          borderRadius="10px" 
          opacity={0.25} 
        />
      </div>
    </div>
  );
};

export const NewEventButtonSkeleton = () => {
  const { isDarkMode } = useTheme();
  return (
    <div style={{
      padding: '1.25rem',
      borderRadius: '1rem',
      backgroundColor: isDarkMode ? 'rgba(42, 114, 221, 0.1)' : 'rgba(230, 242, 255, 0.8)',
      marginBottom: '1.25rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: `1px solid ${isDarkMode ? 'rgba(60, 130, 220, 0.2)' : 'rgba(230, 242, 255, 0.9)'}`,
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.03)',
      opacity: 0.5
    }}>
      <Skeleton width="80px" height="18px" opacity={0.6} />
      <Skeleton width="18px" height="18px" opacity={0.6} />
    </div>
  );
};

export const EventDetailsSkeleton = styled.div`
  background-color: ${props => props.theme.isDarkMode ? 'rgba(42, 42, 42, 0.25)' : 'rgba(255, 255, 255, 0.6)'};
  border-radius: 24px;
  padding: 2.5rem;
  width: 55%;
  max-height: 85vh;
  backdrop-filter: blur(10px);
  border: 1px solid ${props => props.theme.isDarkMode ? 'rgba(80, 80, 80, 0.15)' : 'rgba(230, 230, 230, 0.6)'};
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  
  @media (max-width: 1080px) {
    width: 95%;
    padding: 1.5rem;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .content {
    margin-bottom: 2rem;
  }
  
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-top: 2rem;
  }
  
  .buttons {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
  }
`;

export const SkeletonGroup = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'column'};
  gap: ${props => props.gap || '8px'};
  width: ${props => props.width || 'auto'};
  margin: ${props => props.margin || '0'};
`

export default Skeleton