import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ThemeContext, useTheme, lightTheme, darkTheme } from '../ThemeContext'

import Layout from '../components/Layout'
import API from '../API'
import Skeleton, { CardSkeleton, SkeletonGroup } from '../components/Skeleton'
import Toolbar, { ToolbarButton, ToolbarLink } from '../components/Toolbar'

const UsersLayout = styled(Layout)`
	table {
		width: 100%;
		border-collapse: collapse;
		td {
			padding: 4px;
			border: 1px solid ${props => props.theme === darkTheme ? '#555' : '#E5E5E2'};
			font-size: 13px;
			transition: background-color 0.3s ease, border-color 0.3s ease;
		}
		thead {
			th {
				color: ${props => props.theme.text};
				transition: color 0.3s ease;
				padding: 6px 4px;
				text-align: left;
				border-bottom: 2px solid ${props => props.theme === darkTheme ? '#444' : '#E5E5E2'};
			}
		}
		tr {
			cursor: pointer;
			&:hover td {
				background: ${props => props.theme === darkTheme ? '#444' : '#F3F3F1'};
			}
		}
		.InactiveUser {
			color: ${props => props.theme === darkTheme ? '#777' : '#949491'};
		}
		.CompanyLabel {
			font-size: 12px;
			background: ${props => props.theme === darkTheme ? '#444' : '#F3F3F1'};
			color: ${props => props.theme === darkTheme ? '#aaa' : '#61615F'};
			padding: 3px 6px;
			margin: 2px;
			border-radius: 4px;
			transition: background-color 0.3s ease, color 0.3s ease;
			
			&.active {
				background: ${props => props.theme.accent};
				color: white;
			}
			span {
				font-size: 10px;
				opacity: 0.5;
			}
		}
		.Label {
			display: inline;
			padding-left: 5px;
			font-size: 10px;
			font-weight: bold;
			color: ${props => props.theme === darkTheme ? '#aaa' : '#61615F'};
			transition: color 0.3s ease;
		}
	}
	
	/* Search input styles - used in Toolbar */
	.search-filters {
		display: flex;
		flex: 1;
		gap: 12px;
	}
	
	/* Active filter styles */
	.active-filter {
		display: flex;
		align-items: center;
		margin-left: 4px;
		white-space: nowrap;
	}
	
	@media (max-width: 768px) {
		.search-filters {
			flex-direction: column;
		}
	}
`

const CompanyLabel = props => {
	const { isDarkMode } = useTheme();
	const className = [ 'CompanyLabel' ]
	if(props.company.active) {
		className.push('active')
	}
	return (
		<span className={ className.join(' ') }>
			{ API.shared.companyName(props.company.companyId) }
			{ props.company.email && (props.company.email != props.defaultEmail) && <span> ({ props.company.email })</span> }
		</span>
	)
}

const Users = props => {
	const [state, setState] = React.useState({
		showActiveOnly: true,
		users: [],
		nameFilter: '',
		companyFilter: '',
		phoneFilter: '',
	});
	const { isDarkMode } = useTheme();
	const theme = isDarkMode ? darkTheme : lightTheme;

	React.useEffect(() => {
		loadData();
	}, []);

	const loadData = () => {
		API.shared.get('users').then(data => {
			setState(prevState => ({ ...prevState, users: data.users }));
		});
	};

	const toggleActive = () => {
		setState(prevState => ({ 
			...prevState, 
			showActiveOnly: !prevState.showActiveOnly 
		}));
	};

	const updateFilter = (field, value) => {
		setState(prevState => ({ ...prevState, [field]: value }));
	};

	const applyFilters = (users) => {
		return users.filter(user => {
			const matchesActive = state.showActiveOnly ? user.active : true;
			
			// Name/surname filter
			const matchesName = user.name.toLowerCase().includes(state.nameFilter.toLowerCase());
			
			// Phone filter - removes any non-digits for matching
			const phoneFilterDigits = state.phoneFilter.replace(/\D/g, '');
			const userPhoneDigits = String(user.phone).replace(/\D/g, '');
			const matchesPhone = phoneFilterDigits === '' || 
				userPhoneDigits.includes(phoneFilterDigits);
			
			// Company filter
			const matchesCompany = user.companies.some(c =>
				API.shared.companyName(c.companyId).toLowerCase().includes(state.companyFilter.toLowerCase())
			);
			
			return matchesActive && matchesName && matchesCompany && matchesPhone;
		});
	};

	if (!(state && state.users)) {
		return (
			<UsersLayout title="Users" theme={theme}>
				<CardSkeleton height="55px" borderRadius="12px" margin="0 0 20px 0" />
				
				<table>
					<thead>
						<tr>
							<th>ID</th>
							<th>Name</th>
							<th>Phone</th>
							<th>E-Mail</th>
							<th>Company</th>
						</tr>
					</thead>
					<tbody>
						{[...Array(8)].map((_, i) => (
							<tr key={i} style={{ background: 'transparent' }}>
								<td><Skeleton width="25px" height="14px" margin="4px 0" /></td>
								<td><Skeleton width={`${100 + Math.random() * 60}px`} height="14px" margin="4px 0" /></td>
								<td><Skeleton width="90px" height="14px" margin="4px 0" /></td>
								<td><Skeleton width={`${140 + Math.random() * 50}px`} height="14px" margin="4px 0" /></td>
								<td>
									<div style={{ display: 'flex', gap: '4px' }}>
										<Skeleton width="80px" height="20px" borderRadius="4px" />
										{Math.random() > 0.5 && <Skeleton width="70px" height="20px" borderRadius="4px" />}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</UsersLayout>
		);
	}

	const filteredUsers = applyFilters(state.users);

	return (
		<UsersLayout title="Users" theme={theme}>
			<Toolbar 
				leftElements={
					<>
						<ToolbarLink to="/users/new" icon="user-plus">Create User</ToolbarLink>
						<label className="active-filter">
							<input
								type="checkbox"
								checked={state.showActiveOnly}
								onChange={toggleActive}
							/>
							Show Active Only
						</label>
					</>
				}
				rightElements={
					<div className="search-filters">
						<input
							type="text"
							value={state.nameFilter}
							onChange={e => updateFilter('nameFilter', e.target.value)}
							placeholder="Search by name..."
							className="search-input"
						/>
						
						<input
							type="text" 
							value={state.phoneFilter}
							onChange={e => updateFilter('phoneFilter', e.target.value)}
							placeholder="Search by phone..."
							className="search-input"
						/>
						
						<input
							type="text"
							value={state.companyFilter}
							onChange={e => updateFilter('companyFilter', e.target.value)}
							placeholder="Filter by company..."
							className="search-input"
						/>
					</div>
				}
			/>
			<table>
				<thead>
					<tr>
						<th>ID</th>
						<th>Name</th>
						<th>Phone</th>
						<th>E-Mail</th>
						<th>Company</th>
					</tr>
				</thead>
				<tbody>
					{filteredUsers.map(user => (
						<tr key={user.id} onClick={e => props.history.push('/users/' + user.id)}>
							<td>{user.id}</td>
							<td className={user.active ? 'ActiveUser' : 'InactiveUser'}>
								{user.name}
								{user.isRemote && <div className="Label">[REMOTE]</div>}
							</td>
							<td>+{user.phone}</td>
							<td>{user.email}</td>
							<td>
								{user.companies.map(c => (
									<CompanyLabel key={c.companyId} defaultEmail={user.email} company={c} />
								))}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</UsersLayout>
	);
};

export default Users;