import React, { useEffect, useState, useCallback } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styled from 'styled-components'
import {getTagColor} from "../../views/Events";
import { useTheme } from '../../ThemeContext';
import { CalendarSkeleton } from '../../components/Skeleton';
moment.locale("es-es", {
    week: {
        dow: 1 //Monday is the first day of the week.
    }
});

const localizer = momentLocalizer(moment);

const formats = {
    dateFormat: (date, culture, localizer) => localizer.format(date, 'D', culture),
    timeGutterFormat: (date, culture, localizer) => localizer.format(date, 'HH:mm', culture),
}

const EventCalendarLayout = styled.div`
    height: calc(90vh - 20px);

    @media (max-width: 1080px) {
        height: 70vh;
    }
    
    @media (max-width: 768px) {
        height: 60vh;
        
        /* Prevent calendar scrolling on mobile */
        .rbc-calendar {
            overflow: hidden;
            touch-action: none;
        }
        
        .rbc-month-view {
            overflow: hidden;
            height: 100%;
        }
        
        .rbc-month-row {
            overflow: hidden;
        }
        
        .rbc-row-content {
            overflow: hidden;
        }
        
        .rbc-row-bg {
            overflow: hidden;
        }
    }
    
    /* Make calendar days non-clickable and no hover effects */
    .rbc-button-link {
        pointer-events: none;
        cursor: default;
        -webkit-tap-highlight-color: transparent;
        user-select: none;
    }
    
    .rbc-day-bg {
        pointer-events: none;
    }
    
    .rbc-date-cell {
        pointer-events: none;
    }
    
    /* Remove any hover styles */
    .rbc-button-link:hover {
        background-color: transparent !important;
    }
    
    .rbc-date-cell:hover, 
    .rbc-day-bg:hover {
        background-color: inherit !important;
    }
    
    /* Make sure the event container is clickable */
    .rbc-events-container {
        pointer-events: auto;
    }
    
    /* Ensure events are still clickable */
    .rbc-event, .rbc-event * {
        pointer-events: auto !important;
        cursor: pointer !important;
    }
    
    /* Make event dots still clickable in mobile view */
    .event-dot {
        pointer-events: auto !important;
        cursor: pointer !important;
    }
    
    /* Global mobile calendar styles */
    &:has(.mobile-calendar) {
        /* Style the event container for left-aligned dots */
        .rbc-event {
            min-height: 14px !important;
            padding: 0 !important;
            margin: 1px 0 !important;
            background-color: transparent !important;
            border: none !important;
            pointer-events: all !important;
            opacity: 1 !important;
            visibility: visible !important;
            z-index: 10 !important;
            position: relative !important;
            display: block !important;
            text-align: left !important;
        }
        
        .rbc-event-content {
            height: auto !important;
            opacity: 1 !important;
            visibility: visible !important;
            position: relative !important;
            display: block !important;
            text-align: left !important;
        }
        
        /* Left-aligned dot styling */
        .event-dot {
            display: block !important;
            opacity: 0.85 !important;
            visibility: visible !important;
            width: 8px !important;
            height: 8px !important; 
            position: relative !important;
            z-index: 100 !important;
            margin-left: 4px !important;
            margin-top: 3px !important;
            border: none !important;
            box-shadow: none !important;
            transition: transform 0.15s ease !important;
            float: left !important;
        }
        
        .event-dot:hover {
            transform: scale(1.25) !important;
        }
        
        /* Make sure event overlays don't hide the dots */
        .rbc-overlay {
            z-index: 20 !important;
        }
        
        /* Fix for webkit browsers */
        .rbc-row-content {
            z-index: 1 !important;
        }
        
        .rbc-month-row, .rbc-row-bg, .rbc-date-cell {
            overflow: visible !important;
        }
    }

    button {
        border: none !important;
        color: ${props => props.theme.text} !important;
        font-family: Plus Jakarta Sans, sans-serif;
    }

    .rbc-button-link {
        font-weight: normal !important;
        padding: 4px;
        font-size: 14px;
    }
    
    /* Big Calendar dark theme overrides */
    .rbc-calendar {
        color: ${props => props.theme.text};
    }
    
    /* Force all header elements to be transparent */
    .rbc-calendar .rbc-header {
        background-color: transparent !important;
    }

    .rbc-off-range button {
        color: ${props => props.theme.calendarWeekendTextColor} !important;
    }

    .rbc-day-bg {
        background-color: ${props => props.theme.calendarDayBg} !important;
    }

    .rbc-month-row > .rbc-row-bg > :nth-last-child(2),
    .rbc-month-row > .rbc-row-bg > :last-child {
        background-size: auto auto;
        background-color: rgba(255, 255, 255, 0);
        background-image: repeating-linear-gradient(135deg, transparent, transparent 20px, 
          ${props => props.theme.calendarStripedBg} 20px, 
          ${props => props.theme.calendarStripedBg} 40px);
    }

    .rbc-month-row > .rbc-row-content > .rbc-row > .rbc-date-cell:nth-last-child(2) > button,
    .rbc-month-row > .rbc-row-content > .rbc-row > .rbc-date-cell:last-child > button {
        color: ${props => props.theme.calendarWeekendTextColor} !important;
    }

    .rbc-header {
        padding: 10px 8px !important;
        border: none !important;
        text-align: right !important;
        background-color: transparent !important;
    }

    .rbc-header span {
        font-weight: normal;
        color: ${props => props.theme.text};
        font-size: 15px;
        background-color: transparent !important;
    }

    .rbc-header:last-child > span,
    .rbc-header:nth-last-child(2) > span {
        color: ${props => props.theme.calendarWeekendTextColor};
    }

    .rbc-month-view {
        border: none !important;
    }
    
    .rbc-month-view .rbc-month-header {
        background-color: transparent !important;
    }

    .rbc-month-row {
        border: 1px solid ${props => props.theme.calendarBorder} !important;
        border-bottom: none !important;
    }

    .rbc-month-row:last-child {
        border-bottom: 1px solid ${props => props.theme.calendarBorder} !important;
    }

    .striped-bg {
        background-image: linear-gradient(45deg, 
          transparent 25%, 
          ${props => props.theme.calendarStripedBg} 25%, 
          ${props => props.theme.calendarStripedBg} 50%, 
          transparent 50%, 
          transparent 75%, 
          ${props => props.theme.calendarStripedBg} 75%, 
          ${props => props.theme.calendarStripedBg} 100%);
        background-size: 14.14px 14.14px;
    }
    
    .rbc-now > .rbc-button-link {
        background-color: ${props => props.theme.calendarNowBg} !important;
        color: ${props => props.theme.calendarNowText} !important;    
        border-radius: 50%;
        min-width: 25px;
    }
    
    .rbc-date-cell {
        padding-top: 5px;
    }

    /* Week and day view styling */
    .rbc-time-view {
        border: none !important;
    }
    
    .rbc-today {
        background-color: ${props => props.theme.calendarTodayBg};
    }

    .rbc-time-header {
        border-bottom: 1px solid ${props => props.theme.calendarBorder} !important;
    }

    .rbc-time-header-content {
        border: none;
    }

    .rbc-time-header-cell {
        text-align: center;
        background-color: transparent;
        font-weight: normal;
        color: ${props => props.theme.text};
        font-size: 15px;
    }
    
    .rbc-allday-cell, .rbc-allday-cell > .rbc-row-bg > .rbc-day-bg {
        background-color: transparent !important;
    }
    
    .rbc-time-header-cell > .rbc-today {
        background-color: ${props => props.theme.calendarNowBg};
        color: ${props => props.theme.calendarNowText};
        border-radius: 10px;
    }
    .rbc-time-header-cell > .rbc-today > button > span {
        color: ${props => props.theme.calendarNowText};
    }
    
    .rbc-header > .rbc-button-link {
        //padding: 4px;
    
        padding-bottom: 20px;
    }


    .rbc-time-content {
        border-top: none;
        background-color: ${props => props.theme.background};
    }
    
    .rbc-day-slot {
        background-color: ${props => props.theme.calendarDayBg} !important;
    }

    .rbc-time-content > * + * > * {
        border-left: 1px solid ${props => props.theme.calendarBorder} !important;
    }

    .rbc-day-bg + .rbc-day-bg {
        border-left: 1px solid ${props => props.theme.calendarBorder};
    }
    
    .rbc-time-slot {
        background-color: transparent !important;
        border-bottom: 1px dashed ${props => props.theme.calendarDashedBorder} !important;
    }
    
    /* Time view styles */
    .rbc-time-view {
        background-color: ${props => props.theme.background};
    }
    
    .rbc-time-header-content {
        background-color: transparent !important;
    }
    
    /* Force day names to have transparent background */
    .rbc-month-header .rbc-header, 
    .rbc-time-header .rbc-header,
    .rbc-header-row .rbc-header,
    .rbc-day-header,
    .rbc-time-header-cell {
        background-color: transparent !important;
    }
    
    /* Additional override for day header backgrounds */
    .rbc-month-view .rbc-month-header {
        background-color: transparent !important;
    }
    
    .rbc-time-header-content .rbc-row {
        background-color: transparent !important;
    }
    
    .rbc-day-slot:last-child, .rbc-day-slot:nth-last-child(2) {
        background-size: auto auto;
        background-color: rgba(255, 255, 255, 0);
        background-image: repeating-linear-gradient(135deg, 
            transparent, 
            transparent 20px, 
            ${props => props.theme.calendarPatternBg} 20px, 
            ${props => props.theme.calendarPatternBg} 40px);
    }
    
    .rbc-time-slot > .rbc-label {
        font-size: 12px;
        color: ${props => props.theme.text};
    }

    .rbc-current-time-indicator {
        background-color: ${props => props.theme.calendarNowBg} !important;
        border-radius: 14px;
        height: 2px;
    }
    
    /* Calendar event color overrides */
    .rbc-event {
        color: ${props => props.theme.text} !important;
        border-color: ${props => props.theme.calendarBorder} !important;
    }

    /* Styling for the week view's days */
    .rbc-time-view .rbc-allday-cell {
        background-color: ${props => props.theme.calendarStripedBg};
    }

    /* Styling for the time slots */
    .rbc-time-slot {
        background-color: ${props => props.theme.calendarStripedBg};
        border-top: none;
        border-bottom: 1px dashed ${props => props.theme.calendarDashedBorder} !important;
    }

    .rbc-timeslot-group {
        border: none;
    }
    
    .rbc-label {
        font-size: 14px;
        color: ${props => props.theme.text};
    }
    
    .rbc-event {
       // padding: 4px;
    }
    
    .rbc-event-label {
        display: none;
        padding: 0;
    }
    
    .rbc-event-content div {
        height: 100%;
    }
    
    .rbc-event {
        border: none;
    }

    .rbc-events-container {
        margin-right: 0;
    }
`

const NavButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

const ToolbarButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    height: 32px;
    width: 32px;
    border-radius: 6px;
    transition: all 0.15s ease;
    padding: 0;
    
    svg {
        stroke: ${props => props.theme.text};
        height: 18px;
        width: 18px;
        stroke-width: 4;
    }
    
    &:hover {
        background-color: ${props => props.theme.calendarHoverBg};
    }
    
    @media (max-width: 1080px) {
        height: 30px;
        width: 30px;
    }
`;

const TodayButton = styled.button`
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    color: ${props => props.theme.buttonText};
    border: none;
    outline: none;
    padding: 0 14px;
    border-radius: 6px;
    margin-left: 10px;
    cursor: pointer;
    height: 32px;
    transition: all 0.15s ease;
    
    
    @media (max-width: 1080px) {
        font-size: 13px;
        padding: 0 12px;
        height: 30px;
        margin-left: 8px;
    }
    
    @media (max-width: 768px) {
        font-size: 12px;
        padding: 0 8px;
        height: 28px;
        margin-left: 6px;
    }
    
    @media (max-width: 480px) {
        padding: 0 6px;
        margin-left: 4px;
    }
`;

const MonthDisplay = styled.div`
    display: flex;
    align-items: center;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 16px;
    color: ${props => props.theme.text};
    
    .toolbar-month {
        font-weight: 700;
    }
    
    .toolbar-year {
        font-weight: 500;
        color: ${props => props.theme.isDarkMode ? 'rgba(243, 243, 241, 0.6)' : 'rgba(25, 25, 24, 0.6)'};
        margin-left: 5px;
    }
    
    @media (max-width: 1080px) {
        font-size: 14px;
    }
    
    @media (max-width: 768px) {
        font-size: 13px;
        
        .toolbar-month {
            max-width: 90px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        
        .toolbar-year {
            margin-left: 3px;
        }
    }
    
    @media (max-width: 480px) {
        .toolbar-month {
            max-width: 70px;
        }
    }
`;

const ToolbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding: 10px 16px;
    background-color: ${props => props.theme.calendarToolbarBg};
    border-radius: 16px;
    box-shadow: ${props => props.theme.boxShadow};
    color: ${props => props.theme.text};
    
    @media (max-width: 1080px) {
        padding: 8px 12px;
        margin-bottom: 12px;
    }
    
    @media (max-width: 768px) {
        padding: 6px 10px;
        margin-bottom: 8px;
        border-radius: 10px;
    }
`;

const ToolbarContent = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

const CustomToolbar = (props) => {
    const { isDarkMode } = useTheme();
    
    // Force all headers to be transparent via direct DOM manipulation
    useEffect(() => {
        const headers = document.querySelectorAll('.rbc-header');
        headers.forEach(header => {
            header.style.backgroundColor = 'transparent';
        });
    }, []);
    
    const goToBack = () => {
        let mDate = moment(props.date);
        let newDate = mDate.subtract(1, props.view === 'month' ? 'month' : 'week').toDate();
        props.onNavigate('prev', newDate);
    };

    const goToNext = () => {
        let mDate = moment(props.date);
        let newDate = mDate.add(1, props.view === 'month' ? 'month' : 'week').toDate();
        props.onNavigate('next', newDate);
    };

    const goToCurrent = () => {
        let now = new Date();
        props.onNavigate('today', now);
    };

    const label = () => {
        const date = moment(props.date);
        return (
            <>
                <span className="toolbar-month">{date.format('MMMM')}</span>
                <span className="toolbar-year"> {date.format('YYYY')}</span>
            </>
        );
    };

    const changeView = (view) => {
        props.onView(view);
    };

    return (
        <ToolbarContainer>
            <ToolbarContent>
                <MonthDisplay>{label()}</MonthDisplay>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <NavButtonsWrapper>
                        <ToolbarButton onClick={goToBack} aria-label="Previous month">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round">
                                <polyline points="15 18 9 12 15 6"></polyline>
                            </svg>
                        </ToolbarButton>
                        <ToolbarButton onClick={goToNext} aria-label="Next month">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round">
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </ToolbarButton>
                    </NavButtonsWrapper>
                    <TodayButton onClick={goToCurrent}>Today</TodayButton>
                </div>
            </ToolbarContent>
        </ToolbarContainer>
    );
};

const eventStyleGetter = (event, start, end, isSelected) => {
    // Don't use window.innerWidth here as it may not be accurate during SSR
    // Component will handle mobile detection through useState and useEffect
    
    const style = {
        backgroundColor: 'transparent',
        padding: '4px',
        display: 'flex',
        visibility: 'visible',
        opacity: 1,
        zIndex: 5
    };

    return {
        style: style
    };
}

const EventLayout = styled.div`
    border-radius: 8px;
    opacity: 1;
    padding: 8px;
    transition: all 0.2s ease-in-out;
    min-height: 18px;
    cursor: pointer;
    
    &:hover {
        filter: brightness(1.08);
    }
    
    h4 {
        margin: 0;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow-wrap: break-word;
        font-size: 14px;
        font-weight: 700;
        color: inherit;
        line-height: 1.3;
    }
`;

const EventDot = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0;
    margin-left: 4px;
    margin-top: 3px;
    display: block !important;
    position: relative;
    z-index: 50;
    pointer-events: all;
    cursor: pointer;
    opacity: 1 !important;
    transition: all 0.2s ease;
    
    &:hover {
        filter: brightness(1.1);
    }
`;

function Event({ event }) {
    const { isDarkMode, theme } = useTheme();
    // Use the primary tag (first one) for the event background
    const primaryTag = event.tags?.[0];
    // Get the tag color matching current theme
    const { backgroundColor, baseColor, color } = getTagColor(primaryTag?.color || 'default', isDarkMode);
    const isPastEvent = moment().isAfter(moment(event.end));
    
    // Determine if we should show dot view based on window width
    const [showDotView, setShowDotView] = useState(false);
    
    useEffect(() => {
        const handleResize = () => {
            setShowDotView(window.innerWidth <= 768);
        };
        
        handleResize(); // Set initial value
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Check if the text color is dark (to determine border color and styling)
    const isDarkText = color === '#000000';
    
    const eventStyle = isPastEvent
        ? { 
            backgroundColor: backgroundColor,
            opacity: '0.65',  // More faded for past events to make active ones pop more
            color: color
          }
        : { 
            backgroundColor: backgroundColor, 
            color: color
          }
    
    if (showDotView) {
        // For dot view, we use the solid base color for better visibility
        const dotStyle = {
            backgroundColor: baseColor, // Using solid base color for dots to ensure visibility
            display: 'block',
            visibility: 'visible',
            opacity: isPastEvent ? 0.6 : 1, // Reduced opacity for past events
            width: '8px',
            height: '8px',
            position: 'relative',
            zIndex: 100,
            boxShadow: isDarkMode ? '0 0 4px rgba(255, 255, 255, 0.2)' : 'none', // Subtle glow in dark mode
            border: 'none',
            marginLeft: '4px',
            marginTop: '3px'
        };
        
        // Use a very simple approach to maximize visibility
        return (
            <EventDot 
                style={dotStyle} 
                className="event-dot"
                title={event.title} // Add tooltip for accessibility
            />
        );
    }

    return (
        <EventLayout style={eventStyle} darkText={color === '#000000'}>
            <h4>{event.title}</h4>
            {event.tags && event.tags.length > 1 && (
                <div style={{ marginTop: '4px', display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
                    {event.tags.slice(1).map((tag, index) => {
                        // Get secondary tag color with consistent properties
                        const { color: tagTextColor, baseColor } = getTagColor(tag.color || 'default', isDarkMode);
                        // Create a subtle background for the tag
                        const tagBgColor = isDarkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.08)';
                        
                        return (
                            <span 
                                key={index} 
                                style={{ 
                                    fontSize: '10px', 
                                    padding: '2px 6px', 
                                    borderRadius: '4px',
                                    backgroundColor: tagBgColor,
                                    color: tagTextColor,
                                    display: 'inline-block',
                                    fontWeight: '600',
                                    letterSpacing: '0.2px'
                                }}
                            >
                                {tag.name}
                            </span>
                        );
                    })}
                </div>
            )}
        </EventLayout>
    )
}

const EventCalendar = ({ events, organizators, onEventClick, isLoading }) => {
    const { isDarkMode } = useTheme();
    const [calendarEvents, setCalendarEvents] = useState(events);
    const [isMobileView, setIsMobileView] = useState(false);
    
    useEffect(() => {
        setCalendarEvents(events);
    }, [events]);
    
    // Show skeleton loader when events are loading
    if (isLoading) {
        return <CalendarSkeleton />;
    }
    
    // Detect mobile view
    useEffect(() => {
        const checkMobile = () => {
            setIsMobileView(window.innerWidth <= 768);
        };
        
        checkMobile(); // Initial check
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);
    
    // Apply transparent headers effect
    const applyTransparentHeaders = useCallback(() => {
        // Direct DOM manipulation to force transparent backgrounds
        setTimeout(() => {
            const headers = document.querySelectorAll('.rbc-header');
            headers.forEach(header => {
                header.style.backgroundColor = 'transparent';
            });
            
            const headerRow = document.querySelector('.rbc-month-header');
            if (headerRow) {
                headerRow.style.backgroundColor = 'transparent';
            }
            
            // Ensure dot visibility on mobile
            if (isMobileView) {
                const eventDots = document.querySelectorAll('.event-dot');
                eventDots.forEach(dot => {
                    dot.style.display = 'block';
                });
            }
        }, 100);
    }, [isMobileView]);
    
    // Apply transparent headers and enhance dot visibility on mount and when theme changes
    useEffect(() => {
        applyTransparentHeaders();
        
        // Apply additional fixes for mobile dots
        if (isMobileView) {
            // Force a refresh of the event rendering by adding a small delay
            setTimeout(() => {
                const eventDots = document.querySelectorAll('.event-dot');
                eventDots.forEach(dot => {
                    // Force dot visibility with left-aligned styling
                    dot.style.display = 'block';
                    dot.style.visibility = 'visible';
                    dot.style.opacity = '0.85';
                    dot.style.position = 'relative';
                    dot.style.zIndex = '100';
                    dot.style.boxShadow = 'none';
                    dot.style.border = 'none';
                    dot.style.width = '8px';
                    dot.style.height = '8px';
                    dot.style.marginLeft = '4px';
                    dot.style.marginTop = '3px';
                    dot.style.float = 'left';
                });
            }, 500);
        }
    }, [applyTransparentHeaders, isDarkMode, isMobileView]);
    
    return (
        <EventCalendarLayout>
            <Calendar
                localizer={localizer}
                formats={formats}
                events={calendarEvents}
                eventPropGetter={eventStyleGetter}
                startAccessor="start"
                endAccessor="end"
                components={{
                    toolbar: CustomToolbar,
                    event: (props) => <Event {...props} />
                }}
                onSelectEvent={onEventClick}
                showAllEvents
                timeslots={1}
                step={15}
                min={new Date(2021, 0, 0, 8, 0, 0)}
                className={`${isDarkMode ? 'dark-calendar' : ''} ${isMobileView ? 'mobile-calendar' : ''}`}
                selectable={false}
            />
        </EventCalendarLayout>
    );
};

export default EventCalendar;
