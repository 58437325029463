
// ===================================================================

import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { ThemeContext, lightTheme, darkTheme, updatePWAThemeColor } from './ThemeContext'

import App from './components/App'
import { ModalContainer } from './components/Modal'

// ===================================================================

const ThemeContextProvider = ({ children }) => {
  // Check if dark mode preference is saved in localStorage (using 'isDarkMode' for consistency)
  const storedTheme = localStorage.getItem('isDarkMode');
  // Check system preference if no stored preference
  const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  const initialDarkMode = storedTheme !== null ? storedTheme === 'true' : prefersDarkMode;
  
  const [isDarkMode, setIsDarkMode] = useState(initialDarkMode);
  
  // Update PWA theme-color when theme changes
  useEffect(() => {
    updatePWAThemeColor(isDarkMode);
    // Also save preference (using 'isDarkMode' for consistency)
    localStorage.setItem('isDarkMode', isDarkMode.toString());
  }, [isDarkMode]);
  
  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };
  
  // Listen for system theme changes
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      // Only apply system theme if user hasn't set a preference
      if (localStorage.getItem('isDarkMode') === null) {
        setIsDarkMode(e.matches);
      }
    };
    
    // Add listener
    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', handleChange);
    } else {
      // Older browsers
      mediaQuery.addListener(handleChange);
    }
    
    // Cleanup
    return () => {
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', handleChange);
      } else {
        mediaQuery.removeListener(handleChange);
      }
    };
  }, []);
  
  const themeObject = isDarkMode ? darkTheme : lightTheme;
  
  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      <ThemeProvider theme={{...themeObject, isDarkMode}}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

const GlobalStyle = createGlobalStyle`
	@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
	$primary-color: green;
	.material-symbols-outlined {
		display: inline-block;
		text-transform: none;
		letter-spacing: normal;
		word-wrap: normal;
		white-space: nowrap;
		direction: ltr;
		font-variation-settings:
			'FILL' 1,
			'wght' 400,
			'GRAD' 0,
			'opsz' 24
	}
	link[rel="manifest"] {
		--pwacompat-splash-font: 24px 'Plus Jakarta Sans';
	}
	
	:root {
		--background-color: ${props => props.theme.background};
	}

	html {
		font-family: 'Plus Jakarta Sans', sans-serif;
		background: ${props => props.theme.background};
		position: relative;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: ${props => props.theme.text};
		transition: background 0.3s ease, color 0.3s ease;
		-webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape */
		-moz-text-size-adjust: 100%;
		-ms-text-size-adjust: 100%;
		text-size-adjust: 100%;
		touch-action: pan-y; /* Allow vertical scrolling but prevent zooming */
	}
	html, body, #root {
		height: 100%;
		overflow: auto; /* Allow scrolling */
	}
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
	a {
		text-decoration: none;
	}
	.Header {
		padding: 20px 10px;
	}
	.Content {
		padding: 10px;
	}
	button,
	.ButtonWrap a {
		font-family: "Plus Jakarta Sans", sans-serif;
		background-color: ${props => props.theme.buttonBackground};
		color: ${props => props.theme.buttonText};
		padding: 12px 14px;
		border: none !important;
		border-radius: 16px;
		font-size: 14px;
		font-weight: 700;
		cursor: pointer;
		margin: 4px;
		transition: background-color 0.2s ease-in-out;

		&:hover {
			background-color: ${props => props.theme.buttonHoverBackground};
		}

	}
	.ButtonWrap a {
		display: inline-block;
	}
`

// ===================================================================

// Prevent pinch zoom on touch devices, while allowing scrolling
document.addEventListener('touchmove', function(event) {
  if (event.scale !== undefined && event.scale !== 1) { 
    event.preventDefault();
  }
}, { passive: false });

// Modified double-tap prevention to only apply to specific elements
// This allows scrolling to work while still preventing zooming
document.addEventListener('DOMContentLoaded', function() {
  // Apply to interactive elements where double-tap might be an issue
  const nonZoomElements = document.querySelectorAll('a, button, input, select, .UserWrapper, .UserInfo');
  
  nonZoomElements.forEach(element => {
    let lastTapTime = 0;
    element.addEventListener('touchend', function(event) {
      const currentTime = new Date().getTime();
      const tapLength = currentTime - lastTapTime;
      if (tapLength < 300 && tapLength > 0) {
        event.preventDefault();
      }
      lastTapTime = currentTime;
    }, { passive: false });
  });
});

ReactDOM.render(
	<React.Fragment>
		<ThemeContextProvider>
			<GlobalStyle/>
			<App/>
			<ModalContainer/>
		</ThemeContextProvider>
	</React.Fragment>
, document.getElementById("root"))

// ===================================================================
