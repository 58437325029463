// ===================================================================

import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { withRouter } from 'react-router-dom'

import SideMenu from './SideMenu'
import Modal, { ModalContainer } from './Modal'
import { useTheme, lightTheme, darkTheme } from '../ThemeContext'

// ===================================================================

const LayoutStyle = styled.div`
	min-height: 100%;
	background: ${props => props.theme.background};
	color: ${props => props.theme.text};
	transition: background 0.3s ease, color 0.3s ease;
	display: flex;
	
	@keyframes spin {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
	
	@keyframes shimmer {
		0% { background-position: -200% 0; }
		100% { background-position: 200% 0; }
	}
	
	.SideMenu {
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		overflow: hidden;
		z-index: 1000;
		height: 100%;
		transition: all 0.3s ease-in-out;
	}
	
	.ContentBlackout,
	.SideMenuButton {
		display: none;
	}
	
	.MainContent {
		margin-left: ${props => props.sidebarCollapsed ? '80px' : '300px'};
		overflow: auto;
		flex: 1;
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		transition: margin-left 0.3s ease;
		
		& > .Header {
			padding: 20px;
			display: flex;
			align-items: center;
			
			h1 {
				font-size: 26px;
				color: ${props => props.theme.text};
				span {
					font-weight: 400;
				}
			}
		}
		& > .Loading {
			margin: auto auto;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: ${props => props.theme.secondaryText};
			
			.spinner {
				width: 40px;
				height: 40px;
				margin-bottom: 16px;
				border: 3px solid ${props => props.theme === darkTheme 
					? 'rgba(255, 255, 255, 0.1)' 
					: 'rgba(0, 0, 0, 0.1)'};
				border-top-color: ${props => props.theme.accent || '#FF6C64'};
				border-radius: 50%;
				animation: spin 1s linear infinite;
			}
		}
		& > .Content {
			padding: 20px;
			flex: 1;
		}
	}
	
	&.layoutCenter {
		.MainContent > .Content {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
		}
	}
	
	/* MOBILE Layout */
	@media (max-width: 768px) {
		.SideMenu {
			transform: translateX(-100%);
			box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
		}
		
		.SideMenuButton {
			height: 40px;
			display: block;
			padding: 8px;
			background-color: ${props => props.theme.menuActiveBackground};
			border-radius: 50%;
			margin-right: 10px;
			cursor: pointer;
			transition: background-color 0.2s ease;
			
			svg {
				fill: ${props => props.theme.text} !important;
				transition: fill 0.2s ease;
			}
			
			&:hover {
				background-color: ${props => props.theme.menuHoverBackground};
			}
		}
		
		.ContentBlackout {
			display: none;
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(0, 0, 0, 0.5);
			z-index: 900;
			backdrop-filter: blur(2px);
		}
		
		.MainContent {
			margin-left: 0;

			& > .Header {
				padding: 15px 10px 15px 20px;

				h1 {
					font-size: 20px;
				}
			}
		}
		
		&.sideMenuOpen {
			.SideMenu {
				transform: translateX(0);
			}
			.ContentBlackout {
				display: block;
			}
		}
	}
`

// ===================================================================

class LayoutClass extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			sideMenuOpen: false,
			sidebarCollapsed: false
		}
		
		// Reference to the sidebar component
		this.sideMenuRef = React.createRef();
	}
	
	componentDidMount() {
		// Check if sidebar collapsed state is saved in localStorage
		const savedState = localStorage.getItem('sidebarCollapsed');
		if (savedState !== null) {
			this.setState({ sidebarCollapsed: savedState === 'true' });
		}
		
		// Add event listener for sidebar collapse changes
		window.addEventListener('sidebar-collapse-change', this.handleSidebarCollapseChange);
	}
	
	componentWillUnmount() {
		// Remove event listener
		window.removeEventListener('sidebar-collapse-change', this.handleSidebarCollapseChange);
	}
	
	componentDidUpdate(prevProps) {
		if(this.props.location !== prevProps.location) {
			this.hideSideMenu();
		}
	}
	
	handleSidebarCollapseChange = (event) => {
		this.setState({ sidebarCollapsed: event.detail.collapsed });
	}
	
	hideSideMenu() {
		this.setState({ sideMenuOpen: false });
	}
	
	toggleSideMenu() {
		this.setState({ sideMenuOpen: !this.state.sideMenuOpen });
	}
	
	render() {
		const { sideMenuOpen, sidebarCollapsed } = this.state;
		const classNames = [this.props.className];
		
		if(sideMenuOpen) {
			classNames.push('sideMenuOpen');
		}
		
		if(this.props.center) {
			classNames.push('layoutCenter');
		}
		
		return (
			<LayoutStyle 
				className={classNames.join(' ')} 
				sidebarCollapsed={sidebarCollapsed}
			>
				<div className="SideMenu" ref={this.sideMenuRef}>
					<SideMenu />
				</div>
				
				<div 
					className="ContentBlackout" 
					onClick={e => this.hideSideMenu(e)} 
				/>
				
				<div className="MainContent">
					{this.props.title && (
						<div className="Header">
							<div 
								className="SideMenuButton" 
								onClick={e => this.toggleSideMenu(e)}
							>
								<svg 
									xmlns="http://www.w3.org/2000/svg" 
									height="24px" 
									viewBox="0 -960 960 960"
									width="24px" 
									fill="currentColor"
								>
									<path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z"/>
								</svg>
							</div>
							<h1>
								<span>HEX </span>
								{this.props.title}
							</h1>
						</div>
					)}
					
					{this.props.isLoading && (
						<div className="Loading">
							<div className="spinner"></div>
							<div>Loading...</div>
						</div>
					)}
					
					{!this.props.isLoading && (
						<div className="Content">
							{this.props.children}
						</div>
					)}
				</div>
				<ModalContainer/>
			</LayoutStyle>
		);
	}
}

const Layout = withRouter(LayoutClass);

export default function LayoutWithTheme(props) {
	const { isDarkMode } = useTheme();
	const theme = isDarkMode ? darkTheme : lightTheme;
	
	return (
		<ThemeProvider theme={theme}>
			<Layout {...props} />
		</ThemeProvider>
	);
}

// ===================================================================