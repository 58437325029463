import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ThemeContext } from '../ThemeContext'

import Layout from '../components/Layout'
import API from '../API'

const ProfileLayout = styled(Layout)`
  .PageWrapper {
    max-width: 800px;
    margin: 0 auto;
    padding-bottom: 40px;
  }

  .InnerBox {
    text-align: center;
    padding: 40px 20px;
    position: relative;
    margin-bottom: 20px;
    

  }
  
  .UserImage {
    width: 160px;
    height: 160px;
    background: ${props => props.theme.keychainBackground};
    background-size: cover;
    background-position: center center;
    border-radius: 24px;
    margin: 0 auto;
    margin-bottom: 24px;
    box-shadow: 0 8px 16px ${props => props.theme.isDarkMode ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.1)'};
    transition: all 0.3s ease;
    border: ${props => props.theme.isDarkMode ? '1px solid #404040' : 'none'};
    position: relative;
    overflow: hidden;
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(
        to bottom,
        transparent 70%,
        ${props => props.theme.isDarkMode ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.2)'}
      );
      opacity: 0;
      transition: opacity 0.3s ease;
    }
    
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 12px 20px ${props => props.theme.isDarkMode ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.15)'};
      
      &::before {
        opacity: 1;
      }
      
      & + .ChangeUserImage {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .ChangeUserImageReal {
    visibility: hidden;
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
  }
  
  .ChangeUserImage {
    background-color: ${props => props.theme.buttonBackground};
    color: ${props => props.theme.buttonText};
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px ${props => props.theme.isDarkMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'};
    margin-top: 20px;
    position: relative;
    opacity: 0.85;
    
    &:hover {
      background-color: ${props => props.theme.buttonHoverBackground};
      transform: translateY(-2px);
      box-shadow: 0 6px 12px ${props => props.theme.isDarkMode ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.15)'};
    }
    
    &:active {
      transform: translateY(1px);
      box-shadow: 0 2px 4px ${props => props.theme.isDarkMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.1)'};
    }
  }

  .Section {
    background-color: ${props => props.theme.keychainBackground};
    margin: 16px 20px;
    padding: 24px;
    border-radius: 16px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px ${props => props.theme.isDarkMode ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.05)'};

    h3 {
      color: ${props => props.theme.text};
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 600;
      transition: color 0.3s ease;
      position: relative;
      padding-bottom: 12px;
      
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 60px;
        height: 3px;
        background: ${props => props.theme.accent};
        border-radius: 3px;
      }
    }
    
    .ProfileInfo {
      display: flex;
      flex-direction: column;
      gap: 16px;
      
      .InfoRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid ${props => props.theme.keychainBorder};
        padding-bottom: 14px;
        transition: all 0.3s ease;
        
        &:hover {
          border-bottom-color: ${props => props.theme.accent};
        }
        
        .Label {
          font-weight: 500;
          color: ${props => props.theme.secondaryText};
          transition: color 0.3s ease;
          display: flex;
          align-items: center;
          gap: 8px;
          
          .icon {
            font-size: 18px;
            color: ${props => props.theme.tertiaryText};
          }
        }
        
        .Value {
          color: ${props => props.theme.text};
          font-weight: 500;
          transition: color 0.3s ease;
          max-width: 60%;
          text-align: right;
          word-break: break-word;
          background: ${props => props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.03)' : 'rgba(0, 0, 0, 0.02)'};
          padding: 6px 12px;
          border-radius: 6px;
        }
      }
    }
  }
  
  .ToggleContainer {
    background-color: ${props => props.theme.keychainBackground};
    margin: 20px;
    padding: 24px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px ${props => props.theme.isDarkMode ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.05)'};
    
    &:hover {
      box-shadow: 0 6px 16px ${props => props.theme.isDarkMode ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.08)'};
    }
  }

  .ToggleLabel {
    font-weight: 500;
    color: ${props => props.theme.secondaryText};
    font-size: 16px;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    
    .icon {
      font-size: 20px;
      color: ${props => props.theme.tertiaryText};
    }
  }

  .Switch {
    position: relative;
    width: 50px;
    height: 28px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .Slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${props => props.theme.keychainSliderBackground};
      border-radius: 34px;
      transition: all 0.3s;
      box-shadow: inset 0 1px 3px ${props => props.theme.isDarkMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.2)'};

      &::before {
        content: '';
        position: absolute;
        height: 20px;
        width: 20px;
        left: 4px;
        bottom: 4px;
        background-color: ${props => props.theme.keychainSliderKnob};
        border-radius: 50%;
        transition: transform 0.3s, background-color 0.3s;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
      }
    }

    input:checked + .Slider {
      background-color: ${props => props.theme.accent};
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);

      &::before {
        transform: translateX(22px);
        background-color: white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
      }
    }
  }
  
  @media (max-width: 768px) {
    .PageWrapper {
      padding-bottom: 20px;
    }
    
    .InnerBox {
      padding: 24px 16px;
      margin-bottom: 12px;
      
      &::after {
        left: 10px;
        right: 10px;
      }
    }
    
    .UserImage {
      width: 120px;
      height: 120px;
      border-radius: 16px;
      margin-bottom: 16px;
    }
    
    .ChangeUserImage {
      padding: 8px 16px;
      font-size: 13px;
      width: auto;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    
    .Section, .ToggleContainer {
      margin: 12px;
      padding: 16px;
      border-radius: 12px;
    }
    
    .Section {
      h3 {
        font-size: 16px;
        padding-bottom: 10px;
        margin-bottom: 16px;
        
        &::after {
          width: 50px;
          height: 2px;
        }
      }
      
      .ProfileInfo {
        gap: 12px;
        
        .InfoRow {
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
          padding-bottom: 12px;
          
          .Label {
            margin-bottom: 2px;
          }
          
          .Value {
            max-width: 100%;
            width: 100%;
            text-align: left;
            box-sizing: border-box;
            padding: 8px 12px;
          }
        }
      }
    }
    
    .ToggleContainer {
      flex-direction: row;
      align-items: center;
      padding: 16px;
    }
    
    .ToggleLabel {
      font-size: 14px;
      
      .icon {
        font-size: 18px;
      }
    }
    
    .Switch {
      min-width: 46px;
      height: 24px;
      
      .Slider::before {
        height: 16px;
        width: 16px;
        left: 4px;
        bottom: 4px;
      }
      
      input:checked + .Slider::before {
        transform: translateX(22px);
      }
    }
  }
  
  /* Extra-specific styles for very small mobile screens */
  @media (max-width: 360px) {
    .PageWrapper {
      padding-bottom: 10px;
    }
    
    .InnerBox {
      padding: 20px 10px;
    }
    
    .UserImage {
      width: 100px;
      height: 100px;
      border-radius: 14px;
    }
    
    .ChangeUserImage {
      padding: 6px 12px;
      font-size: 12px;
    }
    
    .Section, .ToggleContainer {
      margin: 8px;
      padding: 12px;
    }
    
    .Section h3 {
      font-size: 15px;
    }
    
    .ToggleLabel {
      font-size: 13px;
    }
  }
`

export default class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      quickAccessEnabled: JSON.parse(localStorage.getItem('quickAccessEnabled')) ?? true
    }
    
    this.fileInputRef = React.createRef()
  }

  onFileSelect(e) {
    const file = e.target.files[0]
    if(!file) {
      return
    }

    const formData = new FormData()
    formData.append('file', file)

    API.shared.post('upload-picture', formData).then(data => {        
      console.log({ data })
      location.reload()
    })
  }
  
  onChangeClick() {
    this.fileInputRef.current.click()
  }

  toggleQuickAccess = () => {
    const newStatus = !this.state.quickAccessEnabled
    this.setState({ quickAccessEnabled: newStatus })
    localStorage.setItem('quickAccessEnabled', JSON.stringify(newStatus))
  }

  render() {
    const u = API.shared.user
    
    return (
      <ThemeContext.Consumer>
        {theme => (
          <ProfileLayout title="My Profile">
            <div className="PageWrapper">
              <div className="InnerBox">
                <div className="UserImage" style={{backgroundImage: `url(${u.imagePath || ''})`}} />
                <button className="ChangeUserImage" onClick={() => this.onChangeClick()}>
                  <span className="material-symbols-outlined" style={{ marginRight: '8px', fontSize: '16px' }}>
                    photo_camera
                  </span>
                  <span>Upload New Picture</span>
                </button>
                <input 
                  className="ChangeUserImageReal" 
                  ref={this.fileInputRef} 
                  type="file"
                  accept="image/*"
                  onChange={e => this.onFileSelect(e)}
                />
              </div>
              
              <div className="Section">
                <h3>Profile Information</h3>
                <div className="ProfileInfo">
                  <div className="InfoRow">
                    <div className="Label">
                      <span className="material-symbols-outlined icon">person</span>
                      Name
                    </div>
                    <div className="Value">{u.name || '-'}</div>
                  </div>
                  <div className="InfoRow">
                    <div className="Label">
                      <span className="material-symbols-outlined icon">mail</span>
                      Email
                    </div>
                    <div className="Value">{u.email || '-'}</div>
                  </div>
                  <div className="InfoRow">
                    <div className="Label">
                      <span className="material-symbols-outlined icon">phone</span>
                      Phone
                    </div>
                    <div className="Value">{u.phone ? `+${u.phone}` : '-'}</div>
                  </div>
                  {u.companies && u.companies.length > 0 && (
                    <div className="InfoRow">
                      <div className="Label">
                        <span className="material-symbols-outlined icon">business</span>
                        Company
                      </div>
                      <div className="Value">
                        {u.companies.filter(c => c.active).map(c => (
                          API.shared.companyName(c.companyId)
                        )).join(', ') || '-'}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              
              <div className="ToggleContainer">
                <span className="ToggleLabel">
                  <span className="material-symbols-outlined icon">widgets</span>
                  Quick Access Bar
                </span>
                <label className="Switch">
                  <input
                    type="checkbox"
                    checked={this.state.quickAccessEnabled}
                    onChange={this.toggleQuickAccess}
                  />
                  <span className="Slider"/>
                </label>
              </div>
            </div>
          </ProfileLayout>
        )}
      </ThemeContext.Consumer>
    )
  }
}