import React, {useCallback, useEffect, useState, useRef, useMemo} from "react";
import Gallery from "react-photo-gallery";
import SelectedImage from "./Selected";
import { useTheme } from '../ThemeContext';
import Modal from "./Modal";

const getModalId = (monitorId) => `monitor-modal-${monitorId}`;

const Monitor = ({ url, title, monitorId, type }) => {
    const { isDarkMode } = useTheme();
    const [isHovered, setIsHovered] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [rotationTime, setRotationTime] = useState(4);
    const [images, setImages] = useState([]);
    
    const isMounted = useRef(true);
    const modalCleanup = useRef(null);
    const stableKeyPrefix = useRef(`${monitorId}-${Math.random().toString(36).substr(2, 9)}`);
    
    const [monitorSize, setMonitorSize] = useState(
        type === 'horizontal' 
            ? { width: 600, height: 340 }
            : { width: 320, height: 540 }
    );

    const monitorStyle = {
        width: monitorSize.width,
        height: monitorSize.height,
        background: "#222",
        borderRadius: "16px",
        position: "relative",
        cursor: "pointer",
        filter: isHovered ? "brightness(0.5)" : "brightness(1)",
        transition: "all 0.2s ease-in-out",
        zIndex: "0",
        boxShadow: isDarkMode ? 
            "0 8px 20px rgba(0, 0, 0, 0.4)" : 
            "0 8px 20px rgba(0, 0, 0, 0.15)",
        border: "12px solid #111",
        borderBottomWidth: type === 'horizontal' ? "30px" : "12px",
        borderTopWidth: "12px",
        overflow: "hidden"
    };

    const iframeStyle = {
        transform: isHovered ? "" : "scale(0.98)",
        filter: isHovered ? "grayscale(1) blur(2px)" : "none",
        pointerEvents: "none",
        width: "100%",
        height: "100%",
        border: "none"
    };
    
    const tvStandStyle = {
        position: "absolute",
        bottom: "-20px",
        left: "50%",
        transform: "translateX(-50%)",
        width: type === 'horizontal' ? "120px" : "80px",
        height: type === 'horizontal' ? "20px" : "15px",
        background: "#111",
        borderRadius: "0 0 8px 8px",
        zIndex: "1"
    };

    const overlay = {
        position: "relative",
        width: monitorSize.width,
        height: monitorSize.height,
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        pointerEvents: "auto",
        margin: "10px",
    };

    const titleStyle = {
        position: "absolute",
        top: "calc(50% - 50px)",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: "1",
        pointerEvents: "none",
        color: '#fff',
        transition: "all 0.2s ease-in-out",
        display: isHovered ? 'block' : 'none',
        textShadow: '1px 1px 5px rgba(0, 0, 0, 0.5), 0 0 20px rgba(255, 255, 255, 0.5)',
        fontWeight: "600",
        fontSize: "32px",
        textAlign: "center"
    };

    const changeHeading = {
        position: "absolute",
        top: "calc(50% + 10px)",
        left: "50%",
        transform: "translateX(-50%)",
        width: "100%",
        textAlign: "center",
        display: isHovered ? 'block' : 'none',
        fontSize: "16px",
        fontWeight: "500",
        letterSpacing: "0.5px",
        textTransform: "uppercase",
        color: '#fff',
        textShadow: '1px 1px 5px rgba(0, 0, 0, 0.5), 0 0 20px rgba(255, 255, 255, 0.5)',
        zIndex: "2"
    }

    useEffect(() => {
        isMounted.current = true;
        
        return () => {
            isMounted.current = false;
            
            if (modalCleanup.current) {
                modalCleanup.current();
                modalCleanup.current = null;
            }
            
            const modalId = getModalId(monitorId);
            if (window.modalContainer && window.modalContainer.hasModal(modalId)) {
                window.modalContainer.pop();
            }
        };
    }, [monitorId]);

    useEffect(() => {
        const baseWidthVertical = 320;
        const baseHeightVertical = 540;
        const baseWidthHorizontal = 600;
        const baseHeightHorizontal = 340;
        
        const baseWidth = type === 'horizontal' ? baseWidthHorizontal : baseWidthVertical;
        const baseHeight = type === 'horizontal' ? baseHeightHorizontal : baseHeightVertical;

        const updateMonitorSize = () => {
            if (!isMounted.current) return;
            
            const screenWidth = window.innerWidth;
            let scaledWidth, scaledHeight;

            if (screenWidth < 768) {
                const scaling = type === 'horizontal' ? 0.7 : 0.5;
                scaledWidth = baseWidth * scaling;
                scaledHeight = baseHeight * scaling;
            } else if (screenWidth < 1024) {
                const scaling = type === 'horizontal' ? 0.8 : 0.6;
                scaledWidth = baseWidth * scaling;
                scaledHeight = baseHeight * scaling;
            } else {
                const scaling = type === 'horizontal' ? 1.0 : 0.7;
                scaledWidth = baseWidth * scaling;
                scaledHeight = baseHeight * scaling;
            }

            setMonitorSize({ 
                width: scaledWidth, 
                height: scaledHeight 
            });
        };

        updateMonitorSize();
        window.addEventListener("resize", updateMonitorSize);

        return () => {
            window.removeEventListener("resize", updateMonitorSize);
        };
    }, [type]);

    useEffect(() => {
        const handleEscapeKey = (event) => {
            if ((event.key === 'Escape' || event.keyCode === 27) && isModalOpen) {
                closeModal();
            }
        };
        
        document.addEventListener('keydown', handleEscapeKey);
        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [isModalOpen, closeModal]);


    const createModalContent = useMemo(() => {
        return () => {
            console.log("Creating modal content with selection state:", selectedImages);
            const currentImages = [...(images || [])];
            
            // Function to toggle select all state
            const toggleSelectAll = () => {
                if (selectAll || selectedImages.length > 0) {
                    // If select all is active or some images are selected, deselect all
                    setSelectAll(false);
                    setSelectedImages([]);
                    console.log("Deselected all images");
                } else {
                    // Select all images
                    setSelectAll(true);
                    // Also explicitly select all images by ID to ensure proper submission
                    const allImageIds = currentImages.map(img => {
                        const id = img.key || img.id;
                        const numericId = parseInt(id);
                        return isNaN(numericId) ? id : numericId;
                    }).filter(Boolean);
                    setSelectedImages(allImageIds);
                    console.log("Selected all images:", allImageIds);
                }
            };
            
            return (
                <div style={{
                    width: '800px',
                    maxWidth: '90vw',
                    maxHeight: '85vh',
                    overflow: 'auto'
                }}>
                    <div className="modalHeader" style={{ 
                        padding: '16px',
                        borderBottom: isDarkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <h3 style={{ 
                            margin: 0, 
                            fontSize: '18px', 
                            fontWeight: '600',
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <span>
                                Select images for monitor {title}
                                {currentImages.length > 0 && 
                                    <span style={{ 
                                        fontSize: '14px', 
                                        color: isDarkMode ? '#aaa' : '#777',
                                        marginLeft: '10px'
                                    }}>
                                        ({currentImages.length} images)
                                    </span>
                                }
                            </span>
                            {currentImages.length > 0 && (
                                <button 
                                    onClick={toggleSelectAll}
                                    style={{
                                        marginLeft: '16px',
                                        background: 'transparent',
                                        border: isDarkMode ? '1px solid #444' : '1px solid #ddd',
                                        borderRadius: '4px',
                                        padding: '4px 8px',
                                        fontSize: '12px',
                                        color: isDarkMode ? '#C7C7C5' : '#61615F',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {selectAll || selectedImages.length === currentImages.length ? 'Deselect All' : 'Select All'}
                                </button>
                            )}
                        </h3>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <input 
                                className="seconds"
                                id={`seconds-monitor-${monitorId}`}
                                type="number"
                                placeholder="seconds"
                                defaultValue={rotationTime}
                                onChange={(e) => setRotationTime(e.target.value)}
                                style={{
                                    padding: '10px',
                                    width: '70px',
                                    borderRadius: '8px',
                                    background: isDarkMode ? '#333331' : 'white',
                                    color: isDarkMode ? '#F3F3F1' : '#191918',
                                    border: isDarkMode ? '1px solid #444' : '1px solid #ddd',
                                    fontSize: '14px',
                                    textAlign: 'center'
                                }}
                            />
                            <label htmlFor={`seconds-monitor-${monitorId}`} style={{ 
                                marginLeft: '8px', 
                                marginRight: '16px',
                                color: isDarkMode ? '#C7C7C5' : '#61615F',
                                fontWeight: '500',
                                fontSize: '14px'
                            }}>Sec.</label>
                            <button 
                                onClick={handleSaveRotation} 
                                style={{
                                    background: isDarkMode ? '#F3F3F1' : '#191918',
                                    color: isDarkMode ? '#191918' : 'white',
                                    borderRadius: '8px',
                                    border: 'none',
                                    padding: '10px 16px',
                                    fontWeight: '500',
                                    fontSize: '14px'
                                }}
                                disabled={!currentImages.length}
                            >
                                Save rotation ({selectedImages.length} selected)
                            </button>
                        </span>
                    </div>

                    {modalLoading ? (
                        <div style={{ 
                            padding: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}>
                            <span style={{ fontSize: '16px', marginBottom: '15px' }}>Loading images...</span>
                            <div style={{ 
                                width: '40px', 
                                height: '40px', 
                                border: `3px solid ${isDarkMode ? '#333' : '#eee'}`,
                                borderRadius: '50%',
                                borderTopColor: isDarkMode ? '#eee' : '#333',
                                animation: 'spin 1s linear infinite'
                            }}></div>
                            <style>{`
                                @keyframes spin {
                                    to { transform: rotate(360deg); }
                                }
                            `}</style>
                        </div>
                    ) : (
                        <div style={{ padding: '20px' }}>
                            {currentImages.length > 0 ? (
                                <>
                                    <div style={{
                                        marginBottom: '20px',
                                        padding: '10px',
                                        background: isDarkMode ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.05)',
                                        borderRadius: '8px',
                                        fontSize: '14px',
                                        color: isDarkMode ? '#ddd' : '#444'
                                    }}>
                                        Select images for rotation by clicking on them. Selected images will have a blue checkmark.
                                    </div>
                                    <div data-gallery-id={`gallery-monitor-${monitorId}`}>
                                        <Gallery
                                            photos={currentImages.map((img, index) => ({
                                                ...img,
                                                key: `${stableKeyPrefix.current}-${img.key || index}`
                                            }))}
                                            renderImage={imageRenderer}
                                            margin={8}
                                        />
                                    </div>
                                </>
                            ) : (
                                <div style={{ 
                                    textAlign: 'center',
                                    color: isDarkMode ? '#eee' : '#333',
                                    borderRadius: '8px',
                                    background: isDarkMode ? 'rgba(0,0,0,0.2)' : 'rgba(0,0,0,0.05)',
                                    padding: '40px 20px',
                                    margin: '20px 0'
                                }}>
                                    <div style={{ fontSize: '24px', marginBottom: '15px' }}>
                                        <i className="fas fa-photo-video" style={{ marginRight: '10px' }}></i>
                                        Images Loading...
                                    </div>
                                    <p style={{ fontSize: '14px', opacity: 0.8, marginBottom: '20px' }}>
                                        Please wait while the images are loading. If nothing appears:
                                    </p>
                                    <div style={{ 
                                        display: 'flex', 
                                        flexDirection: 'column', 
                                        alignItems: 'center', 
                                        gap: '10px',
                                        fontSize: '14px'
                                    }}>
                                        <button onClick={() => {
                                            // Force refresh images
                                            setModalLoading(true);
                                            fetchImages();
                                        }} style={{
                                            background: isDarkMode ? '#F3F3F1' : '#191918',
                                            color: isDarkMode ? '#191918' : 'white',
                                            borderRadius: '8px',
                                            border: 'none',
                                            padding: '10px 16px',
                                            fontWeight: '500',
                                            fontSize: '14px',
                                            cursor: 'pointer'
                                        }}>
                                            <i className="fas fa-sync-alt" style={{ marginRight: '8px' }}></i>
                                            Retry Loading Images
                                        </button>
                                        <p style={{ fontSize: '12px', opacity: 0.7, marginTop: '10px' }}>
                                            Monitor ID: {monitorId} | Type: {type}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            );
        };
    }, [images, modalLoading, isDarkMode, monitorId, title, rotationTime, type, imageRenderer, handleSaveRotation, stableKeyPrefix, selectedImages, selectAll, setSelectAll, setSelectedImages]);

    const closeModal = useCallback(() => {
        setIsModalOpen(false);
        if (modalCleanup.current) {
            modalCleanup.current();
            modalCleanup.current = null;
        }
    }, []);

    const fetchImages = useCallback(async () => {
        if (!isMounted.current) return;
        
        setModalLoading(true);
        
        try {
            const response = await fetch('https://hex.lv/chromecast/?getImages', {
                method: 'GET',
                cache: 'no-cache',
            });
            
            if (!isMounted.current) return;
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();
            
            if (!isMounted.current) return;
            
            let processedImages = [];
            
            if (data && Array.isArray(data) && data.length > 0) {
                processedImages = data.map((image) => {
                    return {
                        src: image.url || '',
                        width: parseInt(image.width || 300),
                        height: parseInt(image.height || 200),
                        key: String(image.id || Math.random().toString(36).substr(2, 9))
                    };
                }).filter(img => img.src && img.width > 0 && img.height > 0);
            } else if (data && typeof data === 'object') {
                if (data.images && Array.isArray(data.images)) {
                    processedImages = data.images.map(img => ({
                        src: img.url || '',
                        width: parseInt(img.width || 300),
                        height: parseInt(img.height || 200),
                        key: String(img.id || Math.random().toString(36).substr(2, 9))
                    })).filter(img => img.src);
                } else {
                    Object.entries(data).forEach(([key, value]) => {
                        if (value && typeof value === 'object' && value.url) {
                            processedImages.push({
                                src: value.url,
                                width: parseInt(value.width || 300),
                                height: parseInt(value.height || 200),
                                key: String(value.id || key || Math.random().toString(36).substr(2, 9))
                            });
                        }
                    });
                }
            }
            
            if (processedImages.length === 0) {
                try {
                    const fallbackResponse = await fetch(`https://hex.lv/chromecast/monitor_images.php?id=${monitorId}`, {
                        method: 'GET',
                        cache: 'no-cache',
                    });
                    
                    if (fallbackResponse.ok) {
                        const fallbackData = await fallbackResponse.json();
                        
                        if (fallbackData && Array.isArray(fallbackData) && fallbackData.length > 0) {
                            processedImages = fallbackData.map(img => {
                                return {
                                    src: img.url || '',
                                    width: parseInt(img.width || 300),
                                    height: parseInt(img.height || 200),
                                    key: String(img.id || Math.random().toString(36).substr(2, 9))
                                };
                            }).filter(img => img.src && img.width > 0 && img.height > 0);
                        }
                    }
                } catch (fallbackError) {
                    console.error(`Fallback fetch failed for monitor ${monitorId}:`, fallbackError);
                }
            }
            
            if (isMounted.current) {
                setImages(processedImages);
            }
        } catch (error) {
            console.error(`Error fetching images for monitor ${monitorId}:`, error);
            
            if (isMounted.current) {
                setImages([]);
            }
        } finally {
            if (isMounted.current) {
                setModalLoading(false);
            }
        }
    }, [monitorId]);

    useEffect(() => {
        if (isModalOpen && modalCleanup.current) {
            const modalId = getModalId(monitorId);
            modalCleanup.current = Modal.present(createModalContent(), modalId);
        }
    }, [isModalOpen, images, modalLoading, monitorId, createModalContent]);

    const openModal = useCallback(async () => {
        setIsModalOpen(true);
        setModalLoading(true);
        
        const modalId = getModalId(monitorId);
        modalCleanup.current = Modal.present(createModalContent(), modalId);
        
        fetchImages();
    }, [monitorId, createModalContent, fetchImages]);

    const handleToggleModal = useCallback(() => {
        if (isModalOpen) {
            closeModal();
        } else {
            openModal();
        }
    }, [isModalOpen, closeModal, openModal]);

    const handleSelectedKey = useCallback((key) => {
        let imageId;
        
        try {
            imageId = parseInt(key);
            if (isNaN(imageId)) {
                imageId = key;
            }
        } catch (error) {
            return;
        }
        
        console.log(`Selecting image ID: ${imageId}`);
        
        setSelectedImages((prevSelectedImages) => {
            const newSelection = prevSelectedImages.includes(imageId)
                ? prevSelectedImages.filter(image => image !== imageId)
                : [...prevSelectedImages, imageId];
                
            console.log(`Updated selection:`, newSelection);
            return newSelection;
        });
    }, []);

    const imageRenderer = useCallback(({ index, left, top, key, photo }) => {
        let imageKey = key;
        
        if (key && typeof key === 'string' && key.includes('-')) {
            const parts = key.split('-');
            if (parts.length >= 2) {
                imageKey = parts[parts.length - 1];
            }
        }
        
        // Check both numeric and string versions of the key for selection state
        const numericKey = parseInt(imageKey);
        const isNumericKeySelected = !isNaN(numericKey) && selectedImages.includes(numericKey);
        const isStringKeySelected = selectedImages.includes(imageKey);
        const isImageSelected = isNumericKeySelected || isStringKeySelected || selectAll;
        
        // For debugging
        if (isImageSelected) {
            console.log(`Rendering selected image: key=${imageKey}, numeric=${numericKey}, selected=${isImageSelected}`);
        }
        
        return (
            <SelectedImage
                key={`selected-${key}`}
                selected={isImageSelected}
                imageKey={imageKey}
                margin={"2px"}
                index={index}
                photo={photo}
                left={left}
                top={top}
                onSelectedKey={handleSelectedKey}
            />
        );
    }, [selectAll, selectedImages, handleSelectedKey]);


    const handleSaveRotation = useCallback(async () => {
        console.log("Save rotation called, selected images:", selectedImages);
        
        if (!selectedImages || selectedImages.length === 0) {
            alert("Please select at least one image for the rotation");
            return;
        }
        
        try {
            const formData = new FormData();
            const keysString = selectedImages.join(',');
            
            console.log("Saving images with keys:", keysString);
            
            formData.append('keys', keysString);
            formData.append('time', rotationTime * 1000);
            formData.append('monitor', monitorId);
            
            // Log what's being sent
            for (let pair of formData.entries()) {
                console.log(`Form data: ${pair[0]} = ${pair[1]}`);
            }
            
            const response = await fetch('https://hex.lv/chromecast/', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log("Save response:", result);
            
            closeModal();
            
            try {
                await fetch('http://194.19.232.194:3388/chromecast/chromecaster/?refresh=true', {
                    method: 'GET',
                    mode: 'no-cors',
                });
            } catch (refreshError) {
                // Continue with page reload even if refresh fails
            }
            
            alert("Rotation saved successfully! Page will reload to show changes.");
            window.location.reload();
        } catch (error) {
            console.error("Error saving rotation:", error);
            alert("Error saving rotation: " + error.message);
        }
    }, [selectedImages, monitorId, rotationTime, closeModal]);

    return (
        <div 
            style={overlay}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={handleToggleModal}
        >
            {isHovered && (
                <>
                    <div style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: "rgba(0, 0, 0, 0.6)",
                        zIndex: 1,
                        borderRadius: "4px"
                    }}></div>
                    <h1 style={titleStyle}>
                        {title}
                    </h1>
                    <h2 style={changeHeading}>
                        Click to configure
                    </h2>
                </>
            )}
            <div style={monitorStyle}>
                <iframe
                    src={url}
                    style={iframeStyle}
                    title="Chromecast feed"
                ></iframe>
                
                {/* TV manufacturer logo for horizontal */}
                {type === 'horizontal' && (
                    <div style={{
                        position: "absolute",
                        bottom: "10px",
                        left: "50%",
                        transform: "translateX(-50%)",
                        color: "#444",
                        fontSize: "10px",
                        fontWeight: "bold",
                        letterSpacing: "1px",
                        textTransform: "uppercase",
                        opacity: 0.7
                    }}>HEX TV</div>
                )}
                
                {/* TV legs for horizontal monitor */}
                {type === 'horizontal' ? (
                    <>
                        <div style={{
                            position: "absolute",
                            bottom: "-25px",
                            left: "20%",
                            width: "40px",
                            height: "25px",
                            background: "#111",
                            borderRadius: "0 0 8px 8px"
                        }}></div>
                        <div style={{
                            position: "absolute",
                            bottom: "-25px",
                            right: "20%",
                            width: "40px",
                            height: "25px",
                            background: "#111",
                            borderRadius: "0 0 8px 8px"
                        }}></div>
                    </>
                ) : (
                    <div style={tvStandStyle}></div>
                )}
                
                {/* Power indicator light */}
                <div style={{
                    position: "absolute",
                    bottom: type === 'horizontal' ? "8px" : "6px",
                    right: type === 'horizontal' ? "20px" : "10px",
                    width: "6px",
                    height: "6px",
                    borderRadius: "50%",
                    background: isHovered ? "#ff5f5f" : "#2ecc71",
                    boxShadow: isHovered ? "0 0 5px #ff5f5f" : "0 0 5px #2ecc71",
                    transition: "all 0.2s ease-in-out"
                }}></div>
            </div>
        </div>
    );
};

export default Monitor;