
import React from 'react'
import '@babel/polyfill'
import styled from 'styled-components'

import Layout from '../components/Layout'
import Monitor from '../components/Monitor'
import Toolbar, { ToolbarButton } from '../components/Toolbar'
import { useTheme } from '../ThemeContext'
import Modal from '../components/Modal'

import API from "../API";
import {useDropzone} from "react-dropzone";
import imageCompression from "browser-image-compression";

const MonitorsLayout = styled(Layout)`
    .container {
        display: flex;
        flex-wrap: wrap;  
        gap: 20px;
        justify-content: center;
        margin-bottom: 40px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-shadow: ${props => props.theme.isDarkMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.2)'} 0 0 1px;
    }
    
    /* Location section styling */
    .location-section {
        width: 100%;
        padding: 20px 0;
        margin-top: 20px;
    }
    
    .location-header {
        width: 100%;
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 600;
        color: ${props => props.theme.secondaryText};
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }
    
    .location-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        justify-content: center;
        padding: 10px 0;
    }
    
    /* Special layout for the monitors */
    @media (min-width: 1200px) {
        /* Kitchen location */
        .kitchen-container {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: flex-start;
            gap: 8px;
            width: 100%;
            flex-wrap: wrap;
        }
        
        /* Make all monitors in the kitchen display in one line */
        .kitchen-container > div {
            transition: all 0.2s ease;
        }
        
        /* Adjust vertical monitors group */
        .vertical-monitors-row {
            display: flex;
            justify-content: center;
            gap: 8px;
            margin: 0;
            align-items: flex-end;
            flex: 0 0 auto;
        }
        
        /* Roadgames location */
        .roadgames-container {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            padding: 10px 0 10px 8px;
            position: relative;
        }
        
        .roadgames-container > div {
            transition: transform 0.3s ease;
            max-width: 750px;
        }
        
        /* Remove hover effects on monitors */
    }
    
    /* For medium screens, display monitors in a more compact layout */
    @media (min-width: 768px) and (max-width: 1199px) {
        .kitchen-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 8px;
        }
        
        /* Reset transformations for tablet view */
        .kitchen-container div:nth-child(1),
        .kitchen-container div:nth-child(2),
        .kitchen-container div:nth-child(3),
        .kitchen-container div:nth-child(4) {
            transform: none;
            margin: 0;
        }
    }
    
    /* Properly size monitors for one row layout */
    .kitchen-container > div {
        /* Adjust individual monitors to fit in a single row */
        display: inline-flex;
        align-items: flex-end;
        margin-bottom: 15px;
    }
    
    /* Make each monitor proportionally sized to fit in one row */
    @media (min-width: 1400px) {
        .kitchen-container div:nth-child(1),
        .kitchen-container div:nth-child(2),
        .kitchen-container div:nth-child(3) {
            transform: scale(0.95);
            margin: 0 -10px;
        }
        
        .kitchen-container div:nth-child(4) {
            transform: scale(0.85);
            margin-left: 5px;
        }
    }
    
    /* Adjust for different desktop widths */
    @media (min-width: 1200px) and (max-width: 1399px) {
        .kitchen-container div:nth-child(1),
        .kitchen-container div:nth-child(2),
        .kitchen-container div:nth-child(3) {
            transform: scale(0.9);
            margin: 0 -12px;
        }
        
        .kitchen-container div:nth-child(4) {
            transform: scale(0.8);
            margin-left: 0;
        }
    }
    
    /* Keep roadgames monitor at a good size */
    .roadgames-container > div {
        max-width: 800px;
        transform: scale(0.9);
        margin-left: 0;
    }
  
    .navigation {
        padding: 18px 24px;
        width: 100%;
        background: ${props => props.theme.isDarkMode ? 
            'rgba(35,35,35,0.8)' : 
            'rgba(245,245,245,0.8)'};
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 24px;
        box-shadow: ${props => props.theme.isDarkMode ? 
            '0 6px 20px rgba(0, 0, 0, 0.2)' : 
            '0 6px 20px rgba(0, 0, 0, 0.05)'};
        backdrop-filter: blur(10px);
    }
    
    .navigation-title {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
        color: ${props => props.theme.text};
    }
      
    .navigation div {
        display: flex;  
        gap: 12px;
        align-items: center;
    }
  
    button {
        margin: 0;
        padding: 10px 16px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        border-radius: 8px;
        border: none;
        background: ${props => props.theme.buttonBackground};
        color: ${props => props.theme.buttonText};
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;
    }
    
    button i {
        margin-right: 6px;
        font-size: 15px;
    }
    
    button:disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }
  
    button:hover:not(:disabled) {
        background: ${props => props.theme.buttonHoverBackground};
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.75);
        display: flex;
        overflow: auto;
        backdrop-filter: blur(8px);
        animation: fadeIn 0.2s ease-out;
    }
    
    @keyframes fadeIn {
        from { opacity: 0; }
        to { opacity: 1; }
    }
    
    @keyframes slideUp {
        from { transform: translateY(30px); opacity: 0; }
        to { transform: translateY(0); opacity: 1; }
    }
  
    .modalContent {
        width: 60%;
        margin: auto;
        padding: 0;
        background: ${props => props.theme.modalBackground};
        color: ${props => props.theme.text};
        border-radius: 12px;
        box-shadow: 0 15px 40px ${props => props.theme.isDarkMode ? 'rgba(0, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.2)'};
        overflow: hidden;
        animation: slideUp 0.3s ease-out;
    }
  
    .uploadModal {
        width: 35%;
        max-width: 600px;
    }
  
    .modal h3 {
        padding: 18px 24px;
        margin: 0;
        font-weight: 600;
        font-size: 20px;
        letter-spacing: 0.3px;
    }
  
    .dropzone {
        justify-content: center;
        display: flex;
        padding: 0 24px 24px;
    }
  
    .dropzone label {
        width: 100%;
        height: 300px;
        border-radius: 16px;
        border: 2px dashed ${props => props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.1)'};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: ${props => props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.02)' : 'rgba(0, 0, 0, 0.01)'};
        transition: all 0.3s ease;
    }
  
    .dropzone label:hover {
        background: ${props => props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.03)'};
        transform: translateY(-3px);
        box-shadow: ${props => props.theme.isDarkMode ? 
            '0 10px 25px rgba(0, 0, 0, 0.3)' : 
            '0 10px 25px rgba(0, 0, 0, 0.06)'};
        border-color: ${props => props.theme.isDarkMode ? 
            'rgba(255, 255, 255, 0.25)' : 
            'rgba(0, 0, 0, 0.15)'};
    }
  
    .dropzone label div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: transparent;
        padding: 24px;
    }
  
    .dropzone label div i {
        font-size: 48px;
        color: ${props => props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.5)'};
        margin-bottom: 16px;
    }
  
    .dropzone label div p {
        color: ${props => props.theme.secondaryText};
        font-size: 16px;
        margin: 6px 0;
        text-align: center;
        line-height: 1.5;
    }
    
    .dropzone label div p b {
        color: ${props => props.theme.text};
        font-weight: 600;
    }
  
    .dropzone label input {
        display: none;
    }
  
    .modalActions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 24px;
        border-top: 1px solid ${props => props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.06)'};
        background: ${props => props.theme.isDarkMode ? 'rgba(0, 0, 0, 0.15)' : 'rgba(0, 0, 0, 0.02)'};
    }
    
    .saveBtn {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
    }
  
    .modalActions span {
        font-size: 14px;
        font-weight: 500;
        color: ${props => props.theme.secondaryText};
    }
  
    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 30px;
    }
  
    .loading span {
        font-size: 18px;
        color: ${props => props.theme.text};
    }
  
  .modalHeader {
    position: sticky;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    padding: 0;
    z-index: 5;
    background: ${props => props.theme.isDarkMode ? 
        'rgba(35,35,35,0.95)' : 
        'rgba(245,245,245,0.95)'};
    border-bottom: 1px solid ${props => props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'};
    backdrop-filter: blur(8px);
  }
  
  .modalHeader h3 {
    margin: 0;
    padding: 18px 20px;
    font-size: 18px;
  }
  
  .modalHeader button {
    margin-right: 15px;
    padding: 8px 14px;
    font-weight: 500;
  }
  
  .imageOverlay {
    background-color: rgba(0, 0, 0, 0.8);
    max-height: 240px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: white;
    padding: 2px;
    font-size: 90%;
    pointer-events: auto;
    cursor: pointer;
  }
  
  .seconds {
    padding: 10px;
    width: 70px;
    margin-right: 8px;
    border-radius: 8px;
    border: 1px solid ${props => props.theme.border || (props => props.theme.isDarkMode ? '#444444' : '#E5E5E2')};
    background: ${props => props.theme.contentBackground};
    color: ${props => props.theme.text};
    font-size: 14px;
    text-align: center;
  }
  
  .secondsLabel {
    margin-right: 16px;
    font-weight: 500;
    color: ${props => props.theme.secondaryText};
  }
  
  .hoverText {
    position: absolute;
    bottom: 10%;
    z-index: 10;
    color: #fff;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    display: block; /* You need to set this based on your logic */
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    text-align: center;
    font-size: 20px;
  }
  
  @media(max-width: 1080px) {
    .modalHeader {
      flex-direction: column;
      justify-content: center;
      height: 100px;
      padding: 10px 20px;
      text-align: center;
      position: sticky;
    }
    
    .modalHeader h3 {
      align-items: center;
      margin: 10px 0;
      text-align: center;
      font-size: 20px;
      padding-left: 0;
    }
    
    .modalHeader h3:before {
      display: none;
    }

    .modalHeader button {
      margin: 0 0 10px 0;
      height: 40px;
      width: 100%;
      justify-content: center;
    }
    
    .modalContent {
      width: 90%;
    }
    
    .container {
      justify-content: center;
    }
    
    /* Navigation responsive */
    .navigation {
      padding: 12px;
    }
    
    .navigation div {
      width: 100%;
      gap: 8px;
      justify-content: space-between;
    }
    
    .navigation button {
      flex: 1;
      justify-content: center;
      font-size: 13px;
      padding: 8px 10px;
    }
    
    /* Location responsive styles */
    .location-section {
      padding: 10px 0;
      margin-top: 20px;
    }
    
    .location-header {
      font-size: 16px;
      margin-bottom: 15px;
    }
    
    .kitchen-container, 
    .roadgames-container {
      gap: 15px;
      padding-left: 0;
      justify-content: center;
    }
  }
  
  @media(max-width: 768px) {
    .uploadModal {
      width: 90%;
    }
    
    .dropzone label {
      height: 250px;
    }
    
    /* Adjust vertical monitors for mobile */
    .vertical-monitors-row {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
    
    /* Reset all transformations on mobile */
    .kitchen-container div {
      transform: none !important;
      margin: 0 !important;
    }
    
    /* Make sure monitors have appropriate spacing */
    .kitchen-container,
    .roadgames-container {
      gap: 30px;
    }
  }
  
  @media(max-width: 600px) {
    .location-section {
      padding: 15px 10px;
    }
    
    .location-header {
      font-size: 18px;
    }
    
    button {
      padding: 8px 15px;
      font-size: 13px;
    }
    
    .location-section:hover {
      transform: none;
    }
    
    /* More compact layout for very small screens */
    .vertical-monitors-row {
      gap: 25px;
    }
  }
  
  .namedaySettings {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    gap: 10px;
  }
  
  .namedayLabel {
    padding-bottom: 10px;
  }
  
  .notSelected {
    background: ${props => props.theme.menuActiveBackground};
    border: 1px solid ${props => props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'} !important;
    color: ${props => props.theme.text};
  }
  
  .selected {
    background: ${props => props.theme.buttonBackground};
    color: ${props => props.theme.buttonText};
    border: 1px solid ${props => props.theme.buttonBackground} !important;
  }
  
  .settingsContent {
    padding: 16px;
  }
  
  .namedaySettings button, 
  #bigtvSettings button {
    margin-right: 8px;
    font-size: 14px;
    padding: 8px 16px;
  }
  
  
  
`
const Dropzone = ({ onFileChange, selectedFiles }) => {
    const { isDarkMode } = useTheme();
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            onFileChange({ target: { files: acceptedFiles } });
        },
    });

    return (
        <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <label>
                <div>
                    {selectedFiles.length > 0 ? (
                        selectedFiles.map((file, i) => (
                            <p key={i}>
                                <b>{file.name}</b>
                            </p>
                        ))
                    ) : (
                        <>
                            <i className="fas fa-cloud-upload-alt"></i>
                            <p>
                                <b>Click to upload</b> or drag and drop
                            </p>
                        </>
                    )}
                    <p>Only PNG, JPG, JPEG, or GIF types are allowed</p>
                </div>
            </label>
        </div>
    );
};
export default class Monitors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isUploadModalOpen: false,
            isSettingsModalOpen: false,
            isLoading: false,
            selectedFiles: [],
            imagesUploaded: 0,
            namedayMonitor: 3,
            bigTv: 1,
            roadgamesTv: 1,
        };
    }

    async getSettings() {
        try {
            const response = await fetch('https://hex.lv/chromecast/?getSettings', {
                method: 'GET',
            });
            const responseJson = await response.json();
            console.log(responseJson)
            if (response.ok) {
                this.setState(() => ({
                    namedayMonitor: parseInt(responseJson.nameday),
                    bigTv: parseInt(responseJson.large_tv),
                    roadgamesTv: parseInt(responseJson.roadgames_tv || 1), // Default to 1 (enabled) if not set
                }));
            }
        } catch (error) {
            console.error(`Error sending settings to the server:`, error);
        }
    }

    toggleUploadModal = () => {
        this.setState((prevState) => ({
            isUploadModalOpen: !prevState.isUploadModalOpen,
        }));
        
        // Close the modal using modal container if we're closing
        if (this.state.isUploadModalOpen) {
            window.modalContainer.pop();
        }
    };

    toggleSettingsModal = () => {
        this.setState((prevState) => ({
            isSettingsModalOpen: !prevState.isSettingsModalOpen,
        }));
        
        // Close the modal using modal container if we're closing
        if (this.state.isSettingsModalOpen) {
            window.modalContainer.pop();
        }
    }


    componentDidMount() {
        document.addEventListener('keydown', this.handleEscapeKey);
        this.getSettings()
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleEscapeKey);
    }

    handleEscapeKey = (event) => {
        if (event.key === 'Escape') {
            this.setState(() => ({
                isUploadModalOpen: false,
                isSettingsModalOpen: false
            }));
        }
    }

    onFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        this.setState({
            selectedFiles,
            imagesUploaded: event.target.files.length,
            isLoading: false,
        });
    };

    handleNamedayChange = (namedayId) => {
        this.setState(() => ({
            namedayMonitor: namedayId,
        }));
    }

    handleBigTv = (bigTv) => {
        this.setState(() => ({
            bigTv,
        }));
    }
    
    handleRoadgamesTv = (roadgamesTv) => {
        this.setState(() => ({
            roadgamesTv,
        }));
    }

    saveSettings = async () => {
        try {
            const formData = new FormData();
            formData.append('nameday', this.state.namedayMonitor);
            formData.append('large_tv', this.state.bigTv);
            formData.append('roadgames_tv', this.state.roadgamesTv);
            await fetch('https://hex.lv/chromecast/', {
                method: 'POST',
                body: formData,
            });
            
            // Close the modal
            this.setState((prevState) => ({
                isSettingsModalOpen: false,
            }));
            window.modalContainer.pop();
            
            // Refresh chromecasts
            await fetch('http://194.19.232.194:3388/chromecast/chromecaster/?refresh=true', {
                method: 'GET',
                cors: 'no-cors',
            });
            
            window.location.reload();
        } catch (error) {
            console.error(`Error sending settings to the server:`, error);
        }
    }

    handleRefresh = async () => {
        try {
            await fetch('http://194.19.232.194:3388/chromecast/chromecaster/?refresh=true', {
                method: 'GET',
                cors: 'no-cors',
            });
        } catch (error) {
            console.error('Error refreshing the chromecaster:', error);
        }
    }

    sendFilesToServer = async () => {
        this.setState({ isLoading: true });
        const selectedFiles = Array.from(this.state.selectedFiles);
        
        try {
            const promises = selectedFiles.map(async (file, index) => {
                try {
                    const compressedFile = await imageCompression(file, {
                        maxSizeMB: 1,
                        maxWidthOrHeight: 1920,
                        useWebWorker: true
                    });
                    
                    const img = new Image();
                    img.src = URL.createObjectURL(file);
                    
                    await new Promise((resolve) => {
                        img.onload = resolve;
                    });
                    
                    const formData = new FormData();
                    formData.append('user', 1);
                    formData.append('data', compressedFile.name);
                    formData.append('type', compressedFile.type);
                    formData.append('width', img.width);
                    formData.append('height', img.height);
                    formData.append('file', compressedFile);
                    
                    const response = await fetch('https://hex.lv/chromecast/', {
                        method: 'POST',
                        body: formData,
                    });
                    
                    const responseJson = await response.json();
                    if (!response.ok) {
                        throw new Error(`Upload failed: ${responseJson}`);
                    }
                } catch (error) {
                    console.error(`Error processing file ${index + 1}:`, error);
                    throw error;
                }
            });
            
            await Promise.all(promises);
            
            this.setState({
                selectedFiles: [],
                imagesUploaded: 0,
                isLoading: false,
                isUploadModalOpen: false,
            });
            
            // Close the modal
            window.modalContainer.pop();
            
            // Refresh
            this.handleRefresh();
        } catch (error) {
            console.error('Error uploading files:', error);
            this.setState({ isLoading: false });
        }
    }

    render() {
        return (
            <MonitorsLayout title="Chromecast" >
                <div className="container">
                    <Toolbar 
                        leftElements={
                            <>
                                <ToolbarButton icon="cloud-upload-alt" onClick={() => this.toggleUploadModal()}>
                                    Upload
                                </ToolbarButton>
                                <ToolbarButton icon="sync-alt" onClick={() => this.handleRefresh()}>
                                    Refresh
                                </ToolbarButton>
                                <ToolbarButton icon="cog" onClick={() => this.toggleSettingsModal()}>
                                    Settings
                                </ToolbarButton>
                            </>
                        }
                    />
                    
                    <div className="location-section">
                        <h2 className="location-header">Kitchen</h2>
                        <div className="location-container kitchen-container">
                            <Monitor 
                                url={this.state.namedayMonitor === 1 ? "https://hex.lv/chromecast/namedays/" : "https://hex.lv/chromecast/vertical/?monitor=1"}
                                title="1"
                                type="vertical"
                                monitorId={1}
                            />
                            <Monitor 
                                url={this.state.namedayMonitor === 2 ? "https://hex.lv/chromecast/namedays/" : "https://hex.lv/chromecast/vertical/?monitor=2"}
                                title="2"
                                type="vertical"
                                monitorId={2}
                            />
                            <Monitor 
                                url={this.state.namedayMonitor === 3 ? "https://hex.lv/chromecast/namedays/" : "https://hex.lv/chromecast/vertical/?monitor=3"}
                                title="3"
                                type="vertical"
                                monitorId={3}
                            />
                            <Monitor  
                                url={this.state.bigTv === 0 ? "https://hex.lv/chromecast/off.php" : "https://hex.lv/chromecast/horizontal/"}
                                title="4"
                                type="horizontal"
                                monitorId={4}
                            />
                        </div>
                    </div>
                    
                    <div className="location-section">
                        <h2 className="location-header">Roadgames</h2>
                        <div className="location-container roadgames-container">
                            <Monitor  
                                url={this.state.roadgamesTv === 0 ? "https://hex.lv/chromecast/off.php" : "https://hex.lv/chromecast/horizontal/?monitor=5"}
                                title="5"
                                type="horizontal"
                                monitorId={5}
                            />
                        </div>
                    </div>
                </div>
                {this.state.isUploadModalOpen && 
                    Modal.present(
                        <div style={{
                            width: '700px', 
                            maxWidth: '90vw',
                            maxHeight: '85vh',
                            overflow: 'auto'
                        }}>
                            <div style={{ padding: '20px' }}>
                                <h3 style={{marginBottom: '20px'}}>Upload new images</h3>
                                {this.state.isLoading ? (
                                    <div className="loading" style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '40px 0'
                                    }}>
                                        <span>Uploading images...</span>
                                    </div>
                                ) : (
                                    <>
                                        <Dropzone onFileChange={this.onFileChange} selectedFiles={this.state.selectedFiles} />
                                        <div className="modalActions" style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: '20px'
                                        }}>
                                            <span>{this.state.imagesUploaded} images selected</span>
                                            <button 
                                                onClick={this.sendFilesToServer}
                                                disabled={this.state.selectedFiles.length === 0}
                                            >
                                                Upload
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )
                }
                {this.state.isSettingsModalOpen && 
                    Modal.present(
                        <div style={{
                            width: '500px', 
                            maxWidth: '90vw',
                            maxHeight: '85vh',
                            overflow: 'auto',
                            padding: '20px'
                        }}>
                            <h3 style={{marginBottom: '20px'}}>Chromecast settings</h3>
                            <div className="settingsContent">
                                <label htmlFor="namedaySettings" className="namedayLabel" style={{ 
                                    display: 'block', 
                                    marginBottom: '12px',
                                    color: this.props.theme?.secondaryText || '#61615F',
                                    fontWeight: '500'
                                }}>
                                    Select name day display
                                </label>
                                <div id="namedaySettings" className="namedaySettings" style={{marginBottom: '30px'}}>
                                    {[0, 1, 2, 3].map(id => (
                                        <button
                                            key={id}
                                            onClick={() => this.handleNamedayChange(id)}
                                            className={this.state.namedayMonitor === id ? 'selected' : 'notSelected'}
                                        >
                                            {id === 0 ? 'Disabled' : id}
                                        </button>
                                    ))}
                                </div>

                                <label htmlFor="bigtvSettings" className="bigtvLabel" style={{ 
                                    display: 'block', 
                                    marginBottom: '12px',
                                    color: this.props.theme?.secondaryText || '#61615F',
                                    fontWeight: '500'
                                }}>
                                    Chromecast for Big TV
                                </label>
                                <div id="bigtvSettings" className="namedaySettings" style={{marginBottom: '30px'}}>
                                    <button
                                        onClick={() => this.handleBigTv(0)}
                                        className={this.state.bigTv === 0 ? 'selected' : 'notSelected'}
                                    >
                                        Disabled
                                    </button>
                                    <button
                                        onClick={() => this.handleBigTv(1)}
                                        className={this.state.bigTv === 1 ? 'selected' : 'notSelected'}
                                    >
                                        Enabled
                                    </button>
                                </div>

                                <label htmlFor="roadgamesTvSettings" className="roadgamesTvLabel" style={{ 
                                    display: 'block', 
                                    marginBottom: '12px',
                                    color: this.props.theme?.secondaryText || '#61615F',
                                    fontWeight: '500'
                                }}>
                                    Roadgames TV (Monitor 5)
                                </label>
                                <div id="roadgamesTvSettings" className="namedaySettings" style={{marginBottom: '30px'}}>
                                    <button
                                        onClick={() => this.handleRoadgamesTv(0)}
                                        className={this.state.roadgamesTv === 0 ? 'selected' : 'notSelected'}
                                    >
                                        Disabled
                                    </button>
                                    <button
                                        onClick={() => this.handleRoadgamesTv(1)}
                                        className={this.state.roadgamesTv === 1 ? 'selected' : 'notSelected'}
                                    >
                                        Enabled
                                    </button>
                                </div>

                                <div className="modalActions saveBtn">
                                    <button onClick={this.saveSettings}>Save changes</button>
                                </div>
                            </div>
                        </div>
                    )
                }
            </MonitorsLayout>
        )
    }
}
