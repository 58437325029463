import React, { useEffect, useState } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import Layout from '../components/Layout'
import API from "../API";
import EventCalendar from "../components/Events/EventCalendar";
import { UpcomingEvents } from "../components/Events/UpcomingEvents";
import CreateModal from "../components/Events/CreateModal";
import EventDetails from "../components/Events/EventDetails";
import { useTheme, lightTheme, darkTheme } from '../ThemeContext';
import Skeleton, { CardSkeleton, SkeletonGroup, CalendarSkeleton, UpcomingEventSkeleton, NewEventButtonSkeleton, EventDetailsSkeleton } from '../components/Skeleton';

// Ensure font is loaded globally
const FontImport = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
`;

const CalendarsLayout = styled(Layout)`
    .EventContainer {
        @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
        font-family: 'Plus Jakarta Sans', sans-serif;
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-between !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-shadow: ${props => props.theme.isDarkMode ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.2)'} 0 0 1px;
        gap: 20px;
        color: ${props => props.theme.text};
    }

    .calendar {
        width: 70%;
    }

    /* Mobile styles */
    @media (max-width: 1080px) {
        .EventContainer {
            flex-direction: column;
        }

        .calendar {
            width: 100%;
            height: 100%;
        }

        .tabs {
            position: relative;
            display: flex;
            justify-content: center;
            background: ${props => props.theme.isDarkMode ? '#161618' : 'rgba(245,245,245,0.8)'};
            border-radius: 12px;
            padding: 6px;
            backdrop-filter: blur(10px);
            overflow: hidden;
            margin: 0 auto;
            width: 100%;
        }

        .tab-button {
            flex: 1;
            padding: 12px 18px;
            font-size: 14px;
            cursor: pointer;
            color: ${props => props.theme.isDarkMode ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.65)'};
            -webkit-tap-highlight-color: transparent;
            user-select: none;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            transition: all 0.25s ease;
            border-radius: 8px;
            margin: 0 2px;
            letter-spacing: 0.2px;
            background: transparent;
            height: 26px;
            white-space: nowrap;
        }

        .tab-button.active {
            background: ${props => props.theme.isDarkMode ? 'white' : 'black'};
            color: ${props => props.theme.isDarkMode ? 'black' : 'white'};
            font-weight: 600;
        }
        
        /* Add hover effect to inactive tabs */
        .tab-button:not(.active):hover {
            background: ${props => props.theme.isDarkMode ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.03)'};
            color: ${props => props.theme.isDarkMode ? 'rgba(255,255,255,0.9)' : 'rgba(0,0,0,0.85)'};
        }
        
        /* Add optional tap feedback */
        .tab-button:active {
            opacity: 0.85;
            transition: opacity 0.1s ease;
        }

        .tabContent {
            height: calc(100vh - 50px); /* Adjust if your tabs header differs */
            overflow: auto;
            color: ${props => props.theme.text};
            animation: fadeIn 0.2s ease-in-out;
        }
        
        @keyframes fadeIn {
            from { opacity: 0.8; }
            to { opacity: 1; }
        }
        
        /* Prevent scrolling on calendar tab */
        .tabContent:has(.calendar) {
            overflow: hidden;
        }
        
        /* Keep scrolling for upcoming events tab */
        .tabContent:not(:has(.calendar)) {
            overflow: auto;
        }
    }
`;

export const getTagColor = (color, isDarkMode = false) => {
    // Color definitions with brighter pastels for better visual appeal
    const colors = {
        red: {
            base: '#FF5252',  // Brighter red
            dark: '#D32F2F',
            text: isDarkMode ? '#FF5252' : '#C62828'
        },
        blue: {
            base: '#448AFF',  // Brighter blue
            dark: '#1565C0',
            text: isDarkMode ? '#448AFF' : '#0D47A1'
        },
        green: {
            base: '#69F0AE',  // Brighter green
            dark: '#2E7D32',
            text: isDarkMode ? '#69F0AE' : '#1B5E20'
        },
        yellow: {
            base: '#FFEE58',  // More muted but still bright yellow
            dark: '#F9A825',
            text: isDarkMode ? '#FFEE58' : '#F57F17'
        },
        purple: {
            base: '#B388FF',  // Brighter purple
            dark: '#6A1B9A',
            text: isDarkMode ? '#B388FF' : '#4A148C'
        },
        pink: {
            base: '#FF80AB',  // Brighter pink
            dark: '#AD1457',
            text: isDarkMode ? '#FF80AB' : '#880E4F'
        },
        brown: {
            base: '#A1887F',  // Brighter brown
            dark: '#4E342E',
            text: isDarkMode ? '#A1887F' : '#3E2723'
        },
        orange: {
            base: '#FFAB40',  // Brighter orange
            dark: '#EF6C00',
            text: isDarkMode ? '#FFAB40' : '#E65100'
        },
        gray: {
            base: '#B0BEC5',  // Brighter gray
            dark: '#546E7A',
            text: isDarkMode ? '#B0BEC5' : '#37474F'
        },
        'light gray': {
            base: '#CFD8DC',  // Brighter light gray
            dark: '#78909C',
            text: isDarkMode ? '#CFD8DC' : '#455A64'
        },
        default: {
            base: '#B0BEC5',  // Same as gray
            dark: '#546E7A',
            text: isDarkMode ? '#B0BEC5' : '#37474F'
        }
    };
    
    // Get color definition or use default
    const colorDef = colors[color] || colors.default;
    
    // Function to determine if a color is light or dark
    const isLightColor = (hexColor) => {
        // Convert hex to RGB
        const r = parseInt(hexColor.slice(1, 3), 16);
        const g = parseInt(hexColor.slice(3, 5), 16);
        const b = parseInt(hexColor.slice(5, 7), 16);
        
        // Calculate perceived brightness (based on YIQ formula)
        // Higher values = lighter colors
        const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        
        // Consider colors with brightness > 155 as "light"
        return brightness > 155;
    };
    
    // Determine text color based on background color brightness
    // For light background colors, use dark text; for dark backgrounds, use white text
    const textColor = isLightColor(colorDef.base) ? '#000000' : '#FFFFFF';
    
    return {
        // Background color with full opacity for better pop
        backgroundColor: colorDef.base,
        // Solid background color (useful for borders or UI elements that need the base color)
        baseColor: colorDef.base,
        // Darker variant of the color for contrast
        darkColor: colorDef.dark,
        // Text color that ensures good contrast based on background brightness
        color: textColor
    };
}

const Events = () => {
    const { isDarkMode } = useTheme();
    // Get the appropriate theme based on isDarkMode
    const theme = isDarkMode ? darkTheme : lightTheme;
    const [events, setEvents] = useState([]);
    const [organizators, setOrganizators] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const [editingEvent, setEditingEvent] = useState(null);
    const [loading, setLoading] = useState(true); // Main loading state
    const [eventDetailsLoading, setEventDetailsLoading] = useState(false); // Loading state for event details
    const [canManageEvents, setCanManageEvents] = useState(false);

    // State to handle mobile view and tab selection
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1080);
    const [activeTab, setActiveTab] = useState("upcoming");

    useEffect(() => {
        fetchData();
    }, []);

    // Listen to window resize events to update the mobile state
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1080);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true);
            const [organizatorsData, participantsData, tagsData, eventsData] = await Promise.all([
                API.shared.get('events/organizators'),
                API.shared.get('events/participants'),
                API.shared.get('events/tags'),
                API.shared.get('events')
            ]);

            // Check if user has EVENT_MANAGER permission
            setCanManageEvents(API.shared.hasPermission('EVENT_MANAGER'));
            
            setOrganizators(organizatorsData);
            setParticipants(participantsData);
            setTags(tagsData);

            const formattedEvents = eventsData.map(event => ({
                id: event.id,
                title: event.title,
                start: new Date(event.date + (event.hasTime ? 'T' + event.startTime : '')),
                end: new Date(event.date + (event.hasTime ? 'T' + event.endTime : '')),
                hasTime: event.hasTime,
                allDay: event.allDay,
                description: event.description,
                location: event.location,
                url: event.url,
                organizators: event.organizators || [],
                participants: event.participants || [],
                tags: event.tags || []
            }));

            setEvents(formattedEvents);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const handleEventClick = (event) => {
        // Set loading state for event details
        setEventDetailsLoading(true);
        
        // Simulate a network delay to show the skeleton (in a real app this would be an API call)
        setTimeout(() => {
            setSelectedEvent(event);
            setEventDetailsLoading(false);
        }, 300); // Simulate a small delay to show skeleton - reduced for more subtle effect
    };

    const handleCreateEvent = async (eventData) => {
        try {
            await API.shared.post('events', {
                title: eventData.title,
                date: eventData.date,
                startTime: eventData.startTime,
                endTime: eventData.endTime,
                hasTime: eventData.hasTime,
                allDay: eventData.allDay,
                description: eventData.description,
                location: eventData.location,
                url: eventData.url,
                organizator: eventData.organizator?.id || null,
                participants: eventData.participants,
                tags: eventData.tags
            });

            await fetchData();
            setIsCreating(false);
        } catch (error) {
            console.error('Error creating event:', error);
        }
    };

    const handleCreateOrganizator = async (organizatorData) => {
        try {
            const response = await API.shared.post('events/organizators', organizatorData);
            await fetchData();
            return response;
        } catch (error) {
            console.error('Error creating organizator:', error);
            return null;
        }
    };

    const handleCreateParticipant = async (participantData) => {
        try {
            const response = await API.shared.post('events/participants', participantData);
            await fetchData();
            return response;
        } catch (error) {
            console.error('Error creating participant:', error);
            return null;
        }
    };

    const handleCreateTag = async (tagData) => {
        try {
            const response = await API.shared.post('events/tags', tagData);
            await fetchData();
            return response;
        } catch (error) {
            console.error('Error creating tag:', error);
            return null;
        }
    };

    const handleUpdateEvent = async (updatedEventData) => {
        try {
            await API.shared.post(`events/update/${updatedEventData.id}`, updatedEventData);
            await fetchData();
            setEditingEvent(null);
        } catch (error) {
            console.error('Error updating event:', error);
        }
    };

    const handleDeleteEvent = async (eventId) => {
        try {
            await API.shared.post(`events/delete/${eventId}`);
            await fetchData();
            setSelectedEvent(null);
        } catch (error) {
            console.error('Error deleting event:', error);
        }
    };

    // Use specialized calendar and event skeleton components for better loading experience
    if (loading) {
        return (
            <CalendarsLayout title="Events">
                <FontImport />
                {isMobile ? (
                    <>
                        <div style={{ padding: '12px 0', marginBottom: '12px' }}>
                            <div className="tabs">
                                <div
                                    className={`tab-button ${activeTab === "upcoming" ? "active" : ""}`}
                                    onClick={() => setActiveTab("upcoming")}
                                >
                                    Upcoming Events
                                </div>
                                <div
                                    className={`tab-button ${activeTab === "calendar" ? "active" : ""}`}
                                    onClick={() => setActiveTab("calendar")}
                                >
                                    Calendar
                                </div>
                            </div>
                        </div>
                        <div className="tabContent">
                            {activeTab === "calendar" && (
                                <div className="calendar">
                                    <CalendarSkeleton height="60vh" />
                                </div>
                            )}
                            {activeTab === "upcoming" && (
                                <SkeletonGroup>
                                    <NewEventButtonSkeleton />
                                    {[1, 2, 3, 4].map(i => (
                                        <UpcomingEventSkeleton key={i} />
                                    ))}
                                </SkeletonGroup>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="EventContainer">
                        <div className="calendar" style={{ width: '70%' }}>
                            <CalendarSkeleton />
                        </div>
                        <SkeletonGroup width="30%">
                            <NewEventButtonSkeleton />
                            {[1, 2, 3, 4].map(i => (
                                <UpcomingEventSkeleton key={i} />
                            ))}
                        </SkeletonGroup>
                    </div>
                )}
            </CalendarsLayout>
        );
    }


    return (
        <CalendarsLayout title="Events">
            <FontImport />
            {isMobile ? (
                <>
                    <div style={{ padding: '12px 0', marginBottom: '12px' }}>
                        <div className="tabs">
                            <div
                                className={`tab-button ${activeTab === "upcoming" ? "active" : ""}`}
                                onClick={() => setActiveTab("upcoming")}
                            >
                                Upcoming Events
                            </div>
                            <div
                                className={`tab-button ${activeTab === "calendar" ? "active" : ""}`}
                                onClick={() => setActiveTab("calendar")}
                            >
                                Calendar
                            </div>
                        </div>
                    </div>
                    <div className="tabContent" style={{ color: isDarkMode ? '#F3F3F1' : '#191918' }}>
                        {activeTab === "calendar" && (
                            <div className="calendar" style={{ 
                                overflow: 'hidden', 
                                touchAction: 'none',
                                width: '100%',
                                height: '100%' 
                            }}>
                                <EventCalendar
                                    events={events}
                                    organizators={organizators}
                                    onEventClick={handleEventClick}
                                    isLoading={loading}
                                />
                            </div>
                        )}
                        {activeTab === "upcoming" && (
                            <UpcomingEvents
                                events={events}
                                organizators={organizators}
                                onEventClick={handleEventClick}
                                onCreateClick={canManageEvents ? () => setIsCreating(true) : null}
                                isLoading={loading}
                            />
                        )}
                    </div>
                </>
            ) : (
                <div className="EventContainer" style={{ 
                        display: 'flex', 
                        flexDirection: 'row', 
                        justifyContent: 'space-between',
                        gap: '20px',
                        fontFamily: "'Plus Jakarta Sans', sans-serif",
                        WebkitFontSmoothing: 'antialiased',
                        MozOsxFontSmoothing: 'grayscale',
                        textShadow: isDarkMode ? 'rgba(0, 0, 0, 0.4) 0 0 1px' : 'rgba(0, 0, 0, 0.2) 0 0 1px',
                        color: isDarkMode ? '#F3F3F1' : '#191918'
                    }}>
                    <div className="calendar" style={{ width: '70%' }}>
                        <EventCalendar
                            events={events}
                            organizators={organizators}
                            onEventClick={handleEventClick}
                            isLoading={loading}
                        />
                    </div>
                    <UpcomingEvents
                        events={events}
                        organizators={organizators}
                        onEventClick={handleEventClick}
                        onCreateClick={canManageEvents ? () => setIsCreating(true) : null}
                        isLoading={loading}
                    />
                </div>
            )}

            {/* Show loading skeleton if event details are loading */}
            {eventDetailsLoading && <EventDetailsSkeleton />}
            
            {/* Show event details if an event is selected and not loading */}
            {selectedEvent && !eventDetailsLoading && (
                <EventDetails
                    event={selectedEvent}
                    onClose={() => {
                        setSelectedEvent(null);
                        setEventDetailsLoading(false);
                    }}
                    onDelete={canManageEvents ? handleDeleteEvent : null}
                    onEdit={canManageEvents ? (event) => {
                        setEditingEvent(event);
                        setSelectedEvent(null);
                    } : null}
                />
            )}

            {isCreating && (
                <CreateModal
                    onClose={() => setIsCreating(false)}
                    onSubmit={handleCreateEvent}
                    tags={tags}
                    organizators={organizators}
                    participants={participants}
                    onCreateOrganizator={handleCreateOrganizator}
                    onCreateParticipant={handleCreateParticipant}
                    onCreateTag={handleCreateTag}
                />
            )}

            {editingEvent && (
                <CreateModal
                    event={editingEvent}
                    onClose={() => setEditingEvent(null)}
                    onSubmit={handleUpdateEvent}
                    tags={tags}
                    organizators={organizators}
                    participants={participants}
                    onCreateOrganizator={handleCreateOrganizator}
                    onCreateParticipant={handleCreateParticipant}
                    onCreateTag={handleCreateTag}
                />
            )}
        </CalendarsLayout>
    )
}

export default Events;
