// ===================================================================

import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import API from '../API'

import Login from '../views/Login'
import Layout from './Layout'
import views from '../views'
import styled from "styled-components"
import Skeleton, { CardSkeleton, SkeletonGroup } from './Skeleton';
import { ThemeContext, useTheme, lightTheme, darkTheme } from '../ThemeContext';

// ===================================================================
const Loader = styled.div`
	margin: auto auto;
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	background-color: ${props => props.theme.background};
	color: ${props => props.theme.text};
	
	.logo {
		margin-bottom: 30px;
		width: 100px;
		height: 100px;
		opacity: 0.7;
	}
	
	.spinner {
		width: 40px;
		height: 40px;
		margin-bottom: 20px;
		border: 3px solid ${props => props.theme === darkTheme 
			? 'rgba(255, 255, 255, 0.1)' 
			: 'rgba(0, 0, 0, 0.1)'};
		border-top-color: #FF6C64;
		border-radius: 50%;
		animation: spin 1s linear infinite;
	}
	
	@keyframes spin {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
`;
export default class App extends React.Component {
	constructor(props) {
		super(props)
		window.theApp = this
	}
	
	componentDidMount() {
		API.shared.getUser().then(user => {
			this.forceUpdate()
		})
	}
	
	render() {
		const { isDarkMode, toggleTheme } = this.context;
		const theme = isDarkMode ? darkTheme : lightTheme;
		
		if(!API.shared.userStateKnown) {
			return (
				<Loader theme={theme}>
					<div className="spinner"></div>
					<div>Loading...</div>
				</Loader>
			)
		} else if(!API.shared.user) {
			return (
				<Login/>
			)
		}
		return (
			<Router>
				<Switch>
					<Route exact path="/" render={ () =>
						<Redirect to={ views[0].path }/>
					}/>
					{ views.map(view => {
						if(view.requiresPermission && !API.shared.hasPermission(view.requiresPermission)) {
							return null
						}
						return <Route key={ view.path } exact path={ view.path } component={ view.component }/>
					}) }
					{/* 404 */}
					<Route render={ () =>
						<Layout title="Not Found (404)">
							The page you are looking for does not exists or you don't have appropriate permissions to access it.
						</Layout>
					}/>
				</Switch>
			</Router>
		)
	}
}

App.contextType = ThemeContext;

// ===================================================================