import React, {useEffect, useState, useContext} from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import MeetingRoomsImg from '../images/MeetingRooms.png'
import Office from '../components/Office'
import { ThemeContext, lightTheme, darkTheme } from '../ThemeContext'

const MeetingRoomsLayout = styled(Layout)`
	${props => props.themeColors && `
		transition: background 0.3s ease, color 0.3s ease;
	`}
	
	.MapSection {
		display: grid;
		gap: 24px;
		margin-bottom: 38px;

		@media (min-width: 1024px) {
			grid-template-columns: none;
		}

		@media (min-width: 1280px) {
			grid-template-columns: none;
		}

		@media (min-width: 1440px) {
			grid-template-columns: 1fr 350px;
			gap: 32px;
		}

		@media (min-width: 1900px) {
			grid-template-columns: 1fr 450px ;
		}

		.Map {
			position: relative;
			height: 100%;
			margin: -20px;

			@media (min-width: 1024px) {
				min-height: 600px;
				margin: 0;
			}
			
		}
	}

	.RoomList {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
		gap: 8px;
		padding: 1.5em;
		background: ${props => props.themeColors.background};
		border-radius: 24px;
		height: fit-content;
		transition: background 0.3s ease;

		@media (min-width: 1024px) {
			grid-template-columns: 1fr auto;
		}
		
		@media (min-width: 1440px) {
			grid-template-columns: 1fr;
			padding: 2em;
			border-radius: 40px;
			max-height: 800px;
			overflow-y: auto;

			/* Scrollbar styling */
			&::-webkit-scrollbar {
				width: 8px;
			}

			&::-webkit-scrollbar-track {
				background: ${props => props.themeColors.background};
				border-radius: 4px;
				transition: background 0.3s ease;
			}

			&::-webkit-scrollbar-thumb {
				background: ${props => props.themeColors.border};
				border-radius: 4px;
				transition: background 0.3s ease;
			}
		}

		@media (min-width: 1900px) {
			padding: 3em;
			max-height: 900px;
			overflow-y: auto;
		}
	}

    .Room {
        display: flex;
        align-items: center;
        padding: 2px;

		.Number {
			display: flex;
			align-items: center;
			justify-content: center;
			min-width: 36px;
			height: 36px;
			background: ${props => props.themeColors.accent};
			color: white;
			border-radius: 50%;
			font-weight: 900;
			font-size: 18px;
			margin-right: 16px;
			transition: background-color 0.3s ease, transform 0.3s ease;
		}

        .Info {
			display: flex;
			flex: 1;
			justify-content: space-between;
			align-items: center;
			gap: 12px;

			.Name {
				font-weight: 700;
				font-size: 16px;
				color: ${props => props.themeColors.primary};
				transition: color 0.3s ease;

				@media (min-width: 1024px) {
					font-size: 18px;
				}
			}

            .Capacity {
				display: flex;
				align-items: center;
				gap: 1px;
				color: ${props => props.themeColors.secondary};
				font-size: 14px;
				white-space: nowrap;
				transition: color 0.3s ease;

				.material-symbols-outlined {
					font-size: 20px;

					&.hasTv {
						margin-left: 8px;
					}
				}
            }
        }
    }

    .RulesSection {
        margin-top: 48px;
        
        .Title {
            font-size: 24px;
            font-weight: 600;
            color: ${props => props.themeColors.primary};
            margin-bottom: 32px;
            padding-left: 10px;
            transition: color 0.3s ease;
        }
    }

    .Rules {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		gap: 10px;
        padding: 0 10px;
    }

    .Rule {
        display: flex;
		flex-direction: column;
        align-items: flex-start;
        padding: 20px;
        background: ${props => props.themeColors.background};
		border-radius: 16px;
		transition: all 0.3s ease;
        border: 1px solid transparent;

        .Icon {
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${props => props.themeColors.accent};
            font-size: 34px;
			padding: 6px;
			transition: color 0.3s ease;
        }

        .Content {
            flex: 1;

            h3 {
                font-size: 18px;
                font-weight: 600;
                color: ${props => props.themeColors.primary};
                margin-bottom: 8px;
                transition: color 0.3s ease;
            }

            p {
                font-size: 14px;
                color: ${props => props.themeColors.secondary};
                line-height: 1.5;
                transition: color 0.3s ease;
            }
        }
    }

    @media (max-width: 768px) {
        .RoomList {
            grid-template-columns: 1fr;
			padding: 0.8em;
			gap: 0;
			
			.Room  {
				padding: 1px;
				margin-bottom: 2px;
				
				.Number {
					min-width: 30px;
					height: 30px;
					font-size: 16px;
					margin-right: 12px;
				}
				
				.Info {
					.Name {
						font-size: 16px;
					}
					
					.Capacity {
						font-size: 12px;
						
						.material-symbols-outlined {
							font-size: 18px;
						}
					}
				}
			}
        }

        .Rules {
            grid-template-columns: 1fr;
        }

        .Rule .Icon {
            width: 40px;
            height: 40px;
            font-size: 20px;
        }
    }
`

const MeetingRooms = () => {
	const [hoveredRoom, setHoveredRoom] = useState(null);
	const { isDarkMode } = useContext(ThemeContext);
	const themeColors = isDarkMode ? {
		primary: darkTheme.text,
		secondary: darkTheme.secondaryText,
		background: darkTheme.keychainBackground,
		backgroundLight: darkTheme.keychainBackgroundLight,
		accent: darkTheme.accent,
		accentGradient: darkTheme.accentGradient,
		border: darkTheme.keychainBorder,
		quickAccessBackground: darkTheme.keychainQuickAccessBackground,
		quickAccessBorder: darkTheme.keychainQuickAccessBorder,
		quickAccessShadow: darkTheme.keychainQuickAccessShadow,
		sliderBackground: darkTheme.keychainSliderBackground,
		sliderKnob: darkTheme.keychainSliderKnob,
		overlay: darkTheme.keychainOverlay,
	} : {
		primary: lightTheme.text,
		secondary: lightTheme.secondaryText,
		background: lightTheme.keychainBackground,
		backgroundLight: lightTheme.keychainBackgroundLight,
		accent: lightTheme.accent,
		accentGradient: lightTheme.accentGradient,
		border: lightTheme.keychainBorder,
		quickAccessBackground: lightTheme.keychainQuickAccessBackground,
		quickAccessBorder: lightTheme.keychainQuickAccessBorder,
		quickAccessShadow: lightTheme.keychainQuickAccessShadow,
		sliderBackground: lightTheme.keychainSliderBackground,
		sliderKnob: lightTheme.keychainSliderKnob,
		overlay: lightTheme.keychainOverlay,
	};

	const rooms = [
		{ number: 1, name: 'Los Angeles', capacity: 8, hasTV: true },
		{ number: 2, name: 'Barcelona', capacity: 6, hasTV: true },
		{ number: 3, name: 'Cēsis', capacity: 4, hasTV: true },
		{ number: 4, name: 'Tacho', capacity: 6, hasTV: true },
		{ number: 5, name: 'Kravas kaste', capacity: 4, hasTV: false },
		{ number: 6, name: 'Kabīne', capacity: 6, hasTV: false },
		{ number: 7, name: 'Aizkulises', capacity: 6, hasTV: true },
		{ number: 8, name: 'Lomu kambaris', capacity: 2, hasTV: false },
		{ number: 9, name: 'Noslēpumu kambaris', capacity: 2, hasTV: false },
		{ number: 10, name: 'Bibliotēka', capacity: 8, hasTV: true },
		{ number: 11, name: 'Hola kubs', capacity: 4, hasTV: false },
		{ number: 12, name: 'Draugiem', capacity: 8, hasTV: true },
		{ number: 13, name: 'Studija', capacity: 7, hasTV: false },
		{ number: 14, name: 'Gaismas telpa', capacity: 20, hasTV: true },
	];

	const rules = [
		{
			icon: "event_available",
			title: "Rezervācija",
			text: "Rezervē telpu 1WORK sistēmā. Atcel vai saīsini rezervāciju, ja telpu neizmantosi vai beidz ātrāk."
		},
		{
			icon: "meeting_room",
			title: "Telpu izvēle",
			text: "Mazās un box telpas - individuāliem zvaniem. Lielās telpas - grupu sapulcēm."
		},
		{
			icon: "visibility_off",
			title: "Dokumentu drošība",
			text: "Neturi sensitīvus dokumentus redzamā vietā, kad pamet telpu."
		},
		{
			icon: "inventory_2",
			title: "Kancelejas preces",
			text: "Visas kancelejas preces, līmlapiņas, marķieri un vadi jānovieto atpakaļ kastēs pēc lietošanas."
		},
		{
			icon: "cable",
			title: "TV vadi",
			text: "Pēc lietošanas saritini TV vadus un uzkarini uz āķīša."
		},
		{
			icon: "edit",
			title: "Tāfeles",
			text: "Izmanto tikai whiteboard marķierus. Pēc lietošanas notīri tāfeli un novāc līmlapiņas."
		},
		{
			icon: "coffee",
			title: "Trauki",
			text: "Nepamet tukšas krūzes un glāzes. Aiznes traukus uz virtuvi."
		},
		{
			icon: "styler",
			title: "Garderobes zona",
			text: "Virsdrēbes karini tikai garderobes zonā uz speciāliem pakaramajiem."
		},
		{
			icon: "swap_vert",
			title: "Aizņemtās lietas",
			text: "Ja paņem lietas no citām telpām, noliec tās atpakaļ sākotnējā vietā."
		},
		{
			icon: "recycling",
			title: "Atkritumi",
			text: "Izmanto atkritumu urnas, kas atrodas visos biroja gaiteņos."
		}
	];

	useEffect(() => {
		const animateElements = (roomNumber, isEntering) => {
			const circle = document.getElementById('room' + roomNumber);
			const numberElement = document.querySelector(`#room-list-${roomNumber} .Number`);

			if (circle && numberElement) {
				if (isEntering) {
					circle.style.transition = 'fill 0.3s ease-in-out, transform 0.3s ease-in-out';
					circle.style.transformBox = 'fill-box';
					circle.style.transformOrigin = '50% 50%';
					circle.style.transform = 'scale(1.2)';
					
					// Use appropriate contrast colors based on theme
					const hoverFill = isDarkMode ? darkTheme.text : lightTheme.text;
					circle.setAttribute('fill', hoverFill);

					numberElement.style.transition = 'transform 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out';
					numberElement.style.transform = 'scale(1.2)';
					numberElement.style.backgroundColor = isDarkMode ? darkTheme.text : lightTheme.text;
					numberElement.style.color = isDarkMode ? darkTheme.background : 'white';
				} else {
					circle.setAttribute('fill', themeColors.accent);
					circle.style.transform = 'scale(1)';

					numberElement.style.transform = 'scale(1)';
					numberElement.style.backgroundColor = themeColors.accent;
					numberElement.style.color = 'white';
				}
			}
		};

		// Event handlers
		const handleMouseEnter = (roomNumber) => animateElements(roomNumber, true);
		const handleMouseLeave = (roomNumber) => animateElements(roomNumber, false);

		// Add event listeners
		rooms.forEach((room) => {
			const roomElement = document.getElementById('room-list-' + room.number);
			const circle = document.getElementById('room' + room.number);

			if (roomElement) {
				roomElement.addEventListener('mouseenter', () => handleMouseEnter(room.number));
				roomElement.addEventListener('mouseleave', () => handleMouseLeave(room.number));
			}

			if (circle) {
				circle.addEventListener('mouseenter', () => handleMouseEnter(room.number));
				circle.addEventListener('mouseleave', () => handleMouseLeave(room.number));
				circle.style.cursor = 'pointer';
			}
		});

		// Cleanup
		return () => {
			rooms.forEach((room) => {
				const roomElement = document.getElementById('room-list-' + room.number);
				const circle = document.getElementById('room' + room.number);

				if (roomElement) {
					roomElement.removeEventListener('mouseenter', () => handleMouseEnter(room.number));
					roomElement.removeEventListener('mouseleave', () => handleMouseLeave(room.number));
				}

				if (circle) {
					circle.removeEventListener('mouseenter', () => handleMouseEnter(room.number));
					circle.removeEventListener('mouseleave', () => handleMouseLeave(room.number));
				}
			});
		};
	}, [rooms, isDarkMode, themeColors]);


	return (
		<MeetingRoomsLayout title="Meeting Rooms" themeColors={themeColors} isDarkMode={isDarkMode}>
			<div className="MapSection">
				<div className="Map">
					<Office/>
				</div>
				<div className="RoomList">
					{rooms.map(room => (
						<div key={room.number} className="Room">
							<div id={'room-list-' + room.number} className="Room">
								<div className="Number">{room.number}</div>
								<div className="Info">
									<div className="Name">{room.name}</div>
									<div className="Capacity">
										<span className="material-symbols-outlined">person</span>
										{room.capacity}
										{room.hasTV && (
											<span className="hasTv material-symbols-outlined">connected_tv</span>
										)}
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>

			<div className="RulesSection">
				<h2 className="Title">Sapulču telpu lietošanas noteikumi</h2>
				<div className="Rules">
					{rules.map((rule, index) => (
						<div key={index} className="Rule">
                            <span className="material-symbols-outlined Icon">
                                {rule.icon}
                            </span>
							<div className="Content">
								<h3>{rule.title}</h3>
								<p>{rule.text}</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</MeetingRoomsLayout>
	)
}

export default MeetingRooms