import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Layout from '../components/Layout'
import ReactSelect from "react-select";
import {useDropzone} from "react-dropzone";
import API from "../API";
import imageCompression from "browser-image-compression";
import Toolbar, { ToolbarButton } from '../components/Toolbar';

const ToolsLayout = styled(Layout)`
    .container {
        padding: 4em 10% 2em;
        column-count: 2;
        column-gap: 3em;
        margin: auto;
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        overflow: scroll !important;
    }

    .modalContent {
        width: 60%;
        margin: auto;
        padding: 2em;
        background: ${props => props.theme.modalBackground || 'white'};
        color: ${props => props.theme.text};
        border-radius: 8px;
        overflow: auto;
        max-height: 90%;
    }

    .modalContent h3 {
        margin-bottom: 1em;
        color: ${props => props.theme.text};
    }

    form button {
        margin-top: 1em;
    }

    form div label {
        font-weight: bold;
        display: block;
        margin-bottom: 0.5em;
        margin-top: 2em;
        color: ${props => props.theme.text};
    }

    form div input {
        width: 100%;
        padding: 0.5em;
        border: 1px solid ${props => props.theme.inputBorder || '#ccc'};
        border-radius: 4px;
        box-sizing: border-box;
        background: ${props => props.theme.inputBackground || 'white'};
        color: ${props => props.theme.inputText || props.theme.text};
    }

    .flex {
        display: flex;
        gap: 1em;
        width: 100%;
        justify-content: space-between;
    }

    .flex div {
        flex-grow: 1;
    }

    .topBar {
        display: flex;
        gap: 1em;
        width: 100%;
        justify-content: space-between;
    }

    .companySelector {
        min-width: 300px !important;
    }

    /* React-Select Dark Mode Styling */
    .css-13cymwt-control, 
    .css-t3ipsp-control {
        background-color: ${props => props.theme.inputBackground || 'white'} !important;
        border-color: ${props => props.theme.inputBorder || '#ccc'} !important;
    }

    .css-1dimb5e-singleValue,
    .css-166bipr-Input, 
    .css-qbdosj-Input, 
    .css-1jqq78o-placeholder {
        color: ${props => props.theme.inputText || props.theme.text} !important;
    }

    .css-1nmdiq5-menu, 
    .css-1n6sfyn-MenuList {
        background-color: ${props => props.theme.modalBackground || 'white'} !important;
    }

    .css-d7l1ni-option {
        background-color: ${props => props.theme.menuActiveBackground || '#f5f5f5'} !important;
    }

    .css-tr4s17-option, 
    .css-1qs4hsa-option {
        color: ${props => props.theme.text} !important;
    }

    .css-1hb7zxy-IndicatorsContainer span {
        background-color: ${props => props.theme.text} !important;
    }

    .delete-btn {
        background: #ff0000;
        color: #fff;
        border: 1px solid #ec0000 !important;
        margin-right: 0.5em;
    }

    .quill {
        background: ${props => props.theme.inputBackground || 'white'};
        border-radius: 4px;
        
        .ql-toolbar {
            border-color: ${props => props.theme.inputBorder || '#ccc'};
            color: ${props => props.theme.text};
            background: ${props => props.theme.toolbarBackground || props.theme.inputBackground || 'white'};
            
            .ql-stroke {
                stroke: ${props => props.theme.text} !important;
            }
            
            .ql-fill {
                fill: ${props => props.theme.text} !important;
            }
            
            .ql-picker {
                color: ${props => props.theme.text} !important;
            }
            
            .ql-picker-options {
                background: ${props => props.theme.dropdownBackground || props.theme.inputBackground || 'white'} !important;
            }
        }
        
        .ql-container {
            border-color: ${props => props.theme.inputBorder || '#ccc'};
            color: ${props => props.theme.text};
            background: ${props => props.theme.inputBackground || 'white'};
        }
        
        .ql-editor {
            color: ${props => props.theme.text};
        }
    }

    @media (max-width: 1680px) {
        .container {
            width: 98%;
        }
    }

    @media (max-width: 1480px) {
        .container {
            padding: 3em 5% 2em;
        }
    }

    @media (max-width: 1024px) {
        .container {
            column-count: 1;
            padding: 1em 0;
        }

        .modalContent {
            width: 99%;
        }

        .flex {
            flex-direction: column;
            gap: 1em;
        }

        .topBar {
            display: block;
        }
    }

    .uploadModal {
        width: 35%;
        padding: 8px;
    }

    .dropzone {
        justify-content: center;
        display: flex;
    }

    .dropzone label {
        width: 600px;
        height: 300px;
        border-radius: 8px;
        border: 2px dashed ${props => props.theme.inputBorder || '#ccc'};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: ${props => props.theme.dropzoneBackground || '#f8f8f8'};
        transition: all 0.2s ease-in-out;
    }

    .dropzone label:hover {
        background: ${props => props.theme.dropzoneHoverBackground || '#f3f4f6'};
    }

    .dropzone label div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: transparent;
    }

    .dropzone label div i {
        font-size: 40px;
        color: ${props => props.theme.secondaryText || '#ccc'};
    }

    .dropzone label div p {
        color: ${props => props.theme.secondaryText || '#ccc'};
        font-size: 16px;
        margin-top: 10px;
    }

    .dropzone label input {
        display: none;
    }

    .imageSelector {
        height: 200px;
        overflow: auto;
        border: 1px solid ${props => props.theme.inputBorder || '#ccc'};
        background: ${props => props.theme.inputBackground || 'white'};
    }
`;

const Tool = styled.div`
  font-family: "Plus Jakarta Sans", sans-serif;
  margin-bottom: 4em;
  border-radius: 36px;
  max-width: 100%;
  transition: all 0.2s ease-in-out;
  break-inside: avoid;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);

  .description {
    padding: 3em;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2em;
    
    h2 > a {
      font-weight: 900;
      text-underline-offset: 4px;
    }
  }
  
  .buttons {
    display: flex;
    gap: 4px;
    margin: 3em 0 -1.5em;
  }
  
  button {
    border-radius: 72px;
    background: ${props => props.theme.buttonBackground || '#FBFBF9'};
    color: ${props => props.theme.buttonText || '#000000'};
    cursor: pointer;
    border: none !important;
    text-transform: uppercase;
    padding: 0.8em 1.5em;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.2s ease-in-out;
    white-space: nowrap;
  }

  button i {
    transition: all 0.2s ease-in-out;
    margin-left: 0.5em;
    transform: ${({expanded}) => (expanded ? 'rotate(45deg)' : 'rotate(135deg)')};
  }

  button:hover {
    cursor: pointer;
    background: ${props => props.theme.buttonHoverBackground || '#f3f3f1'};
  }

  button:active {
    transform: scale(0.98);
  }

  .expanded {
    padding: ${({expanded}) => (expanded ? '0 3em 3em 3em' : '0 3em 0 3em')};
    max-height: ${({expanded}) => (expanded ? '4000px' : '0')};
    opacity: ${({expanded}) => (expanded ? '1' : '0')};
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .expanded p {
    font-size: 16px;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
    color: ${props => props.theme.darkMode ? 'inherit' : '#000'};
  }

  h2 {
    color: ${props => props.theme.darkMode ? 'inherit' : '#000'};
    font-size: 36px;
    font-weight: 700;
    line-height: 1.35;
    margin: 0;
  }

  a {
    color: ${props => props.theme.linkColor || (props.theme.darkMode ? 'inherit' : '#000')};
    font-style: normal;
    font-weight: 700;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
  }

  a:hover {
    color: ${props => props.theme.linkHoverColor || (props.theme.darkMode ? '#ffffff' : '#2c2c2c')};
  }

  p {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
    color: ${props => props.theme.darkMode ? 'inherit' : '#000'};
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }

  img {
    max-width: 100%;
    margin-bottom: -20px;
    border-radius: 36px;
  }

  iframe {
    min-height: 300px;
    width: 100%;
  }

  .ql-editor img {
    width: 100%;
  }

  .ql-editor h3 {
    margin-bottom: 1em;
    color: ${props => props.theme.darkMode ? 'inherit' : 'initial'};
  }

  li {
    margin-left: 10px !important;
    color: ${props => props.theme.darkMode ? 'inherit' : 'initial'};
  }
  
  ul {
    list-style-position: inside;
    color: ${props => props.theme.darkMode ? 'inherit' : 'initial'};
  }

  .expanded ul, .expanded ol {
    color: ${props => props.theme.darkMode ? 'inherit' : 'initial'};
  }

  @media (max-width: 1480px) {
    h2 {
      font-size: 30px;
    }

    p {
      font-size: 16px;
    }

    .description {
      padding: 2.5em;
    }
    
    .ql-editor ol, .ql-editor ul {
      padding-left: 0.2em;
    }

    .ql-indent-1 {
      padding-left: 2.7em !important;
    }
  }

  @media (max-width: 1024px) {
    margin-bottom: 2.5em;
    
    h2 {
      font-size: 26px;
    }

    p {
      font-size: 16px;
    }

    .description {
      padding: 2em;
    }

    .expanded {
      padding: ${({expanded}) => (expanded ? '0 1.5em 1.5em 1.5em' : '0 1.5em 0 1.5em')};
      max-height: ${({expanded}) => (expanded ? '4000px' : '0')};
      opacity: ${({expanded}) => (expanded ? '1' : '0')};
      overflow: hidden;
      transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .buttons {
      margin: 2em 0 -1em;
    }

    .ql-editor h3 {
      font-size: 18px !important;
    }
  }

  @media (max-width: 700px) {
    border-radius: 30px;
    
    img {
      border-radius: 30px;
    }
    
    p, button, li {
      font-size: 14px;
    }

    .heading {
      padding-bottom: 1.5em;
    }
    
    .description {
      padding: 1.8em;
    }

    iframe {
      min-height: 200px;
    }
  }
`

const Dropzone = ({ onFileChange, selectedFiles }) => {
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            onFileChange({ target: { files: acceptedFiles } });
        },
    });

    return (
        <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <label>
                <div>
                    {selectedFiles.length > 0 ? (
                        selectedFiles.map((file, i) => (
                            <p key={i} style={{ color: 'inherit' }}>
                                <b>{file.name}</b>
                            </p>
                        ))
                    ) : (
                        <>
                            <i className="fas fa-cloud-upload-alt"></i>
                            <p style={{ color: 'inherit' }}>
                                <b>Click to upload</b> or drag and drop
                            </p>
                        </>
                    )}
                    <p style={{ color: 'inherit' }}>Only PNG, JPG, JPEG, or GIF types are allowed</p>
                </div>
            </label>
        </div>
    );
};

const ImageSelector = ({ onImageSelect }) => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        // Fetch images from the server
        const fetchImages = async () => {
            try {
                const response = await fetch('https://hex.lv/chromecast/?getImages');
                if (response.ok) {
                    const data = await response.json();
                    setImages(data);
                }
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };
        fetchImages();
    }, []);

    return (
        <div className="imageSelector">
            {images.map((image) => (
                <img
                    key={image.id}
                    src={image.url}
                    alt={image.name}
                    onClick={() => onImageSelect(image)}
                    style={{ 
                        width: '100px', 
                        height: '100px', 
                        objectFit: 'cover', 
                        margin: '5px', 
                        cursor: 'pointer',
                        borderRadius: '4px',
                        transition: 'transform 0.2s ease',
                        border: '1px solid transparent'
                    }}
                    onMouseOver={(e) => {
                        e.currentTarget.style.transform = 'scale(1.05)';
                        e.currentTarget.style.borderColor = 'var(--primary-color, #ccc)';
                    }}
                    onMouseOut={(e) => {
                        e.currentTarget.style.transform = 'scale(1)';
                        e.currentTarget.style.borderColor = 'transparent';
                    }}
                />
            ))}
        </div>
    );
};

export default class Tools extends React.Component {
    modules = {
        toolbar: [
            [{'header': [2, 3, 4, false]}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image', 'video'],
            ['clean']
        ],
    };
    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ];

    constructor(props) {
        super(props);
        this.state = {
            availableTools: {},
            expandedTool: null,
            settings: null,
            editedDescription: '',
            editedExpanded: '',
            viewPermissions: [],
            selectedFiles: [],
            selectedImage: null,
            isUploadModalOpen: false,
            selectedViewCompany: null,
            userCompanies: API.shared.user.companies
                .filter(company => company.active)
                .map(company => company.companyId),
        };

        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.handleExpandedChange = this.handleExpandedChange.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.sendFilesToServer = this.sendFilesToServer.bind(this);
        this.toggleUploadModal = this.toggleUploadModal.bind(this);
        this.handleImageSelect = this.handleImageSelect.bind(this);
        this.handleViewCompanyChange = this.handleViewCompanyChange.bind(this);
    }

    componentDidMount() {
        this.loadData()
    }

    async loadData() {
        try {
            const response = await fetch('https://hex.lv/tools/?getTools&key=7sa97eh201hs812j', {
                method: 'GET',
            });
            const responseJson = await response.json();
            if (response.ok) {
                const sortedTools = Object.entries(responseJson)
                    .sort(([, toolA], [, toolB]) => toolB.priority - toolA.priority)
                    .reduce((acc, [key, value]) => {
                        acc[key] = value;
                        return acc;
                    }, {});
                this.setState(() => ({
                    availableTools: sortedTools,
                }));
            }
        } catch (error) {
            console.error(`Error getting tools from the server:`, error);
        }
    }

    async onSubmit(e) {
        e.preventDefault();

        if (API.shared.hasPermission('TOOL_EDITOR')) {
            const formData = new FormData(e.target);
            formData.set('description', this.state.editedDescription)
            formData.set('expanded', this.state.editedExpanded)
            formData.set('permissions', this.state.viewPermissions.map(option => option.value))
            if (this.state.selectedImage) {
                formData.set('img', this.state.selectedImage.url);
            }

            try {
                const response = await fetch('https://hex.lv/tools/?key=7sa97eh201hs812j', {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    location.reload();
                    console.log('Settings updated successfully');
                }
            } catch (error) {
                console.error('Error updating settings:', error);
            }
        }
    }

    async sendFilesToServer() {
        const selectedFiles = Array.from(this.state.selectedFiles);

        const promises = selectedFiles.map(async (file, index) => {
            const compressedFile = await imageCompression(file, {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            });

            const img = new Image();
            img.src = URL.createObjectURL(file);

            await new Promise((resolve) => {
                img.onload = resolve;
            });

            const formData = new FormData();
            formData.append('user', 1);
            formData.append('data', compressedFile.name);
            formData.append('type', compressedFile.type);
            formData.append('width', img.width);
            formData.append('height', img.height);
            formData.append('file', compressedFile);

            console.log(formData.get('user'), formData.get('data'), formData.get('type'), formData.get('width'), formData.get('height'), formData.get('file'));

            try {
                const response = await fetch('https://hex.lv/chromecast/', {
                    method: 'POST',
                    body: formData,
                });
                const responseJson = await response.json();
                if (response.ok) {
                    console.log(`File ${index + 1} uploaded successfully`);
                } else {
                    console.error(`File ${index + 1} upload failed: ${responseJson}`);
                }
            } catch (error) {
                console.error(`Error sending file ${index + 1} to the server:`, error);
            }
        });

        await Promise.all(promises);
        this.setState({ selectedFiles: [], isUploadModalOpen: false });
        this.loadData(); // Reload the tools data
    }

    toggleTool(toolKey) {
        const tool = this.state.availableTools[toolKey];

        if (tool && tool.expanded && tool.expanded.trim() !== "<p><br></p>") {
            this.setState((prevState) => ({
                expandedTool: prevState.expandedTool === toolKey ? null : toolKey,
            }));
        }
    }

    toggleSettings(tool) {
        this.setState((prevState) => ({
            settings: tool,
            editedDescription: tool ? (prevState.editedDescription ? '' : tool.description) : '',
            editedExpanded: tool ? (prevState.editedExpanded ? '' : tool.expanded) : '',
        }));

        if (tool && tool.permissions) {
            this.setState({
                viewPermissions: tool.permissions.split(',').map(company => ({
                    value: company,
                    label: API.shared.companyName(company)
                }))
            })
        }
    }

    toggleCreateTool() {
        this.setState((prevState) => ({
            settings: Object.keys(this.state.availableTools).length + 1,
            editedDescription: '',
            editedExpanded: '',
        }))
    }

    toggleUploadModal() {
        this.setState((prevState) => ({
            isUploadModalOpen: !prevState.isUploadModalOpen,
        }));
    }

    onFileChange(event) {
        const selectedFiles = Array.from(event.target.files);
        this.setState({ selectedFiles });
    }

    handleViewPermissionsChange(value) {
        this.setState({viewPermissions: value})
    }

    handleDescriptionChange(value) {
        this.setState({editedDescription: value});
    }

    handleExpandedChange(value) {
        this.setState({editedExpanded: value});
    }

    handleImageSelect(image) {
        this.setState({ selectedImage: image });
    }

    handleViewCompanyChange(selectedCompany) {
        this.setState({ selectedViewCompany: selectedCompany });
    }

    handleDeleteTool() {
        if (window.confirm('Are you sure you want to delete this tool?')) {
            const formData = new FormData();
            formData.append('deleteTool', this.state.settings.id);
            fetch(`https://hex.lv/tools/?key=7sa97eh201hs812j`, {
                method: 'POST',
                body: formData,
            }).then(response => {
                if (response.ok) {
                    location.reload();
                    console.log('Tool deleted successfully');
                } else {
                    console.error('Error deleting tool:', response.statusText);
                }
            }).catch(error => {
                console.error('Error deleting tool:', error);
            });
        } else {
            console.log('Tool deletion cancelled');
        }
    }

    render() {
        const {availableTools, expandedTool, settings, userCompanies, selectedViewCompany} = this.state;
        const companyOptions = Object.keys(API.shared.companies).map(id => ({
            value: id,
            label: API.shared.companies[id].name,
        }));

        const visibleTools = Object.keys(availableTools)
            .filter(toolKey => {
                const tool = availableTools[toolKey];
                const toolPermissions = tool.permissions ? tool.permissions.split(',').map(Number) : [];

                if (selectedViewCompany) {
                    return toolPermissions.includes(parseInt(selectedViewCompany.value));
                }

                return !tool.permissions ||
                    userCompanies.some(companyId => toolPermissions.includes(companyId)) || API.shared.hasPermission('TOOL_EDITOR');
            })
            .sort((a, b) => availableTools[b].priority - availableTools[a].priority)
            .map(toolKey => {
                const tool = availableTools[toolKey];
                return (
                    <Tool
                        key={toolKey}
                        style={{backgroundColor: tool.color}}
                        expanded={expandedTool === toolKey}
                    >
                        <div className="description">
                            <div className="heading">
                                <h2>
                                    <a href={tool.link} target="_blank">{tool.name}</a>
                                </h2>
                            </div>
                            <div dangerouslySetInnerHTML={{__html: tool.description}}/>
                            <div className="buttons">
                                {tool && tool.expanded && tool.expanded.trim() !== "<p><br></p>" && (
                                    <div className="expandButton">
                                        <button onClick={() => this.toggleTool(toolKey)}>Uzzināt Vairāk
                                            <i className="fas fa-arrow-up" ></i>
                                        </button>
                                    </div>
                                )}
                                {API.shared.hasPermission('TOOL_EDITOR') && (
                                    <button className="editButton" onClick={() => this.toggleSettings(tool)}>Edit</button>
                                )}
                            </div>
                        </div>

                        <div className="expanded ql-editor" dangerouslySetInnerHTML={{__html: tool.expanded}}/>
                        <img src={tool.img} alt={tool.name}/>
                    </Tool>
                );
            });

        return (
            <ToolsLayout title="Employee Resources">
                {API.shared.hasPermission('TOOL_EDITOR') &&
                    <>
                        <Toolbar 
                            leftElements={
                                <>
                                    <ToolbarButton icon="plus" onClick={() => this.toggleCreateTool()}>
                                        Create new tool
                                    </ToolbarButton>
                                    <ToolbarButton icon="cloud-upload-alt" onClick={() => this.toggleUploadModal()}>
                                        Upload images
                                    </ToolbarButton>
                                </>
                            }
                            rightElements={
                                <div className="companySelector">
                                    <ReactSelect
                                        options={companyOptions}
                                        value={selectedViewCompany}
                                        onChange={this.handleViewCompanyChange}
                                        placeholder="View as company..."
                                    />
                                </div>
                            }
                        />
                    </>
                }

                <div className="container">
                    {visibleTools}
                </div>
                {settings && API.shared.hasPermission('TOOL_EDITOR') && (
                    <div className="modal">
                        <div className="modalContent" onClick={(e) => e.stopPropagation()}>
                            <div className="flex">
                                <h3>{settings.name}</h3>
                                <div style={{flexGrow: '0'}}>
                                    <button onClick={() => this.handleDeleteTool()} className="delete-btn">Delete</button>
                                    <button onClick={() => this.toggleSettings(null)}>Close</button>

                                </div>
                            </div>
                            <div className="settingsContent">
                            <form onSubmit={(e) => this.onSubmit(e, settings)}>
                                    <div className="flex">
                                        <div>
                                            <label>Title</label>
                                            <input type="text" name="name" defaultValue={settings.name} required />
                                            <input type="hidden" name="id" value={settings.id} />
                                        </div>
                                        <div>
                                            <label>Link</label>
                                            <input type="text" name="link" defaultValue={settings.link} required />
                                        </div>
                                        <div>
                                            <label>Background Color</label>
                                            <input type="text" name="color" defaultValue={settings.color} required />
                                        </div>
                                    </div>

                                    <div>
                                        <label>Viewing Permissions</label>
                                        <ReactSelect
                                            isMulti
                                            options={companyOptions}
                                            value={this.state.viewPermissions}
                                            onChange={values => this.handleViewPermissionsChange(values)}
                                        />
                                    </div>
                                    <div>
                                        <label>Priority</label>
                                        <input type="number" name="priority" defaultValue={settings.priority} required />
                                    </div>

                                    <div>
                                        <label>Select Background Image </label>
                                        <ImageSelector onImageSelect={this.handleImageSelect} />
                                    </div>
                                    <div className={"flex"}>
                                        <div>
                                            <label>Current Image:</label>
                                            <img src={settings.img} style={{ width: '200px' }} />
                                            <input type="hidden" name="img" value={settings.img} />
                                        </div>
                                        {this.state.selectedImage && (
                                            <div>
                                                <label>Selected Image:</label>
                                                <img src={this.state.selectedImage.url} alt={this.state.selectedImage.name} style={{ width: '200px' }} />
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <label>Description</label>
                                        <ReactQuill
                                            value={this.state.editedDescription}
                                            onChange={this.handleDescriptionChange}
                                            modules={this.modules}
                                            formats={this.formats}
                                        ></ReactQuill>
                                    </div>
                                    <div>
                                        <label>Expanded Description</label>
                                        <ReactQuill
                                            value={this.state.editedExpanded}
                                            onChange={this.handleExpandedChange}
                                            modules={this.modules}
                                            formats={this.formats}
                                        ></ReactQuill>
                                    </div>
                                <div>
                                    <button>Save</button>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.isUploadModalOpen && (
                    <div className="modal" onClick={this.toggleUploadModal}>
                        <div className="modalContent uploadModal" onClick={(e) => e.stopPropagation()}>
                            <h3>Upload new image</h3>
                            <Dropzone onFileChange={this.onFileChange} selectedFiles={this.state.selectedFiles} />
                            <div className="modalActions">
                                <span>{this.state.selectedFiles.length} image(s) selected</span>
                                <button onClick={this.sendFilesToServer}>Upload</button>
                            </div>
                        </div>
                    </div>
                )}
            </ToolsLayout>
        );
    }
}
