import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getTagColor } from "../../views/Events";
import { useTheme } from '../../ThemeContext';
import { Skeleton, TextSkeleton, CircleSkeleton, CardSkeleton, SkeletonGroup } from '../Skeleton';

const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;

const EventModal = styled.div`
    background-color: ${props => props.background || 'white'};
    color: ${props => props.textColor || '#FFFFFF'};
    padding: 2.5rem;
    border-radius: 24px;
    width: 55%;
    max-height: 85vh;
    overflow-y: auto;
    position: relative;
    transition: all 0.3s ease;
    
    @media (max-width: 1080px) {
        width: 95%;
        padding: 1.5rem;
    }
`;

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    h2 {
        font-size: 2.5rem;
        font-weight: 800;
        letter-spacing: -0.02em;
        color: ${props => props.theme.isDarkMode ? props.theme.text : '#232323'};
        margin: 0;

        @media (max-width: 1080px) {
            font-size: 2rem;
        }
    }
`;

const CloseButton = styled.button`
    background-color: ${props => props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)'};
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    color: ${props => props.theme.text};
    padding: 10px;

    &:hover {
        background-color: ${props => props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)'};
        transform: rotate(90deg);
    }

    svg {
        width: 30px;
        height: 30px;
    }
`;

const Title = styled.h3`
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: -0.01em;
    color: inherit;
    margin: 0 0 1rem 0;
    line-height: 1.3;

    @media (max-width: 1080px) {
        font-size: 1.5rem;
    }
`;

const Description = styled.div`
    font-size: 1.125rem;
    line-height: 1.6;
    color: inherit;
    margin: 0 0 2rem 0;

    @media (max-width: 1080px) {
        font-size: 1rem;
    }
    
    /* Rich Text Content Styles */
    h1, h2, h3 {
        margin-top: 1.5rem;
        margin-bottom: 1rem;
        font-weight: 600;
        color: inherit;
    }
    
    h1 {
        font-size: 1.8rem;
    }
    
    h2 {
        font-size: 1.5rem;
    }
    
    h3 {
        font-size: 1.3rem;
    }
    
    p {
        margin-bottom: 1rem;
    }
    
    ul, ol {
        margin-left: 1.5rem;
        margin-bottom: 1rem;
    }
    
    li {
        margin-bottom: 0.5rem;
    }
    
    blockquote {
        border-left: 4px solid ${props => props.theme.isDarkMode ? '#444' : '#ddd'};
        padding-left: 1rem;
        margin-left: 0;
        color: ${props => props.theme.isDarkMode ? '#aaa' : '#666'};
    }
    
    a {
        color: ${props => props.theme.isDarkMode ? '#4f8eff' : '#0066cc'};
        text-decoration: none;
    }
    
    a:hover {
        text-decoration: underline;
    }
    
    img {
        max-width: 100%;
        height: auto;
        margin: 1rem 0;
    }
`;

const DetailsSection = styled.section`
    margin-bottom: 1.5rem;

    &:last-child {
        margin-bottom: 0;
    }
`;

const DetailsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    margin-top: 0.75rem;
`;

const DetailLabel = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    color: inherit;

    svg {
        width: 18px;
        height: 18px;
    }
`;

const DetailItem = styled.span`
    font-size: 1rem;
    color: inherit;
    background-color: ${props => props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.08)'};
    padding: 0.5rem 1rem;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;

    svg {
        width: 16px;
        height: 16px;
    }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 2rem;
`;

const Tag = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background-color: ${props => props.background || (props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)')};
  color: ${props => props.theme.isDarkMode ? props.theme.text : '#232323'};
  
  svg {
    width: 16px;
    height: 16px;
  }
`;

const LinkTag = styled(Tag)`
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const LinkText = styled.a`
  color: inherit;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  
  &:hover {
    text-decoration: underline;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  
  ${props => props.primary ? `
    background-color: ${props.theme.isDarkMode ? props.theme.buttonBackground : '#232323'};
    color: ${props.theme.isDarkMode ? props.theme.buttonText : 'white'};
    border: none;
    
    &:hover {
      background-color: ${props.theme.isDarkMode ? props.theme.buttonHoverBackground : '#000'};
      transform: translateY(-1px);
    }
  ` : `
    background-color: ${props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)'};
    color: ${props.theme.isDarkMode ? props.theme.text : '#232323'};
    border: none;
    
    &:hover {
      background-color: ${props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)'};
      transform: translateY(-1px);
    }
  `}
`;
// Icon components
const CloseIcon = () => (
    <svg
        height="40px"
        width="40px"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        aria-hidden="true"
    >
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
    </svg>
);

const IconParticipants = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        height="16px"
        aria-hidden="true"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z"
        />
    </svg>
);

const IconOrganizators = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        height="16px"
        aria-hidden="true"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5"
        />
    </svg>
);

const IconTime = () => (
    <svg
        height="16px"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.7}
        stroke="currentColor"
        aria-hidden="true"
    >
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
    </svg>
);

const IconLocation = () => (
    <svg
        height="16px"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.7}
        stroke="currentColor"
        aria-hidden="true"
    >
        <path d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
        <path d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
    </svg>
);

const IconUrl = () => (
    <svg
        height="16px"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.7}
        stroke="currentColor"
        aria-hidden="true"
    >
        <path d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
    </svg>
);

// Main component - Loading skeleton has been moved to Skeleton.jsx

// Main component
const EventDetails = ({ event, onClose, onDelete, onEdit, isLoading }) => {
    const { isDarkMode } = useTheme();
    
    // Return null if loading or no event is provided
    if (isLoading || !event) return null;

    const { start, end, title, description, participants, organizators, tags, url, hasTime, location } = event;
    // Get the primary tag for the main color scheme (using its color)
    let tagColor;
    if (tags && tags.length > 0) {
        const colorData = getTagColor(tags[0].color, isDarkMode);
        // Use vibrant colors with slightly darker background for modal
        const darkenColor = (color) => {
            // Create slightly darker version of the base color for better readability in the modal
            const r = parseInt(color.slice(1, 3), 16);
            const g = parseInt(color.slice(3, 5), 16);
            const b = parseInt(color.slice(5, 7), 16);
            return `rgb(${Math.max(0, r-20)}, ${Math.max(0, g-20)}, ${Math.max(0, b-20)})`;
        };
        
        tagColor = { 
            backgroundColor: darkenColor(colorData.baseColor),
            color: "#000" // Use auto-calculated text color
        };
    } else {
        tagColor = { 
            backgroundColor: isDarkMode ? '#252525' : '#333333',
            color: "#000"
        };
    }

    return (
        <ModalContainer role="dialog" aria-modal="true">
            <EventModal background={tagColor.backgroundColor} textColor={tagColor.color}>
                <Header>
                    <h2 style={{ color: 'inherit' }}>{moment(start).format('MMM Do')}</h2>
                    <CloseButton onClick={onClose} aria-label="Close modal">
                        <CloseIcon />
                    </CloseButton>
                </Header>

                <DetailsSection>
                    <Title>{title}</Title>
                    <Description 
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                </DetailsSection>

                {organizators && organizators.length > 0 && (
                    <DetailsSection>
                        <DetailLabel>
                            <IconOrganizators />
                            Organized by
                        </DetailLabel>
                        <DetailsList>
                            {organizators.map((org, index) => (
                                <DetailItem key={index}>
                                    {org.name}
                                </DetailItem>
                            ))}
                        </DetailsList>
                    </DetailsSection>
                )}

                {participants && participants.length > 0 && (
                    <DetailsSection>
                        <DetailLabel>
                            <IconParticipants />
                            Participants
                        </DetailLabel>
                        <DetailsList>
                            {participants.map((participant, index) => (
                                <DetailItem key={index}>
                                    {participant.name}
                                </DetailItem>
                            ))}
                        </DetailsList>
                    </DetailsSection>
                )}

                <TagsContainer>
                    {hasTime && (
                        <Tag background={isDarkMode ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.08)'} color={tagColor.color}>
                            <IconTime />
                            {moment(start).format('HH:mm')}
                            {!moment(start).isSame(moment(end)) && ` - ${moment(end).format('HH:mm')}`}
                        </Tag>
                    )}

                    <Tag background={isDarkMode ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.08)'} color={tagColor.color}>
                        <IconLocation />
                        {location}
                    </Tag>

                    {url && url !== 'null' && (
                        <LinkTag background={isDarkMode ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.08)'} color={tagColor.color}>
                            <IconUrl />
                            <LinkText href={url} target="_blank" rel="noopener noreferrer">
                                {url}
                            </LinkText>
                        </LinkTag>
                    )}

                    {tags && tags.length > 0 && tags.map((tag, index) => {
                        // Get tag color with consistent properties
                        const tagColorData = getTagColor(tag.color, isDarkMode);
                        let thisTagColor;
                        
                        if (index === 0) {
                            // Primary tag - use a vibrant opaque background
                            thisTagColor = { 
                                background: tagColorData.baseColor, 
                                color: tagColorData.color // Use auto-calculated text color
                            };
                        } else {
                            // For secondary tags, use semi-transparent vibrant backgrounds
                            thisTagColor = { 
                                background: tagColorData.baseColor + 'CC', // CC = 80% opacity in hex
                                color: tagColorData.color // Use auto-calculated text color
                            };
                        }
                        
                        return (
                            <Tag 
                                key={index} 
                                background={thisTagColor.background} 
                                color={thisTagColor.color}
                            >
                                {tag.name}
                            </Tag>
                        );
                    })}
                </TagsContainer>

                {(onEdit || onDelete) && (
                    <ButtonGroup>
                        {onEdit && <Button onClick={() => onEdit(event)}>Edit Event</Button>}
                        {onDelete && (
                            <Button primary onClick={() => onDelete(event.id)}>
                                Delete Event
                            </Button>
                        )}
                    </ButtonGroup>
                )}
            </EventModal>
        </ModalContainer>
    );
};

EventDetails.propTypes = {
    event: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    isLoading: PropTypes.bool
};

export default EventDetails;
