
// ===================================================================

import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import API from '../API'
import { ThemeContext } from '../ThemeContext'

import views from '../views'
import packageJson from '../../package.json'

// ===================================================================

const SideMenuStyle = styled.div`
  min-height: 100%;
  color: ${props => props.theme.text};
  background: ${props => props.theme.sideMenuBackground};
  box-shadow: ${props => props.theme.boxShadow};
  padding: ${props => props.collapsed ? '20px 10px' : '20px 15px'};
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  width: ${props => props.collapsed ? '80px' : '300px'};
  scrollbar-width: thin;
  scrollbar-color: ${props => props.theme.scrollbarThumb || 'rgba(0,0,0,0.2)'} transparent;

  &::-webkit-scrollbar {
    width: 5px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.scrollbarThumb || 'rgba(0,0,0,0.2)'};
    border-radius: 10px;
  }

  & > .User {
    padding: 30px 10px 25px;
    position: relative;
    margin-bottom: 15px;
    
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 15px;
      right: 15px;
      height: 1px;
      background: ${props => props.theme.borderColor || 'rgba(0,0,0,0.05)'};
      opacity: 0.5;
    }
    
    & > .UserWrapper {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      border-radius: 8px;
      transition: all 0.2s ease;
      padding: 5px;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      user-select: none;
      
      @media (hover: hover) {
        &:hover {
          transform: translateX(2px);
        }
      }
      
      & > .Image {
        position: relative;
        margin-right: 15px;
        
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          right: -2px;
          width: 11px;
          height: 11px;
          background-color: #22c55e;
          border-radius: 50%;
          border: 2px solid ${props => props.theme.background}; 
          z-index: 2;
        }
        
        img, .DefaultAvatar {
          height: 50px;
          width: 50px;
          transition: all 0.2s ease-in-out;
          border-radius: 10px;
          box-shadow: 0 2px 5px rgba(0,0,0,0.06);
          object-fit: cover;
          
          @media (hover: hover) {
            :hover {
              transform: translateY(-2px);
              box-shadow: 0 3px 7px rgba(0,0,0,0.12);
            }
          }
        }
        
        .DefaultAvatar {
          background: ${props => props.theme.menuActiveBackground || '#eee'};
          color: ${props => props.theme.text};
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          font-weight: 600;
        }
      }
      
      & > .UserInfo {
        text-align: left;
        flex: 1;
        
        & > .Name {
          font-weight: 600;
          font-size: 15px;
          margin-bottom: 2px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 170px;
        }
        
        & > .Company {
          font-size: 13px;
          color: ${props => props.theme.secondaryText};
          opacity: 0.8;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 170px;
        }
      }
    }
    
    & > .Options {
      color: ${props => props.theme.tertiaryText};
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-right: 10px;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      user-select: none;
      
      span {
        font-size: 20px;
        transition: all 0.2s ease;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.7;
      }
      
      @media (hover: hover) {
        span:hover {
          color: ${props => props.theme.text};
          opacity: 1;
          transform: translateY(-2px);
        }
      }
      
      @media (hover: none) {
        span:active {
          color: ${props => props.theme.text};
          opacity: 1;
        }
      }
    }
  }
  
  & > .SectionTitle {
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: ${props => props.theme.tertiaryText};
    margin: 20px 15px 8px;
    padding-bottom: 4px;
    display: flex;
    align-items: center;
    font-weight: 500;
    opacity: 0.65;
    
    &::after {
      content: '';
      flex-grow: 1;
      height: 1px;
      background: ${props => props.theme.borderColor || 'rgba(0,0,0,0.05)'};
      margin-left: 8px;
    }
  }
  
  & > .NavigationSection {
    padding: 2px 0;
    margin-bottom: 5px;
  }
  
  & a { /* NavLink and External Links */
    color: ${props => props.theme.secondaryText};
    padding: 9px 15px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    margin: 2px 5px;
    transition: all 0.2s ease;
    text-decoration: none;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    user-select: none;
    
    @media (hover: hover) {
      &:hover {
        color: ${props => props.theme.text};
        background: ${props => props.theme.menuHoverBackground || 'rgba(0,0,0,0.03)'};
        transform: translateX(2px);
      }
    }
    
    /* For touch devices */
    @media (hover: none) {
      &:active {
        color: ${props => props.theme.text};
        background: ${props => props.theme.menuHoverBackground || 'rgba(0,0,0,0.03)'};
      }
    }

    span {
      margin-right: 12px;
      font-size: 19px;
      min-width: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    &.active {
      color: ${props => props.theme.accentColor || props.theme.text};
      background: ${props => props.theme.menuActiveBackground};
      font-weight: 600;
      box-shadow: 0 2px 4px rgba(0,0,0,0.03);
      
      span {
        color: ${props => props.theme.accentColor || props.theme.text};
      }
    }
  }
  
  & > .ExternalLinks {
    a {
      transition: all 0.2s ease;
      padding: 7px 15px;
      font-size: 13px;
      opacity: 0.7;
      margin: 1px 0;
      border-radius: 8px;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      user-select: none;
      
      span {
        font-size: 17px;
        min-width: 22px;
      }
      
      @media (hover: hover) {
        &:hover {
          transform: translateX(2px);
          opacity: 1;
          background: ${props => props.theme.menuHoverBackground || 'rgba(0,0,0,0.02)'};
        }
      }
      
      @media (hover: none) {
        &:active {
          opacity: 1;
          background: ${props => props.theme.menuHoverBackground || 'rgba(0,0,0,0.02)'};
        }
      }
    }
  }
  
  & > .Version {
    text-align: center;
    font-size: 10px;
    color: ${props => props.theme.tertiaryText};
    margin-top: auto;
    margin-bottom: 5px;
    opacity: 0.5;
  }
`

// ===================================================================

const SideMenu = () => {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);
  const collapsed = false; // Always expanded
  
  const reservaLogin = (e) => {
    e.preventDefault();
    API.shared.get('auth/get-reserva-login').then(data => {
      if(data && data.redirectUrl) {
        location.href = data.redirectUrl;
      }
    });
    return false;
  }
  
  // Group and filter app navigation links
  const appLinks = views.filter(view => 
    view.sideMenu && 
    (!view.requiresPermission || API.shared.hasPermission(view.requiresPermission))
  );
  
  // Group links by category (if we wanted to categorize them in the future)
  const mainLinks = appLinks.filter(link => 
    !['Chromecast', 'Users'].includes(link.sideMenu.title)
  );
  
  const adminLinks = appLinks.filter(link => 
    ['Chromecast', 'Users'].includes(link.sideMenu.title)
  );
  
  // External links are now handled directly in JSX
  
  // Dispatch event to notify Layout component that sidebar is always expanded
  React.useEffect(() => {
    // Set in localStorage that it's not collapsed
    localStorage.setItem('sidebarCollapsed', 'false');
    
    // Dispatch custom event to notify Layout component
    const event = new CustomEvent('sidebar-collapse-change', { 
      detail: { collapsed: false } 
    });
    window.dispatchEvent(event);
  }, []);

  return (
    <SideMenuStyle collapsed={collapsed}>
      <div className="User">
        <div 
          className="UserWrapper" 
          onClick={() => window.location.href = '/profile'} 
          style={{ cursor: 'pointer' }}
          title="Open My Profile"
        >
          <div className="Image">
            {API.shared.user.imagePath ? (
              <img src={API.shared.user.imagePath} alt="User profile" />
            ) : (
              <div className="DefaultAvatar">
                {API.shared.user.name.charAt(0)}
              </div>
            )}
          </div>
          <div className="UserInfo">
            <div className="Name">{API.shared.user.name}</div>
            <div className="Company">
              {API.shared.user.companies
                .filter(company => company.active)
                .map(company => API.shared.companyName(company.companyId))
                .join(', ')}
            </div>
          </div>
        </div>
        <div className="Options">
          <span 
            className="material-symbols-outlined" 
            onClick={toggleTheme}
            title={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
          >
            {isDarkMode ? "light_mode" : "dark_mode"}
          </span>
          <span 
            className="material-symbols-outlined" 
            onClick={() => API.shared.logout()}
            title="Logout"
          >
            logout
          </span>
        </div>
      </div>
      
      <div className="SectionTitle">Main Navigation</div>
      <div className="NavigationSection">
        {mainLinks.map(view => (
          <MenuLink 
            key={view.path} 
            to={view.path} 
            title={view.sideMenu.title}
            icon={view.sideMenu.icon}
          />
        ))}
      </div>
      
      {adminLinks.length > 0 && (
        <>
          <div className="SectionTitle">Administration</div>
          <div className="NavigationSection">
            {adminLinks.map(view => (
              <MenuLink 
                key={view.path} 
                to={view.path} 
                title={view.sideMenu.title}
                icon={view.sideMenu.icon}
              />
            ))}
          </div>
        </>
      )}
      
      <div className="SectionTitle">External Resources</div>
      <div className="ExternalLinks">
        <a href="https://hex.lv/rekviziti/" className="ExternalLink" target="_blank" rel="noopener noreferrer">
          <span className="material-symbols-outlined">info</span>
          Company Details
        </a>
        <a href="https://dg.caterevo.com/" className="ExternalLink" target="_blank" rel="noopener noreferrer">
          <span className="material-symbols-outlined">open_in_new</span>
          Caterevo
        </a>
        <a href="#" className="ExternalLink" onClick={e => reservaLogin(e)}>
          <span className="material-symbols-outlined">open_in_new</span>
          Reserva
        </a>
        <a href="https://1work.com/dashboard" className="ExternalLink" target="_blank" rel="noopener noreferrer">
          <span className="material-symbols-outlined">open_in_new</span>
          1WORK
        </a>
        <a href="https://draugiemgroup.freshdesk.com/" className="ExternalLink" target="_blank" rel="noopener noreferrer">
          <span className="material-symbols-outlined">open_in_new</span>
          Freshdesk
        </a>
      </div>
      
      <div className="Version">
        v{packageJson.version}
      </div>
    </SideMenuStyle>
  );
}

function MenuLink({ icon, title, ...restProps }) {
  return (
    <NavLink {...restProps} title={title}>
      <span className="material-symbols-outlined">{icon}</span>
      {title}
    </NavLink>
  );
}

export default SideMenu;

// ===================================================================
