// React 16.8.6  |  https://17.reactjs.org/docs/
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { ThemeContext, lightTheme, darkTheme } from '../ThemeContext'

import Layout from '../components/Layout'
import API from '../API'

// ===================================================================
// Constants and Theme
// ===================================================================
const OPEN_DURATION = 3000
const SCROLL_THRESHOLD = 50
const UPDATE_DELAY = 100
const NETWORK_TIMEOUT_DEFAULT = 5000
const NETWORK_TIMEOUT_LOW_BANDWIDTH = 10000
const MAX_RECONNECT_DELAY = 60000
const BATTERY_LOW_THRESHOLD = 0.2

// Use theme properties from ThemeContext instead of local definitions

const animations = `
  @keyframes moveGradient {
    0%, 100% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
  }
  @keyframes pulseGlow {
    0%, 100% {
      box-shadow: 0 0 15px rgba(255, 108, 100, 0.5),
      0 0 20px rgba(255, 142, 102, 0.5),
      0 0 45px rgba(255, 108, 100, 0.25);
    }
    50% {
      box-shadow: 0 0 25px rgba(255, 108, 100, 0.7),
      0 0 30px rgba(255, 142, 102, 0.7),
      0 0 50px rgba(255, 108, 100, 0.4);
    }
  }
  @keyframes shimmer {
    0% { background-position: -200% 0; }
    100% { background-position: 200% 0; }
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

// Icon mappings for different key types
const iconMap = new Map([
  ['door', 'door_front'],
  ['gate', 'gate'],
  ['fence', 'outdoor_garden'],
  ['cafe', 'local_cafe'],
  ['home', 'other_houses'],
  ['truck', 'local_shipping'],
  ['club', 'star'],
])

// ===================================================================
// Styled Components
// ===================================================================
const KeychainLayout = styled(Layout)`
  ${animations}
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  transition: background 0.3s ease, color 0.3s ease;
  
  .Group {
    margin-bottom: 26px;
    width: 100%;
    display: block;
    
    /* Add extra margin for collapsed state */
    &.collapsed {
      margin-bottom: 10px;
    }

    .GroupHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px 14px 10px;
      cursor: pointer;
      
      &:active {
        opacity: 0.7;
      }
      
      .Name {
        font-weight: 500;
        color: ${props => props.themeColors.secondary};
        font-size: 14px;
        transition: color 0.3s ease;
      }
      
      .CollapseIcon {
        font-size: 20px;
        color: ${props => props.themeColors.secondary};
        transition: transform 0.3s ease, color 0.3s ease;
      }
    }
  }
  
  .Keys {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    gap: 8px;
    color: ${props => props.themeColors.primary};
    transition: color 0.3s ease, max-height 0.3s ease, opacity 0.3s ease;
    
    @media (min-width: 768px) {
      gap: 10px;
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    }
  }

  .Key {
    /* Use GPU acceleration and reduce repaints */
    transform: translate3d(0, 0, 0);
    will-change: transform;
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), 
                background 0.3s ease,
                color 0.3s ease,
                box-shadow 0.3s ease,
                grid-column 0.3s ease;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 12px 14px;
    background: ${props => props.themeColors.background};
    border-radius: 16px;
    cursor: pointer;
    overflow: hidden;
    grid-row: span 1;
    position: relative;
    
    @media (min-width: 768px) {
      padding: 16px 20px;
      min-height: 70px;
    }
    
    /* Touch feedback overlay */
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${props => props.themeColors.overlay};
      opacity: 0;
      transition: opacity 0.2s ease, background 0.3s ease;
      pointer-events: none;
      border-radius: 16px;
    }
    
    .Name {
      font-size: 14px;
      font-weight: 600;
      color: ${props => props.themeColors.primary};
      max-width: 100%;
      transition: color 0.3s ease;
      
      span {
        display: inline-block
      }
    }

    .Status {
      font-size: 11px;
      white-space: pre;
      font-weight: 400;
      color: ${props => props.themeColors.secondary};
      transition: color 0.3s ease;
    }

    .Icon {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      padding: 4px;
      background-color: ${props => props.themeColors.backgroundLight};
      border-radius: 50%;
      color: ${props => props.themeColors.border};
      min-width: 38px;
      min-height: 38px;
      transition: background-color 0.3s ease, color 0.3s ease;
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 3px;
      max-width: 100%;
    }

    /* States */
    &:active {
      transform: scale(0.97);
      transition: transform 0.1s cubic-bezier(0.4, 0, 0.2, 1);
      
      &::after {
        opacity: 1;
      }
    }

    &.busy {
      background: linear-gradient(90deg, ${props => props.themeColors.accent} 0%, ${props => props.themeColors.accentGradient} 100%);
      background-size: 200% 100%;
      animation: moveGradient 2s linear infinite;
      z-index: 5;
      box-shadow: 0 0 15px rgba(255, 108, 100, 0.4);
      .Name, .Status { color: white; }
      .Icon { 
        color: ${props => props.themeColors.accent}; 
        background-color: ${props => props.isDarkMode ? 'rgba(255, 255, 255, 0.9)' : props.themeColors.backgroundLight};
      }
    }

    &.isOpen {
      background: linear-gradient(90deg, ${props => props.themeColors.accent} 0%, ${props => props.themeColors.accentGradient} 100%);
      .Name, .Status { color: white; }
      .Icon { 
        color: ${props => props.themeColors.accent}; 
        background-color: ${props => props.isDarkMode ? 'rgba(255, 255, 255, 0.9)' : props.themeColors.backgroundLight};
      }
    }

    /* Variants */
    &.size-large {
      grid-row: span 2;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      min-height: 120px;
    }

    &.size-long {
      grid-column: span 2;
      flex-direction: column;
      align-items: flex-start;
    }
    
    &.last-in-row {
      /* Animation handled by transition in base .Key class */
      /* Keep default left alignment, not centered */
      
      .Name {
        font-size: 15px;
      }
    }
    
    &.skeleton {
      background: linear-gradient(90deg, 
        ${props => props.themeColors.background} 25%, 
        ${props => props.themeColors.backgroundLight} 50%, 
        ${props => props.themeColors.background} 75%
      );
      background-size: 200% 100%;
      animation: shimmer 1.5s infinite;
      flex-direction: column;
      align-items: center;

      .Icon {
        width: 40px;
        height: 40px;
        background: ${props => props.themeColors.background};
        transition: background 0.3s ease;
      }

      .Name {
        width: 60%;
        height: 13px;
        background: ${props => props.themeColors.background};
        border-radius: 4px;
        transition: background 0.3s ease;
      }
    }
  }

  /* QuickAccessBar styles */
  .QuickAccessBar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 16px 20px;
    padding-bottom: calc(16px + env(safe-area-inset-bottom, 16px));
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    background: ${props => props.themeColors.quickAccessBackground};
    backdrop-filter: blur(8px) saturate(150%);
    border-top: 1px solid ${props => props.themeColors.quickAccessBorder};
    /* Transform is now controlled by inline style for better control */
    transition: transform 0.3s cubic-bezier(0.2, 0.9, 0.3, 1), /* Smoother animation with different timing function */
                background 0.3s ease,
                border-color 0.3s ease,
                box-shadow 0.3s ease;
    will-change: transform;
    -webkit-overflow-scrolling: touch; /* Improves mobile scrolling */
    box-shadow: 0 -4px 16px ${props => props.themeColors.quickAccessShadow};
    touch-action: pan-x; /* Allow horizontal panning but handle vertical ourselves */

    /* Gradient border */
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: linear-gradient(90deg,
        ${props => `rgba(${props.isDarkMode ? '50, 50, 50' : '255, 255, 255'}, 0)`},
        ${props => `rgba(${props.isDarkMode ? '50, 50, 50' : '255, 255, 255'}, 0.8)`},
        ${props => `rgba(${props.isDarkMode ? '50, 50, 50' : '255, 255, 255'}, 0)`}
      );
      transition: background 0.3s ease;
    }
    
    /* Indicator to show it's draggable */
    &::after {
      content: '';
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translateX(-50%);
      width: 36px;
      height: 4px;
      background: ${props => props.themeColors.quickAccessShadow};
      border-radius: 4px;
      transition: background 0.3s ease;
    }

    &.closed {
      /* Transform is now controlled by inline style */
      .Key {
        transform: scale(0.95);
        opacity: 0.9;
        transition: transform 0.3s ease, opacity 0.3s ease;
      }
    }

    &.peeking {
      .Key {
        transform: scale(1);
        opacity: 1;
        transition: transform 0.3s ease, opacity 0.3s ease;
      }
    }
    
    .quick {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
      gap: 12px;
      padding: 4px;
      
      .Key {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        .Name {
          font-size: 12px;
          text-align: center;
        }
        
        .Icon {
          margin-bottom: 4px;
        }
      }
    }
    
    @media (min-width: 768px) {
      display: none;
    }
  }

  /* Switch styles */
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        background-color: ${props => props.themeColors.primary};

        &:before {
          transform: translateX(20px);
        }
      }
    }
  }
  
  .slider {
    position: absolute;
    inset: 0;
    background-color: ${props => props.themeColors.sliderBackground};
    transition: 0.3s;
    cursor: pointer;
    border-radius: 34px;

    &:before {
      content: "";
      position: absolute;
      height: 14px;
      width: 14px;
      left: 3px;
      bottom: 3px;
      background-color: ${props => props.themeColors.sliderKnob};
      transition: 0.3s;
      border-radius: 50%;
    }
  }

  .shellyApiToggle {
    position: absolute;
    top: 32px;
    right: 20px;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .shellySwitch {
    font-weight: 500;
    color: ${props => props.themeColors.secondary};
    font-size: 14px;
    transition: color 0.3s ease;
  }
`

// ===================================================================
// Utility Functions
// ===================================================================

/**
 * Debounce function to limit how often a function can be called
 */
const debounce = (func, wait) => {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func(...args), wait)
  }
}

/**
 * Throttle function to ensure function is called at most once in the specified time period
 */
const throttle = (func, limit) => {
  let inThrottle
  return function(...args) {
    if (!inThrottle) {
      func.apply(this, args)
      inThrottle = true
      setTimeout(() => inThrottle = false, limit)
    }
  }
}

/**
 * Check if vibration API is supported
 */
const isVibrationSupported = () => 
  typeof navigator !== 'undefined' && 
  typeof navigator.vibrate === 'function'

/**
 * Haptic feedback utility with various patterns
 */
const hapticFeedback = {
  light: throttle(() => {
    if (isVibrationSupported()) {
      navigator.vibrate(10)
    }
  }, 100),
  
  medium: throttle(() => {
    if (isVibrationSupported()) {
      navigator.vibrate(20)
    }
  }, 100),
  
  heavy: throttle(() => {
    if (isVibrationSupported()) {
      navigator.vibrate([30, 10, 30])
    }
  }, 100),
  
  success: throttle(() => {
    if (isVibrationSupported()) {
      navigator.vibrate([10, 30, 10])
    }
  }, 100),
  
  error: throttle(() => {
    if (isVibrationSupported()) {
      navigator.vibrate([50, 20, 50, 20, 50])
    }
  }, 100)
}

/**
 * Get the appropriate icon for a key based on its type and state
 */
const getKeyIcon = (key, state) => {
  // Special case for doors - show open door icon when state is true
  if (state === true && key.icon === 'door') {
    return 'door_open'
  }
  
  // For other types, look up in the icon map or use default
  return iconMap.get(key.icon) || 'key'
}

/**
 * Get current network connection type (wifi, cellular, etc)
 */
const getNetworkType = () => {
  if (navigator.connection) {
    return navigator.connection.type || 'unknown'
  }
  return 'unknown'
}

/**
 * Determine if current connection is likely low-bandwidth
 */
const checkBandwidth = () => {
  if (navigator.connection) {
    // Check downlink or effectiveType if available
    if (navigator.connection.downlink !== undefined) {
      return navigator.connection.downlink < 1
    } else if (navigator.connection.effectiveType) {
      return ['slow-2g', '2g', '3g'].includes(
        navigator.connection.effectiveType
      )
    }
  }
  return false
}

/**
 * Safely load data from localStorage with a fallback
 */
const loadFromStorage = (key, defaultValue) => {
  try {
    const stored = localStorage.getItem(key)
    return stored !== null ? JSON.parse(stored) : defaultValue
  } catch (error) {
    console.error(`Error loading ${key} from localStorage:`, error)
    return defaultValue
  }
}

/**
 * Safely save data to localStorage
 */
const saveToStorage = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value))
    return true
  } catch (error) {
    console.error(`Error saving ${key} to localStorage:`, error)
    return false
  }
}

// ===================================================================
// Subcomponents
// ===================================================================

/**
 * Cloud/Local API Toggle Component
 */
const ShellyApiToggle = React.memo(() => {
  // Initialize with stored value or default to true if not set
  const [isChecked, setIsChecked] = useState(() => {
    // Use existing API setting or localStorage, otherwise default to true
    return API.shared.useShellyApi ?? (localStorage.getItem('useShellyApi') !== 'false')
  })
  
  // Set the API value to match component state
  useEffect(() => {
    API.shared.useShellyApi = isChecked
    localStorage.setItem('useShellyApi', isChecked)
  }, [isChecked])

  const handleToggle = useCallback(() => {
    setIsChecked(prev => !prev)
  }, [])

  return (
    <div className="shellyApiToggle">
      <span className="shellySwitch">Cloud</span>
      <label className="switch">
        <input
          type="checkbox"
          checked={!isChecked}
          onChange={handleToggle}
        />
        <span className="slider round" />
      </label>
    </div>
  )
})

/**
 * Key Group Component
 */
const KCGroup = React.memo(({ group, keychain }) => {
  const keysRef = useRef(null);
  const [singleLastKey, setSingleLastKey] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(() => {
    // Check localStorage for collapsed state of this group
    const collapsedGroups = loadFromStorage('collapsedGroups', {});
    return collapsedGroups[group.name] || false;
  });
  
  // Toggle collapsed state
  const toggleCollapse = useCallback(() => {
    setIsCollapsed(prev => {
      const newState = !prev;
      
      // Save collapsed state to localStorage
      const collapsedGroups = loadFromStorage('collapsedGroups', {});
      collapsedGroups[group.name] = newState;
      saveToStorage('collapsedGroups', collapsedGroups);
      
      // Provide haptic feedback
      hapticFeedback.light();
      
      // Force a scroll event to recalculate quick access bar visibility
      // This ensures that when opening/closing groups, the quick access bar behavior updates
      setTimeout(() => {
        window.dispatchEvent(new Event('scroll'));
      }, 50);
      
      return newState;
    });
  }, [group.name]);
  
  // Calculate if last key should span 2 columns
  useEffect(() => {
    if (!keysRef.current || !group.keys.length || isCollapsed) return;
    
    const calculateLastKeyPosition = () => {
      const container = keysRef.current;
      if (!container || !document.body.contains(container)) return;
      
      const keys = Array.from(container.querySelectorAll('.Key'));
      if (!keys.length) return;
      
      // Reset any previously set styles
      keys.forEach(key => {
        if (key.classList.contains('last-in-row')) {
          key.classList.remove('last-in-row');
          key.style.gridColumn = '';
        }
      });
      
      // Get the last key element
      const lastKey = keys[keys.length - 1];
      
      // Check if the last key is alone in its row
      const lastKeyRect = lastKey.getBoundingClientRect();
      let isAlone = true;
      
      // Check if any other key is on the same row as the last key
      for (let i = 0; i < keys.length - 1; i++) {
        const keyRect = keys[i].getBoundingClientRect();
        if (Math.abs(keyRect.top - lastKeyRect.top) < 5) { // 5px tolerance for rounding errors
          isAlone = false;
          break;
        }
      }
      
      // If the last key is alone, make it span 2 columns
      if (isAlone) {
        lastKey.classList.add('last-in-row');
        lastKey.style.gridColumn = 'span 2';
        setSingleLastKey(lastKey.id);
      } else {
        setSingleLastKey(null);
      }
    };
    
    // Delay initial calculation to ensure layout is stable
    const initialCalcDelay = setTimeout(() => {
      calculateLastKeyPosition();
    }, 100);
    
    // Set up a mutation observer to detect DOM changes
    const observer = new MutationObserver(debounce(calculateLastKeyPosition, 200));
    
    // Start observing the container for DOM changes
    observer.observe(keysRef.current, { 
      childList: true, 
      subtree: true, 
      attributes: true,
      attributeFilter: ['class', 'style'] 
    });
    
    // Handlers for various events that might affect layout
    const handleResize = debounce(calculateLastKeyPosition, 200);
    const handleOrientationChange = debounce(calculateLastKeyPosition, 300);
    const handleLoad = debounce(calculateLastKeyPosition, 200);
    
    // Listen for events
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleOrientationChange);
    window.addEventListener('load', handleLoad);
    
    return () => {
      clearTimeout(initialCalcDelay);
      observer.disconnect();
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleOrientationChange);
      window.removeEventListener('load', handleLoad);
    };
  }, [group.keys, isCollapsed]);
  
  // No longer needed for our simplified approach
  
  // We don't need this effect anymore since we're using conditional rendering
  // instead of maxHeight animations

  // Use a completely different approach that just conditionally renders the keys
  // without animations when the group is collapsed
  return (
    <div className={`Group ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="GroupHeader" onClick={toggleCollapse}>
        <div className="Name">{group.name}</div>
        <span className="material-symbols-outlined CollapseIcon" style={{
          transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
          transition: 'transform 0.3s ease'
        }}>
          expand_more
        </span>
      </div>
      
      {/* Simpler approach: when collapsed, don't render keys at all */}
      {!isCollapsed && (
        <div className="Keys" ref={keysRef}>
          {group.keys.map((key) => (
            <KCKey 
              key={key.id} 
              theKey={key} 
              keychain={keychain} 
            />
          ))}
        </div>
      )}
    </div>
  );
})

/**
 * Quick Access Component - renders frequently used keys
 */
const QuickAccess = React.memo(({ keys, keychain }) => {
  // Find valid keys by matching resourceIds with actual keys in the keychain
  const validKeys = useMemo(() => {
    if (!keys?.length || !keychain?.data?.keyGroups) return []

    // Create a flat array of all keys from all groups
    const allKeys = keychain.data.keyGroups.flatMap(g => g.keys)
    
    // Map quick access keys to their full details
    return keys
      .map(key => {
        const fullKey = allKeys.find(k => k.id === key.resourceId)
        return fullKey ? { resourceId: key.resourceId, fullKey } : null
      })
      .filter(Boolean)
  }, [keys, keychain?.data?.keyGroups])

  // Return empty component if no valid keys
  if (!validKeys.length) {
    return null
  }

  // Render quick access keys
  return (
    <div className="Keys quick">
      {validKeys.map(({ resourceId, fullKey }) => (
        <KCKey
          key={resourceId}
          theKey={fullKey}
          keychain={keychain}
          quick={true}
        />
      ))}
    </div>
  )
})

/**
 * Key Component - renders a single key button
 */
const KCKey = React.memo(({ theKey, keychain, quick }) => {
  // Handles key activation with immediate visual feedback
  const useKey = useCallback(() => {
    // Only allow key activation if not already active
    if (!theKey.state && !theKey.busy) {
      // Trigger haptic feedback immediately
      hapticFeedback.medium()
      
      // Use the keychain service
      keychain.useKey(theKey)
    }
  }, [theKey, keychain])

  // Compute UI properties based on theKey props
  const icon = useMemo(() => 
    getKeyIcon(theKey, theKey.state), 
    [theKey, theKey.state]
  )
  
  const classNames = useMemo(() => {
    const classes = ['Key']
    if (theKey.state && theKey.duration === 0) classes.push('isOpen')
    if (theKey.state && theKey.duration > 0) classes.push('busy')
    if (quick) classes.push('quick-access')
    if (theKey.size && !quick) classes.push(`size-${theKey.size}`)
    return classes.join(' ')
  }, [theKey.state, theKey.duration, quick, theKey.size])
  
  // Special handling for display names
  const displayName = useMemo(() => {
    if (quick && theKey.id === 'ov6b-draugiem') return 'R • PP • D'
    return theKey.name
  }, [quick, theKey.name, theKey.id])
  
  // Format the status text (remaining time) - hide in quick access
  const statusText = useMemo(() => {
    if (!quick && theKey.hasTimer && theKey.duration > 0) {
      const seconds = Math.round(theKey.duration)
      return seconds === 1 ? `${seconds} second remaining` : `${seconds} seconds remaining`
    }
    return ''
  }, [theKey.hasTimer, theKey.duration, quick])

  return (
    <div className={classNames} onClick={useKey}>
      <div>
        <span className="material-symbols-outlined Icon">{icon}</span>
      </div>
      <div>
        <div className="Name">
          {displayName.split(' | ').map((part, index, array) => (
            <span key={index}>
              {part}
              {index < array.length - 1 && <span> &nbsp;</span>}
            </span>
          ))}
        </div>
        <div className="Status">{statusText}</div>
      </div>
    </div>
  )
}, (prevProps, nextProps) => {
  // Return false (re-render) if the key identity changed
  if (prevProps.theKey.id !== nextProps.theKey.id || prevProps.quick !== nextProps.quick) {
    return false
  }
  
  // Only re-render if visually relevant properties changed
  return (
    prevProps.theKey.state === nextProps.theKey.state &&
    prevProps.theKey.busy === nextProps.theKey.busy &&
    (!prevProps.theKey.hasTimer || !nextProps.theKey.hasTimer || 
     Math.floor(prevProps.theKey.duration) === Math.floor(nextProps.theKey.duration)) &&
    prevProps.theKey.size === nextProps.theKey.size
  )
})

/**
 * Custom hook to manage keychain state and WebSocket communication
 */
const useKeychain = () => {
  // State management
  const [data, setData] = useState(null)
  const [quickAccess, setQuickAccess] = useState(() => loadFromStorage('quickAccess', []))
  const [quickAccessEnabled, setQuickAccessEnabled] = useState(() => loadFromStorage('quickAccessEnabled', true))
  const [isOffline, setIsOffline] = useState(!navigator.onLine)
  const [lastSuccessfulUpdate, setLastSuccessfulUpdate] = useState(() => 
    parseInt(localStorage.getItem('lastKeychainUpdate')) || 0
  )

  // Refs
  const wsRef = useRef(null)
  const lastUpdateTime = useRef(Date.now())
  const reconnectAttempts = useRef(0)
  const connectWebSocketRef = useRef(null)
  const networkType = useRef(getNetworkType())
  const isLowBandwidth = useRef(checkBandwidth())

  /**
   * Handle online/offline status changes
   */
  useEffect(() => {
    const handleOnline = () => {
      setIsOffline(false)
      if (connectWebSocketRef.current) {
        reconnectAttempts.current = 0
        connectWebSocketRef.current()
      }
      loadData()
    }

    const handleOffline = () => {
      setIsOffline(true)
    }

    // Monitor connection type changes
    const handleConnectionChange = () => {
      const newType = getNetworkType()
      if (newType !== networkType.current) {
        networkType.current = newType
        isLowBandwidth.current = checkBandwidth()
        
        // Reconnect WebSocket with new connection parameters
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
          wsRef.current.close(1000, 'Connection type changed')
          setTimeout(() => {
            if (connectWebSocketRef.current) {
              reconnectAttempts.current = 0
              connectWebSocketRef.current()
            }
          }, 500)
        }
      }
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)
    
    // Add connection change listener if supported
    if (navigator.connection) {
      navigator.connection.addEventListener('change', handleConnectionChange)
      // Initial bandwidth check
      isLowBandwidth.current = checkBandwidth()
    }

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
      if (navigator.connection) {
        navigator.connection.removeEventListener('change', handleConnectionChange)
      }
    }
  }, [])

  /**
   * Load cached data from localStorage
   */
  const loadCachedData = useCallback(() => {
    return loadFromStorage('keychainData', null)
  }, [])

  /**
   * Save current data to cache for offline use
   */
  const updateCache = useCallback((newData) => {
    try {
      saveToStorage('keychainData', newData)
      localStorage.setItem('lastKeychainUpdate', Date.now().toString())
      setLastSuccessfulUpdate(Date.now())
    } catch (error) {
      console.error('Error caching keychain data', error)
    }
  }, [])

  /**
   * Fetches keychain data from the API and intelligently merges with current state
   */
  const loadData = useCallback(async () => {
    // Use cached data when offline
    if (isOffline) {
      const cachedData = loadCachedData()
      if (cachedData && !data) {
        console.log('Using cached data in offline mode')
        setData(cachedData)
      }
      return Promise.resolve()
    }
    
    try {
      // Always fetch data on first load or if data is null
      if (!data) {
        // Try to show cached data instantly while fetching fresh data
        const cachedData = loadCachedData()
        if (cachedData) {
          setData(cachedData)
        }
        
        const response = await API.shared.get('keychain')
        setData(response)
        updateCache(response)
        lastUpdateTime.current = Date.now()
        return Promise.resolve()
      }
      
      // Throttle API calls based on network conditions
      const now = Date.now()
      const timeSinceLastUpdate = now - lastUpdateTime.current
      const minUpdateInterval = isLowBandwidth.current ? 30000 : 10000 // 30s for low bandwidth, 10s for good connections
      
      if (timeSinceLastUpdate > minUpdateInterval) {
        // Fetch fresh data but preserve active key states
        const response = await API.shared.get('keychain')
        
        setData(prevData => {
          if (!prevData) return response
          
          // Build a map of currently active keys
          const activeKeysMap = {}
          prevData.keyGroups.forEach(group => {
            group.keys.forEach(key => {
              if (key.state === true || key.busy === true) {
                activeKeysMap[key.id] = key
              }
            })
          })
          
          // Create merged data preserving active states
          const mergedKeyGroups = response.keyGroups.map(group => ({
            ...group,
            keys: group.keys.map(key => {
              // Only preserve recent activations (< 3s) that haven't been acknowledged by server
              if (activeKeysMap[key.id] && 
                (key.state === false || !key.hasTimer) &&
                Date.now() - activeKeysMap[key.id].lastUpdated < 3000) {
                return {
                  ...key,
                  state: activeKeysMap[key.id].state,
                  busy: activeKeysMap[key.id].busy,
                  hasTimer: activeKeysMap[key.id].hasTimer,
                  duration: activeKeysMap[key.id].duration,
                  lastUpdated: activeKeysMap[key.id].lastUpdated
                }
              }
              return key
            })
          }))
          
          const result = { ...response, keyGroups: mergedKeyGroups }
          updateCache(result)
          return result
        })
        
        lastUpdateTime.current = now
      }
    } catch (error) {
      console.error('Error loading keychain data:', error)
      
      // Fall back to cached data on error
      if (!data) {
        const cachedData = loadCachedData()
        if (cachedData) {
          console.log('Using cached data after fetch error')
          setData(cachedData)
        } else {
          setData({ keyGroups: [] })
        }
      }
    }
    
    return Promise.resolve()
  }, [data, isOffline, loadCachedData, updateCache])

  /**
   * Fetches and updates recently used keys for quick access
   */
  const loadRecentlyUsed = useCallback(async () => {
    // Skip if offline
    if (isOffline) return
    
    try {
      const response = await API.shared.get('keychain/recently-used')
      const existingQuickAccess = loadFromStorage('quickAccess', [])
      const existingKeysMap = new Map(existingQuickAccess.map(key => [key.resourceId, key]))

      // Filter to keep only keys that still exist in the response
      const updatedQuickAccess = existingQuickAccess.filter(key =>
        response.keys.some(newKey => newKey.resourceId === key.resourceId)
      )

      // Add any new keys not already in quick access
      response.keys.forEach(newKey => {
        if (!existingKeysMap.has(newKey.resourceId)) {
          updatedQuickAccess.push(newKey)
        }
      })

      // Store and update state
      saveToStorage('quickAccess', updatedQuickAccess)
      setQuickAccess(updatedQuickAccess)
    } catch (error) {
      console.error('Error loading recently used keys:', error)
    }
  }, [isOffline])

  /**
   * Activates a key and handles local state + API communication
   */
  const useKey = useCallback(async (key, callback) => {
    // Track operation for timeout handling
    const operationTimestamp = Date.now()
    let networkTimeoutId = null
    let hasResponded = false
    
    // Update local state immediately
    const updateLocalState = () => {
      const activationTime = Date.now()
      
      setData(prevData => {
        if (!prevData) return prevData
        
        // Update specific key state
        const updatedKeyGroups = prevData.keyGroups.map(group => ({
          ...group,
          keys: group.keys.map(k => 
            k.id === key.id 
              ? {
                ...k,
                state: true,
                busy: true,
                hasTimer: true,
                duration: 3, // Default 3s duration for visual feedback
                momentary: true,
                lastUpdated: activationTime,
                activatedAt: activationTime // Add precise activation timestamp
              }
              : k
          )
        }))
        
        return { ...prevData, keyGroups: updatedKeyGroups }
      })
    }
    
    // Track operation in local history
    const addToLocalKeyHistory = () => {
      try {
        const keyHistory = loadFromStorage('keyHistory', [])
        keyHistory.unshift({
          keyId: key.id,
          timestamp: Date.now()
        })
        // Keep only last 20 entries
        saveToStorage('keyHistory', keyHistory.slice(0, 20))
      } catch (e) {
        // Silently ignore storage errors
      }
    }
    
    // Handle success response
    const handleSuccess = (success = true) => {
      if (hasResponded) return
      hasResponded = true
      
      if (networkTimeoutId) {
        clearTimeout(networkTimeoutId)
        networkTimeoutId = null
      }
      
      if (success) {
        hapticFeedback.success()
      }
      callback?.(success)
    }
    
    // Handle error or timeout
    const handleError = (error) => {
      if (hasResponded) return
      hasResponded = true
      
      if (networkTimeoutId) {
        clearTimeout(networkTimeoutId)
        networkTimeoutId = null
      }
      
      console.error('Error using key:', error)
      hapticFeedback.error()
      
      // Update state to show failure
      setData(prevData => {
        if (!prevData) return prevData
        
        const updatedKeyGroups = prevData.keyGroups.map(group => ({
          ...group,
          keys: group.keys.map(k => 
            k.id === key.id 
              ? {
                ...k,
                state: false,
                busy: false,
                hasTimer: false,
                duration: 0,
                activatedAt: null,
                lastUpdated: Date.now()
              }
              : k
          )
        }))
        
        return { ...prevData, keyGroups: updatedKeyGroups }
      })
      
      callback?.(false)
    }
    
    // Set timeout for mobile network operations
    const NETWORK_TIMEOUT = isLowBandwidth.current ? NETWORK_TIMEOUT_LOW_BANDWIDTH : NETWORK_TIMEOUT_DEFAULT
    networkTimeoutId = setTimeout(() => {
      if (!hasResponded) {
        handleError(new Error('Network request timeout'))
      }
    }, NETWORK_TIMEOUT)
    
    // Update local state immediately
    updateLocalState()
    
    // Return early if offline, but still give feedback
    if (isOffline) {
      hapticFeedback.medium() // Some feedback in offline mode
      addToLocalKeyHistory() // Track for later sync
      handleError(new Error('Device is offline'))
      return
    }
    
    try {
      // Record in history (used for quick access updates)
      addToLocalKeyHistory()
      
      // Give immediate haptic feedback
      hapticFeedback.medium()
      
      // Send WebSocket message to inform other clients
      if (wsRef.current?.readyState === WebSocket.OPEN) {
        wsRef.current.send(JSON.stringify({
          type: 'key-use',
          resourceId: key.id,
          initiatedByClient: true,
          time: new Date().toISOString()
        }))
      }
      
      // Make the API request to activate the key
      const response = await API.shared.post('keychain/use-key', {
        id: key.id,
        toggleApi: API.shared.useShellyApi
      })
      
      // Handle response
      if (response?.success) {
        handleSuccess(true)
      } else {
        handleError(new Error('Server returned unsuccessful response'))
      }
    } catch (error) {
      handleError(error)
    }
  }, [isOffline])

  // Initialize keychain data and start timer for countdown
  useEffect(() => {
    loadData()
    loadRecentlyUsed()
    
    // Set up timer to update durations and clean up stuck states
    const timerInterval = setInterval(() => {
      setData(prevData => {
        if (!prevData) return prevData
        
        let anyChanges = false
        
        // Update durations and clean up expired/stuck states
        const updatedKeyGroups = prevData.keyGroups.map(group => ({
          ...group,
          keys: group.keys.map(key => {
            // Skip keys that don't have active timers
            if (!key.hasTimer || !key.state) return key
            
            // Calculate remaining time based on activation time if available
            let newDuration = key.duration
            if (key.activatedAt) {
              const elapsedSeconds = Math.floor((Date.now() - key.activatedAt) / 1000)
              newDuration = Math.max(0, 3 - elapsedSeconds) // Assuming 3s initial duration
            } else {
              // Fallback to decrementing by 1 second if no activation timestamp
              newDuration = Math.max(0, key.duration - 1)
            }
            
            // Check if timer expired or key is stuck
            const hasExpiredTimer = newDuration <= 0
            const isStuckTooLong = key.state === true && key.busy === true && 
              key.lastUpdated && (Date.now() - key.lastUpdated > 30000)
            
            // Update key state based on conditions
            if (hasExpiredTimer || isStuckTooLong) {
              anyChanges = true
              return {
                ...key,
                state: false,
                busy: false,
                hasTimer: false,
                duration: 0,
                lastUpdated: Date.now()
              }
            } else if (key.duration !== newDuration) {
              // Update duration if it changed
              anyChanges = true
              return { ...key, duration: newDuration }
            }
            
            return key
          })
        }))
        
        // Only update state if changes were made
        return anyChanges ? { ...prevData, keyGroups: updatedKeyGroups } : prevData
      })
    }, 1000) // Update every second to countdown the timer
    
    return () => clearInterval(timerInterval)
  }, [loadData, loadRecentlyUsed])

  // WebSocket connection management (optimized for mobile devices)
  useEffect(() => {
    // Battery state tracking
    let isBatteryLow = false
    let isCharging = true
    
    // Calculate battery-aware backoff delay
    const getBackoffDelay = () => {
      const baseDelay = 3000 // Start with 3s
      const factor = 1.5    // Increase by 50% each attempt
      
      // Increase delay when on battery and low power
      let multiplier = 1.0
      if (isBatteryLow && !isCharging) {
        multiplier = 3.0 // Much longer delays on low battery
      } else if (!isCharging) {
        multiplier = 1.5 // Slightly longer delays when on battery
      }
      
      // Adjust for network conditions
      if (isLowBandwidth.current) {
        multiplier *= 1.5 // Even longer on poor connections
      }
      
      return Math.min(
        baseDelay * Math.pow(factor, reconnectAttempts.current) * multiplier + (Math.random() * 1000),
        MAX_RECONNECT_DELAY
      )
    }
    
    // Get ping interval based on connection and battery state
    const getPingInterval = () => {
      const basePingInterval = 30000 // 30s default
      
      // On low battery and not charging, use much less frequent pings
      if (isBatteryLow && !isCharging) {
        return 120000 // 2 minutes on low battery
      }
      
      // On battery but not low, use less frequent pings
      if (!isCharging) {
        return 60000 // 1 minute when on battery
      }
      
      // Charging or unknown battery state - use default
      return basePingInterval
    }
    
    // Setup battery monitoring if available
    const setupBatteryMonitoring = async () => {
      try {
        if ('getBattery' in navigator) {
          const battery = await navigator.getBattery()
          
          // Initial state
          isCharging = battery.charging
          isBatteryLow = battery.level < BATTERY_LOW_THRESHOLD
          
          // Event handlers
          const handleBatteryChange = () => {
            const prevCharging = isCharging
            const prevLow = isBatteryLow
            
            isCharging = battery.charging
            isBatteryLow = battery.level < BATTERY_LOW_THRESHOLD
            
            // Close websocket and reconnect with new parameters if state changed significantly
            if (prevCharging !== isCharging || prevLow !== isBatteryLow) {
              if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.close(1000, 'Battery status changed')
                // Reconnect with new battery-aware parameters
                setTimeout(() => {
                  if (document.visibilityState === 'visible' && connectWebSocketRef.current) {
                    reconnectAttempts.current = 0
                    connectWebSocketRef.current()
                  }
                }, 500)
              }
            }
          }
          
          // Listen for battery events
          battery.addEventListener('chargingchange', handleBatteryChange)
          battery.addEventListener('levelchange', handleBatteryChange)
          
          // Return cleanup function
          return () => {
            battery.removeEventListener('chargingchange', handleBatteryChange)
            battery.removeEventListener('levelchange', handleBatteryChange)
          }
        }
      } catch (error) {
        console.error('Battery monitoring not available:', error)
      }
      
      // Return empty cleanup function if battery monitoring failed
      return () => {}
    }
    
    // WebSocket connection handler
    const connectWebSocket = () => {
      // Only connect when page is visible and online
      if (document.visibilityState !== 'visible' || isOffline) return
      
      // Don't reconnect if already connected or connecting
      if (wsRef.current && 
        [WebSocket.OPEN, WebSocket.CONNECTING].includes(wsRef.current.readyState)) {
        return
      }
      
      // Implement battery saving mode for low power devices
      if (isBatteryLow && !isCharging && document.visibilityState !== 'visible') {
        // Skip WebSocket on low battery for background tabs
        return
      }
      
      // Determine WebSocket URL (support dev environment)
      const useLocalWs = new URLSearchParams(window.location.search).get('localws') === 'true'
      const wsUrl = useLocalWs 
        ? `${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//localhost:8040`
        : 'wss://app.hex.lv/wss'
      
      try {
        const ws = new WebSocket(wsUrl)
        wsRef.current = ws

        // Connection established
        ws.onopen = () => {
          reconnectAttempts.current = 0
          ws.send(JSON.stringify({ type: 'subscribe' }))
          
          // Load data on first connection if needed
          if (!data) loadData()
        }

        // Heartbeat to keep connection alive
        let pingInterval
        const setupPingInterval = () => {
          if (pingInterval) clearInterval(pingInterval)
          
          // Dynamic ping interval based on battery, network, and app state
          const interval = getPingInterval()
          pingInterval = setInterval(() => {
            if (ws.readyState === WebSocket.OPEN) {
              // Only send pings when visible or recently active (last 5 minutes)
              const isRecentlyActive = (Date.now() - lastUpdateTime.current) < 300000
              if (document.visibilityState === 'visible' || isRecentlyActive) {
                ws.send(JSON.stringify({ type: 'ping' }))
              }
            }
          }, interval)
        }
        
        // Initial setup of ping interval
        setupPingInterval()
        
        // Message handler with optimized state updates
        ws.onmessage = (event) => {
          try {
            const message = JSON.parse(event.data)
            
            if (message.type === 'key-update') {
              // Process key state updates
              setData(prevData => {
                if (!prevData) return prevData
                
                // First check if we need to update at all - optimization for battery life
                let needsUpdate = false
                let currentKeyState = null
                
                // Find if we have this key and if its state is different
                keyLoop: for (const group of prevData.keyGroups) {
                  for (const key of group.keys) {
                    if (key.id === message.resourceId) {
                      currentKeyState = key
                      
                      // Check if any visible properties will change
                      needsUpdate = key.state !== message.state ||
                        key.busy !== (message.busy || message.state) ||
                        (key.hasTimer && message.hasTimer && 
                         Math.floor(key.duration || 0) !== Math.floor(message.duration || 0))
                      
                      break keyLoop
                    }
                  }
                }
                
                // Skip update entirely if nothing important changed (battery optimization)
                if (!needsUpdate) return prevData
                
                // Prevent deactivation of recently activated keys (< 2s)
                const isTryingToTurnOff = message.state === false && currentKeyState?.state === true
                const wasActivatedRecently = currentKeyState?.state === true && 
                  currentKeyState?.lastUpdated && 
                  (Date.now() - currentKeyState.lastUpdated < 2000)
                
                if (isTryingToTurnOff && wasActivatedRecently) {
                  return prevData // Skip update for recently activated keys
                }
                
                // Apply update to the specific key only
                const updatedKeyGroups = prevData.keyGroups.map(group => ({
                  ...group,
                  keys: group.keys.map(key => 
                    key.id === message.resourceId 
                      ? {
                        ...key,
                        state: message.state,
                        busy: message.busy || message.state,
                        hasTimer: message.hasTimer,
                        duration: message.duration || 0,
                        momentary: message.momentary,
                        lastUpdated: Date.now(),
                        // Keep or set activation timestamp for accurate timer counting
                        activatedAt: message.state && !key.activatedAt ? Date.now() : 
                                    (message.state ? key.activatedAt : null)
                      }
                      : key
                  )
                }))
                
                // Cache the updated data for offline use
                const updatedData = { ...prevData, keyGroups: updatedKeyGroups }
                if (message.state === false) {
                  // Only update cache for completed operations (key turned off)
                  // This ensures we don't cache intermediate/temporary states
                  updateCache(updatedData)
                }
                
                return updatedData
              })
              
              // Vibrate for client that initiated the action
              if (message.initiatedByClient) {
                hapticFeedback.success()
              }
            } else if (message.type === 'pong') {
              // Silent handling
            } else if (message.type === 'battery-query') {
              // Respond with battery status if requested by server
              if (ws.readyState === WebSocket.OPEN && 'getBattery' in navigator) {
                navigator.getBattery().then(battery => {
                  ws.send(JSON.stringify({
                    type: 'battery-status',
                    level: battery.level,
                    charging: battery.charging
                  }))
                })
              }
            }
          } catch (err) {
            console.error('Error processing message:', err)
          }
        }

        // Connection closed
        ws.onclose = (event) => {
          if (pingInterval) clearInterval(pingInterval)
          
          // Don't reconnect aggressively if:
          // 1. The app is in background AND
          // 2. Device is on battery AND
          // 3. No active key operations (all keys inactive)
          const hasActiveKeys = data?.keyGroups?.some(group => 
            group.keys.some(key => key.state === true || key.busy === true)
          )
          
          const shouldDelayReconnection = 
            document.visibilityState !== 'visible' && 
            !isCharging && 
            !hasActiveKeys
          
          // Only reconnect if not intentionally closed
          if (event.code !== 1000) {
            reconnectAttempts.current++
            
            // Calculate appropriate delay
            let delay = getBackoffDelay()
            
            // Use longer delay if in background with no active keys
            if (shouldDelayReconnection) {
              delay = Math.max(delay, 60000) // At least 1 minute delay
            }
            
            setTimeout(connectWebSocket, delay)
          }
        }

        // Connection error
        ws.onerror = () => {
          // Let onclose handle reconnection
        }
      } catch (error) {
        // Load data even if WebSocket fails
        if (!data) loadData()
        
        // Schedule reconnection attempt
        reconnectAttempts.current++
        setTimeout(connectWebSocket, getBackoffDelay())
      }
    }
    
    // Store reference to connectWebSocket for other functions
    connectWebSocketRef.current = connectWebSocket
    
    // Set up battery monitoring
    let batteryCleanup
    setupBatteryMonitoring().then(cleanup => {
      batteryCleanup = cleanup
    })

    // Start WebSocket connection
    connectWebSocket()
    
    // Handle page visibility changes
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // Refresh data when returning to app
        loadData()
        
        // Reconnect WebSocket if needed
        if (!wsRef.current || wsRef.current.readyState !== WebSocket.OPEN) {
          reconnectAttempts.current = 0
          connectWebSocket()
        }
      } else {
        // Page is hidden - if on battery and all keys inactive, consider closing connection
        const hasActiveKeys = data?.keyGroups?.some(group => 
          group.keys.some(key => key.state === true || key.busy === true)
        )
        
        if (!isCharging && !hasActiveKeys && wsRef.current?.readyState === WebSocket.OPEN) {
          // Only close if we've been inactive for a while
          const inactiveTime = Date.now() - lastUpdateTime.current
          if (inactiveTime > 60000) { // Been inactive for over a minute
            // Close connection to save battery when app is in background
            wsRef.current.close(1000, 'App in background, saving battery')
          }
        }
      }
    }
    
    document.addEventListener('visibilitychange', handleVisibilityChange)
    
    // Cleanup
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      
      // Clean up battery monitoring
      if (batteryCleanup) batteryCleanup()
      
      // Close WebSocket gracefully on unmount
      if (wsRef.current) {
        try {
          wsRef.current.close(1000, 'Component unmounting')
        } catch (err) {
          console.error('Error closing WebSocket:', err)
        }
      }
    }
  }, [loadData, data, isOffline, updateCache])

  /**
   * Force a full data refresh, ignoring throttling
   */
  const forceRefresh = useCallback(async () => {
    try {
      // Reset the last update time to force a fetch
      lastUpdateTime.current = 0
      
      // Close and reopen WebSocket connection
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.close(1000, 'Force refresh requested')
      }
      
      // Load fresh data 
      const response = await API.shared.get('keychain')
      setData(response)
      updateCache(response)
      
      // Reconnect WebSocket
      if (connectWebSocketRef.current) {
        reconnectAttempts.current = 0
        setTimeout(() => connectWebSocketRef.current(), 500)
      }
      
      // Refresh quick access list
      loadRecentlyUsed()
      
      return true
    } catch (error) {
      console.error('Error during force refresh:', error)
      return false
    }
  }, [loadRecentlyUsed, updateCache])

  // Return public interface
  return {
    data,
    quickAccess,
    quickAccessEnabled,
    useKey,
    loadData,
    forceRefresh,
    wsRef
  }
}

// ===================================================================
// Main View Component
// ===================================================================
const Keychain = () => {
  // Access theme context
  const { isDarkMode, toggleTheme } = React.useContext(ThemeContext);
  
  // Create theme colors object from ThemeContext properties
  const themeColors = React.useMemo(() => {
    const theme = isDarkMode ? darkTheme : lightTheme;
    return {
      primary: theme.text,
      secondary: theme.secondaryText,
      background: theme.keychainBackground || (isDarkMode ? '#000000' : '#F3F3F1'),
      backgroundLight: theme.keychainBackgroundLight || (isDarkMode ? '#121212' : '#FBFBF9'),
      accent: theme.accent || '#FF6C64',
      accentGradient: theme.accentGradient || '#FF8E66',
      border: theme.keychainBorder || (isDarkMode ? '#222222' : '#BDBDBA'),
      quickAccessBackground: theme.keychainQuickAccessBackground || (isDarkMode ? 'rgba(15, 15, 15, 0.85)' : 'rgba(255, 255, 255, 0.85)'),
      quickAccessBorder: theme.keychainQuickAccessBorder || (isDarkMode ? 'rgba(30, 30, 30, 0.8)' : 'rgba(255, 255, 255, 0.8)'),
      quickAccessShadow: theme.keychainQuickAccessShadow || (isDarkMode ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.1)'),
      sliderBackground: theme.keychainSliderBackground || (isDarkMode ? '#2A2A2A' : '#E5E5E2'),
      sliderKnob: theme.keychainSliderKnob || (isDarkMode ? '#C7C7C5' : '#FFFFFD'),
      overlay: theme.keychainOverlay || (isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)'),
    };
  }, [isDarkMode]);
  
  const keychain = useKeychain()
  const [quickAccessHidden, setQuickAccessHidden] = useState(false)
  // Change to useRef for direct updates without re-rendering
  const lastScrollYRef = useRef(0)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [showOfflineBanner, setShowOfflineBanner] = useState(!navigator.onLine)
  const [showUpdateBanner, setShowUpdateBanner] = useState(false)
  const [showDarkModeBanner, setShowDarkModeBanner] = useState(() => {
    return localStorage.getItem('darkModeBannerShown') !== 'true';
  })
  const scrollTimeout = useRef(null)

  /**
   * Handle manual refresh button click
   */
  const handleManualRefresh = useCallback(() => {
    if (isRefreshing) return // Prevent multiple refresh requests
    
    // If we're offline, give feedback that we can't refresh
    if (!navigator.onLine) {
      hapticFeedback.error() // Error feedback for offline refresh attempt
      setShowOfflineBanner(true) // Show offline banner
      setTimeout(() => setShowOfflineBanner(false), 3000) // Hide after 3s
      return
    }
    
    setIsRefreshing(true)
    hapticFeedback.light() // Light feedback when refresh starts
    
    keychain.loadData()
      .finally(() => {
        setTimeout(() => setIsRefreshing(false), 500)
      })
  }, [keychain, isRefreshing])


  /**
   * Completely hardcoded scroll handler with refs for direct access
   */
  const handleScroll = useCallback(() => {
    // Use window.scrollY for better cross-browser compatibility
    const scrollPos = window.scrollY
    const prevPos = lastScrollYRef.current
    
    // Explicitly determine direction
    const isScrollingDown = scrollPos > prevPos
    
    // DEBUG - trace what's happening
    console.log(`SCROLL: ${isScrollingDown ? 'DOWN' : 'UP'} - Current: ${scrollPos}, Last: ${prevPos}`)
    
    // CORE LOGIC - very simple
    if (isScrollingDown) {
      // HIDE when scrolling DOWN
      console.log("HIDING BAR")
      setQuickAccessHidden(true)
    } else {
      // SHOW when scrolling UP
      console.log("SHOWING BAR")
      setQuickAccessHidden(false)
    }
    
    // Always show at very top
    if (scrollPos < 20) {
      setQuickAccessHidden(false)
    }
    
    // Always update reference for next comparison
    lastScrollYRef.current = scrollPos
  }, [])

  // Track online/offline status and show appropriate UI
  useEffect(() => {
    const handleOnline = () => {
      setShowOfflineBanner(false)
      // When coming back online, check if data is stale
      const lastUpdate = parseInt(localStorage.getItem('lastKeychainUpdate')) || 0
      const now = Date.now()
      const dataAge = now - lastUpdate
      
      // If data is over 5 minutes old, show refresh banner
      if (dataAge > 300000) {
        setShowUpdateBanner(true)
        setTimeout(() => setShowUpdateBanner(false), 5000)
      } else {
        // Refresh automatically if data is slightly stale
        keychain.loadData()
      }
    }

    const handleOffline = () => {
      setShowOfflineBanner(true)
      // Hide after 3 seconds
      setTimeout(() => setShowOfflineBanner(false), 3000)
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [keychain])

  // Set up scroll event listener
  useEffect(() => {
    // Add scroll listener
    window.addEventListener('scroll', handleScroll, { passive: true })
    
    // Clean up
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  // Handle additional mobile browser events not covered by visibilitychange
  useEffect(() => {
    const handleAppResume = () => {
      if (document.visibilityState === 'visible') {
        // Check if data is stale when returning to app
        const lastUpdate = parseInt(localStorage.getItem('lastKeychainUpdate')) || 0
        const now = Date.now()
        const dataAge = now - lastUpdate
        
        // Over 5 minutes old, show update banner
        if (dataAge > 300000) {
          setShowUpdateBanner(true)
          setTimeout(() => setShowUpdateBanner(false), 5000)
        }
        
        keychain.loadData()
      }
    }
    
    // Enable pull-to-refresh on mobile
    let touchStartY = 0
    const handleTouchStart = (e) => {
      // Only detect pull-to-refresh at top of page
      if (window.scrollY <= 10) {
        touchStartY = e.touches[0].clientY
      }
    }
    
    const handleTouchMove = (e) => {
      // Only detect pull when at top of page
      if (window.scrollY > 0 || touchStartY === 0) return
      
      const touchY = e.touches[0].clientY
      const diff = touchY - touchStartY
      
      // If pulled down > 60px, trigger refresh
      if (diff > 60 && !isRefreshing) {
        handleManualRefresh()
        touchStartY = 0 // Reset to prevent multiple triggers
      }
    }

    window.addEventListener('focus', handleAppResume)
    window.addEventListener('pageshow', handleAppResume)
    window.addEventListener('touchstart', handleTouchStart)
    window.addEventListener('touchmove', handleTouchMove)

    return () => {
      window.removeEventListener('focus', handleAppResume)
      window.removeEventListener('pageshow', handleAppResume)
      window.removeEventListener('touchstart', handleTouchStart)
      window.removeEventListener('touchmove', handleTouchMove)
    }
  }, [keychain, handleManualRefresh])

  // Function to handle enabling dark mode
  const handleEnableDarkMode = useCallback(() => {
    toggleTheme(); // Toggle to dark mode
    setShowDarkModeBanner(false);
    localStorage.setItem('darkModeBannerShown', 'true');
  }, [toggleTheme]);

  // Function to dismiss the banner
  const handleDismissBanner = useCallback(() => {
    setShowDarkModeBanner(false);
    localStorage.setItem('darkModeBannerShown', 'true');
  }, []);

  // Show loading state if data isn't loaded yet
  if (!keychain.data) {
    return <Layout isLoading />
  }

  // Render keychain UI
  return (
    <KeychainLayout title="Keychain" themeColors={themeColors} isDarkMode={isDarkMode} hidden={quickAccessHidden}>
      {/* Offline banner */}
      {showOfflineBanner && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            background: 'rgba(0,0,0,0.7)',
            color: 'white',
            padding: '10px 16px',
            fontSize: '14px',
            textAlign: 'center',
            zIndex: 1000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px'
          }}
        >
          <span className="material-symbols-outlined" style={{ fontSize: '18px' }}>
            wifi_off
          </span>
          <span>You're offline. Some features may be limited.</span>
        </div>
      )}
      
      {/* Update banner */}
      {showUpdateBanner && (
        <div 
          style={{
            position: 'fixed',
            top: showOfflineBanner ? '40px' : 0,
            left: 0,
            right: 0,
            background: themeColors.accent,
            color: 'white',
            padding: '10px 16px',
            fontSize: '14px',
            textAlign: 'center',
            zIndex: 999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            cursor: 'pointer'
          }}
          onClick={handleManualRefresh}
        >
          <span className="material-symbols-outlined" style={{ fontSize: '18px' }}>
            update
          </span>
          <span>Tap to refresh with latest data</span>
        </div>
      )}
      
      {/* API toggle switch */}
      <ShellyApiToggle />
      
      {/* Display offline status message if offline */}
      {!navigator.onLine && (
        <div style={{
          margin: '10px 16px 20px',
          padding: '12px 16px',
          borderRadius: '12px',
          background: isDarkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)',
          color: themeColors.secondary,
          fontSize: '14px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px'
        }}>
          <span className="material-symbols-outlined" style={{ fontSize: '18px' }}>
            info
          </span>
          <div>
            <strong>You're offline</strong>
            <div style={{ marginTop: '4px', fontSize: '13px' }}>
              Using cached data from {new Date(parseInt(localStorage.getItem('lastKeychainUpdate'))).toLocaleString()}
            </div>
          </div>
        </div>
      )}
      
      {/* Dark Mode Banner */}
      {showDarkModeBanner && (
        <div 
          style={{
            marginBottom: '20px',
            padding: '16px',
            borderRadius: '16px',
            background: `linear-gradient(135deg, ${themeColors.accent}, ${themeColors.accentGradient})`,
            color: 'white',
            boxShadow: '0 4px 12px rgba(255, 108, 100, 0.25)',
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            animation: 'fadeIn 0.5s ease-out'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <div style={{ 
              background: 'rgba(255, 255, 255, 0.2)', 
              borderRadius: '50%', 
              width: '42px', 
              height: '42px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <span className="material-symbols-outlined" style={{ fontSize: '24px' }}>
                dark_mode
              </span>
            </div>
            <div>
              <div style={{ fontWeight: '600', fontSize: '16px', marginBottom: '4px' }}>
                Try Dark Mode
              </div>
              <div style={{ fontSize: '13px', opacity: '0.9' }}>
                Reduce eye strain and save battery
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '10px', marginTop: '4px' }}>
            <button 
              style={{ 
                flex: '1',
                background: 'rgba(255, 255, 255, 0.2)', 
                border: 'none',
                borderRadius: '10px',
                padding: '8px 12px',
                color: 'white',
                fontSize: '14px',
                fontWeight: '500',
                cursor: 'pointer',
                transition: 'background 0.2s ease'
              }}
              onClick={handleDismissBanner}
            >
              Maybe Later
            </button>
            <button
              style={{ 
                flex: '1',
                background: 'white', 
                border: 'none',
                borderRadius: '10px',
                padding: '8px 12px',
                color: themeColors.accent,
                fontSize: '14px',
                fontWeight: '600',
                cursor: 'pointer',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
              }}
              onClick={handleEnableDarkMode}
            >
              Enable Dark Mode
            </button>
          </div>
        </div>
      )}
      
      {/* Key groups */}
      {keychain.data.keyGroups.map(group => (
        <KCGroup key={group.name} group={group} keychain={keychain} />
      ))}
      
      {/* Quick access bar - always render for consistent transitions */}
      {keychain.quickAccessEnabled && keychain.quickAccess?.length > 0 && (
        <div 
          className={`QuickAccessBar ${quickAccessHidden ? "closed" : "peeking"}`}
          style={{ 
            visibility: (window.innerWidth > 768) ? 'hidden' : 'visible',
            pointerEvents: quickAccessHidden ? 'none' : 'auto',
            transform: `translate3d(0, ${quickAccessHidden ? '100%' : '0'}, 0)`
          }}
          onTouchStart={(e) => {
            // Detect touch on the draggable indicator
            const rect = e.currentTarget.getBoundingClientRect();
            const touchY = e.touches[0].clientY;
            const isTopArea = touchY < (rect.top + 40); // Top 40px is draggable
            
            if (isTopArea) {
              e.currentTarget.dataset.dragging = 'true';
              e.currentTarget.dataset.startY = touchY.toString();
            }
          }}
          onTouchMove={(e) => {
            const element = e.currentTarget;
            
            // Only process if dragging
            if (element.dataset.dragging === 'true') {
              const startY = parseFloat(element.dataset.startY || '0');
              const currentY = e.touches[0].clientY;
              const diffY = currentY - startY;
              
              // Only react to deliberate drags
              if (Math.abs(diffY) > 30) {
                // SIMPLE LOGIC - same as scroll and touch:
                
                // 1. Dragging DOWN = HIDE
                if (diffY > 0) {
                  setQuickAccessHidden(true);
                }
                // 2. Dragging UP = SHOW 
                else {
                  setQuickAccessHidden(false);
                }
                
                // End dragging
                element.dataset.dragging = 'false';
              }
            }
          }}
          onTouchEnd={(e) => {
            e.currentTarget.dataset.dragging = 'false';
          }}
        >
          <QuickAccess keys={keychain.quickAccess} keychain={keychain} />
        </div>
      )}
    </KeychainLayout>
  )
}

export default Keychain