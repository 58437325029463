// ThemeContext.js
import { createContext, useContext } from 'react';

// Theme context
export const ThemeContext = createContext({
  isDarkMode: false,
  toggleTheme: () => {},
});

export const useTheme = () => useContext(ThemeContext);

// Update PWA theme-color meta tag based on current theme
export const updatePWAThemeColor = (isDarkMode) => {
  const metaThemeColor = document.querySelector('meta[name="theme-color"]');
  if (metaThemeColor) {
    metaThemeColor.setAttribute('content', isDarkMode ? '#000000' : '#FFFFFD');
  }
};

// Theme definitions
export const lightTheme = {
  background: '#FFFFFD',
  sideMenuBackground: 'linear-gradient(135deg, #F3F3F1, #FBFBF9)',
  text: '#191918',
  secondaryText: '#61615F',
  tertiaryText: '#949491',
  menuActiveBackground: '#E5E5E2',
  menuHoverBackground: 'rgba(0, 0, 0, 0.03)',
  buttonBackground: '#191918',
  buttonHoverBackground: '#61615F',
  buttonText: 'white',
  boxShadow: '4px 0 10px rgba(0, 0, 0, 0.1)',
  modalBackground: 'white',
  contentBackground: '#FFFFFD',
  borderColor: 'rgba(0, 0, 0, 0.05)',
  scrollbarThumb: 'rgba(0, 0, 0, 0.2)',
  accent: '#FF6C64',
  accentColor: '#FF6C64',
  // Keychain specific theme properties
  accentGradient: '#FF8E66',
  keychainBackground: '#F3F3F1',
  keychainBackgroundLight: '#FBFBF9',
  keychainBorder: '#BDBDBA',
  keychainQuickAccessBackground: 'rgba(255, 255, 255, 0.85)',
  keychainQuickAccessBorder: 'rgba(255, 255, 255, 0.8)',
  keychainQuickAccessShadow: 'rgba(0, 0, 0, 0.1)',
  keychainSliderBackground: '#E5E5E2',
  keychainSliderKnob: '#FFFFFD',
  keychainOverlay: 'rgba(0, 0, 0, 0.05)',
  // Calendar specific theme properties
  calendarDayBg: '#FBFBF9',
  calendarStripedBg: '#F3F3F1',
  calendarBorder: '#E5E5E2',
  calendarToolbarBg: '#F3F3F1',
  calendarNowText: 'white',
  calendarNowBg: 'black',
  calendarTodayBg: '#f5f5f5',
  calendarWeekendTextColor: '#a3a3a3',
  calendarDashedBorder: '#E5E5E2',
  calendarPatternBg: 'rgba(239, 239, 239, 1)',
  calendarHoverBg: 'rgba(0, 0, 0, 0.05)',
}

export const darkTheme = {
  background: '#000000',
  sideMenuBackground: 'linear-gradient(135deg, #121212, #000000)',
  text: '#F3F3F1',
  secondaryText: '#C7C7C5',
  tertiaryText: '#A7A7A5',
  menuActiveBackground: '#161618',
  menuHoverBackground: 'rgba(255, 255, 255, 0.03)',
  buttonBackground: '#F3F3F1',
  buttonHoverBackground: '#C7C7C5',
  buttonText: '#191918',
  boxShadow: '4px 0 10px rgba(0, 0, 0, 0.3)',
  modalBackground: '#121212',
  contentBackground: '#000000',
  borderColor: 'rgba(255, 255, 255, 0.05)',
  scrollbarThumb: 'rgba(255, 255, 255, 0.2)',
  accent: '#FF6C64',
  accentColor: '#FF6C64',
  // Keychain specific theme properties
  accentGradient: '#FF8E66',
  keychainBackground: '#161618',
  keychainBackgroundLight: '#262626',
  keychainBorder: '#404040',
  keychainQuickAccessBackground: 'rgba(15, 15, 15, 0.85)',
  keychainQuickAccessBorder: 'rgba(30, 30, 30, 0.8)',
  keychainQuickAccessShadow: 'rgba(0, 0, 0, 0.3)',
  keychainSliderBackground: '#2A2A2A',
  keychainSliderKnob: '#C7C7C5',
  keychainOverlay: 'rgba(255, 255, 255, 0.05)',
  // Calendar specific theme properties
  calendarDayBg: '#0a0a0a',
  calendarStripedBg: '#191919',
  calendarBorder: '#171717',
  calendarToolbarBg: '#161618',
  calendarNowText: 'black',
  calendarNowBg: '#F3F3F1',
  calendarTodayBg: '#222222',
  calendarWeekendTextColor: '#666666',
  calendarDashedBorder: '#171717',
  calendarPatternBg: '#171717',
  calendarHoverBg: 'rgba(255, 255, 255, 0.08)',
}