import moment from "moment";
import React, { useMemo } from "react";
import { getTagColor } from "../../views/Events";
import styled from "styled-components";
import API from "../../API";
import { useTheme } from '../../ThemeContext';
import { NewEventButtonSkeleton, UpcomingEventSkeleton, SkeletonGroup } from '../Skeleton';

const UpcomingEventsWrapper = styled.div`
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    height: calc(90vh - 20px);
    overflow-y: auto;
    
    @media (max-width: 1080px) {
        padding: 0;
        overflow-x: auto;
        width: 100%;
    }
`;

const CreateEventButton = styled.div`
  padding: 1.25rem;
  border-radius: 1rem;
  background: ${props => props.theme.buttonBackground};
  color: ${props => props.theme.buttonText};
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;

  &:hover, &:active {
    background: ${props => props.theme.buttonHoverBackground};
  }
`;

const EventCard = styled.div`
  padding: 1.5rem;
  border-radius: 0.75rem;
  background-color: ${props => props.backgroundColor || (props.theme.isDarkMode ? '#2A2A2A' : '#ffffff')};
  color: ${props => props.textColor || props.theme.text};
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;

  &:hover, &:active {
    filter: brightness(${props => props.theme.isDarkMode ? '1.12' : '0.96'});
  }
`;

const EventHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const EventDate = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
  margin: 0;
`;

const Category = styled.span`
  font-size: 0.75rem;
  color: ${props => props.color};
  font-weight: 600;
`;

const EventTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0 0 0.75rem 0;
  color: inherit;
`;

const EventDescription = styled.div`
  font-size: 0.875rem;
  line-height: 1.5;
  color: inherit;
  margin-bottom: 1rem;
  
  /* Rich Text Styles */
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0.75rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 1rem;
    color: inherit;
  }
  
  p {
    margin-bottom: 0.5rem;
  }
  
  ul, ol {
    padding-left: 1.5rem;
    margin-bottom: 0.75rem;
  }
  
  li {
    margin-bottom: 0.25rem;
  }
  
  blockquote {
    border-left: 3px solid ${props => props.theme.isDarkMode ? '#444' : '#ddd'};
    padding-left: 0.75rem;
    color: ${props => props.theme.isDarkMode ? '#aaa' : '#555'};
    margin-left: 0;
    margin-right: 0;
  }
  
  a {
    color: ${props => props.theme.isDarkMode ? '#4f8eff' : '#2563eb'};
    text-decoration: none;
    font-weight: 600;
    
    &:hover {
      text-decoration: underline;
    }
  }
  
  img {
    max-width: 100%;
    height: auto;
    margin: 0.5rem 0;
    border-radius: 4px;
  }
  
  /* Truncate long content with ellipsis */
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;

`;

const TagsWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-top: 1rem;
`;

const Tag = styled.span`
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0.5rem 0.75rem;
    border-radius: 10px;
    background-color: ${props => props.background || (props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.06)')};
    color: inherit;
    backdrop-filter: blur(4px);
  
    svg {
        margin-right: 0.25rem;
        height: 0.875rem;
        width: 0.875rem;
    }
`;

const EventItem = ({ event, onClick }) => {
    const { isDarkMode } = useTheme();
    const { backgroundColor, color } = getTagColor(
        event.tags?.[0]?.color || "default", 
        isDarkMode
    );
    const startMoment = moment(event.start);
    const endMoment = moment(event.end);

    return (
        <EventCard backgroundColor={backgroundColor} textColor={color} onClick={() => onClick(event)}>
            <EventHeader>
                <EventDate>{startMoment.format("MMM Do")}</EventDate>
                {event.tags?.[0] && (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        <Category color={color}>{event.tags[0].name}</Category>
                        {event.tags.length > 1 && (
                            <span style={{ 
                                fontSize: '0.65rem', 
                                opacity: 0.7,
                                color: color // Use the same color with opacity
                            }}>
                                +{event.tags.length - 1}
                            </span>
                        )}
                    </div>
                )}
            </EventHeader>

            <EventTitle>{event.title}</EventTitle>
            <EventDescription dangerouslySetInnerHTML={{ __html: event.description || '' }} />

            <TagsWrapper>
                {event.hasTime && !event.allDay && (
                    <Tag>
                        <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.7}
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            />
                        </svg>
                        {startMoment.format("HH:mm")}
                        {!startMoment.isSame(endMoment) && (
                            <> - {endMoment.format("HH:mm")}</>
                        )}
                    </Tag>
                )}

                <Tag>
                    <svg
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.7}
                        stroke="currentColor"
                    >
                        <path d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                        <path d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                    </svg>
                    {event.location}
                </Tag>
            </TagsWrapper>
        </EventCard>
    );
};

export function UpcomingEvents({ events, onEventClick, onCreateClick, isLoading }) {
    const { isDarkMode } = useTheme();
    const today = moment().startOf("day");

    // Show skeleton loading UI when data is loading
    if (isLoading) {
        return (
            <UpcomingEventsWrapper style={{ opacity: 0.8 }}>
                <NewEventButtonSkeleton />
                {[1, 2, 3, 4].map(i => (
                    <UpcomingEventSkeleton key={i} />
                ))}
            </UpcomingEventsWrapper>
        );
    }

    const upcomingEvents = useMemo(() => {
        const now = moment(); // Current date and time
        
        return events
            .filter((event) => {
                if (!event.hasTime) {
                    // For all-day events, check if the date is today or in the future
                    return moment(event.start).isSameOrAfter(today, 'day');
                } else {
                    // For events with time, check if the event is in the future
                    return moment(event.start).isAfter(now);
                }
            })
            .sort((a, b) => moment(a.start).diff(moment(b.start)));
    }, [events, today]);

    return (
        <UpcomingEventsWrapper>
            {onCreateClick && (
                <CreateEventButton onClick={onCreateClick}>
                    <span>New Event</span>
                    <span className="material-symbols-outlined">add</span>
                </CreateEventButton>
            )}
            {upcomingEvents.length > 0 ? (
                upcomingEvents.map((event) => (
                    <EventItem
                        key={event.id}
                        event={event}
                        onClick={onEventClick}
                    />
                ))
            ) : (
                <div style={{ 
                    padding: '20px', 
                    textAlign: 'center', 
                    color: isDarkMode ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0.6)',
                    fontStyle: 'italic'
                }}>
                    No upcoming events found
                </div>
            )}
        </UpcomingEventsWrapper>
    );
}