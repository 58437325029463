import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const ToolbarContainer = styled.div`
  padding: 16px 20px;
  width: 100%;
  background: ${props => props.theme.isDarkMode ? 
    '#161618' : 
    'rgba(245,245,245,0.8)'};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 24px;
  box-shadow: ${props => props.theme.isDarkMode ? 
    '0 6px 20px rgba(0, 0, 0, 0.2)' : 
    '0 6px 20px rgba(0, 0, 0, 0.05)'};
  backdrop-filter: blur(10px);

  /* Standard button styling within toolbar */
  button {
    margin: 0;
    padding: 10px 16px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 8px;
    border: none;
    background: ${props => props.theme.buttonBackground};
    color: ${props => props.theme.buttonText};
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  
  button i {
    margin-right: 6px;
    font-size: 15px;
  }
  
  button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  button:hover:not(:disabled) {
    background: ${props => props.theme.buttonHoverBackground};
  }

  /* Link styling to match buttons */
  a {
    display: inline-flex;
    align-items: center;
    padding: 10px 16px;
    background: ${props => props.theme.buttonBackground};
    color: ${props => props.theme.buttonText};
    border-radius: 8px;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    transition: all 0.2s ease-in-out;
  }

  a:hover {
    background: ${props => props.theme.buttonHoverBackground};
  }

  /* Input styling within toolbar */
  input[type="text"], 
  input[type="search"],
  input[type="number"] {
    padding: 8px 12px;
    border: 1px solid ${props => props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)'};
    border-radius: 6px;
    background-color: ${props => props.theme.isDarkMode ? 'rgba(30, 30, 30, 0.8)' : '#FFFFFF'};
    color: ${props => props.theme.text};
    font-size: 14px;
    transition: all 0.2s ease;
    
    &:focus {
      outline: none;
      border-color: ${props => props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.3)'};
      box-shadow: 0 0 0 2px ${props => props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)'};
    }
    
    &::placeholder {
      color: ${props => props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.3)'};
    }
  }

  /* Checkbox styling */
  input[type="checkbox"] {
    margin-right: 6px;
    width: 16px;
    height: 16px;
  }

  /* Label styling */
  label {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${props => props.theme.text};
  }

  /* Search filters container */
  .search-filters {
    display: flex;
    gap: 12px;
    flex: 1;
  }
  
  .search-input {
    flex: 1;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    padding: 12px;
    
    button, a {
      justify-content: center;
      width: 100%;
      padding: 8px 10px;
      font-size: 13px;
    }
    
    .search-filters {
      flex-direction: column;
      width: 100%;
    }
  }
`;

const ToolbarSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    justify-content: ${props => props.position === 'right' ? 'flex-end' : 'flex-start'};
    width: 100%;
  }
`;

/**
 * A standardized toolbar component for use across the application
 * 
 * @param {React.ReactNode} leftElements - Elements to display on the left side of the toolbar
 * @param {React.ReactNode} rightElements - Elements to display on the right side of the toolbar
 * @param {string} className - Additional class names to apply to the toolbar
 */
const Toolbar = ({ leftElements, rightElements, className }) => {
  return (
    <ToolbarContainer className={`toolbar ${className || ''}`}>
      <ToolbarSection position="left">
        {leftElements}
      </ToolbarSection>
      {rightElements && (
        <ToolbarSection position="right">
          {rightElements}
        </ToolbarSection>
      )}
    </ToolbarContainer>
  );
};

// Export a standardized button component for use with the toolbar
export const ToolbarButton = ({ children, icon, onClick, disabled }) => (
  <button onClick={onClick} disabled={disabled}>
    {icon && <i className={`fas fa-${icon}`}></i>}
    {children}
  </button>
);

// Export a standardized link component for use with the toolbar
export const ToolbarLink = ({ to, children, icon }) => (
  <Link to={to}>
    {icon && <i className={`fas fa-${icon}`}></i>}
    {children}
  </Link>
);

export default Toolbar;