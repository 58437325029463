import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTheme } from '../../ThemeContext';

const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;

  .EventModal {
      background-color: ${props => props.background || (props.theme.isDarkMode ? props.theme.modalBackground : 'white')};
      padding: 2.5rem;
      border-radius: 24px;
      width: 55%;
      max-height: 85vh;
      overflow-y: auto;
      position: relative;
      box-shadow: ${props => props.theme.isDarkMode ? '0 8px 32px rgba(0, 0, 0, 0.3)' : '0 8px 32px rgba(0, 0, 0, 0.15)'};
      transition: transform 0.3s ease;
      color: ${props => props.theme.text};

    h2 {
      font-size: 36px;
      font-weight: 700;
      line-height: 150%;
    }

    h3 {
      font-size: 28px;
      font-weight: 700;
      line-height: 150%;
    }

    .description {
      font-size: 18px;
      font-weight: 500;
    }

    .tag {
      font-size: 16px;
      padding: 8px 16px;
    }

    .tag svg {
      margin-right: 6px;
    }

    button {
      background-color: transparent;
      border: none !important;
      color: ${props => props.theme.text};
      cursor: pointer;
      padding: 0;
      margin: 0;
      align-items: center;
      text-align: center;
    }

    .deleteBtn {
      margin-top: 6px;
      background-color: white;
      padding: 8px 16px;
      border-radius: 20px;
    }

    .virtual {
      max-width: 300px;
      white-space: nowrap;
      justify-content: space-between;
    }

    .link-text {
      color: ${props => props.theme.text};
      width: 95%;
      text-decoration: none;
      overflow: auto;
      -ms-overflow-style: none; 
      scrollbar-width: none;
      cursor: auto;
      -webkit-user-select: text;
      -moz-select: text;
      -ms-select: text;
      user-select: text;
    }

    .link-text::-webkit-scrollbar {
      display: none;
    }

    .link-text:hover {
      text-decoration: none;
    }

    .details {
      padding-bottom: 1em;
    }

    .organizatorsWrapper {
      display: flex;
      font-size: 16px;
      font-weight: 500;
      align-items: center;
      flex-wrap: wrap;
    }

    .organizator {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .participantsWrapper {
      display: flex;
      flex-wrap: wrap;
    }

    .participantsWrapper,
    .organizatorsWrapper {
      gap: 6px;
      margin-top: 1em;

      .label {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }

  @media (max-width: 1080px) {
    .EventModal {
      width: 90%;
      padding: 1.5em;

      h2 {
        font-size: 26px;
      }

      h3 {
        font-size: 18px;
      }

      h4 {
        font-size: 14px;
      }

      .description {
        font-size: 16px;
      }

      .tag {
        font-size: 14px;
      }

      .virtual {
        max-width: 300px;
        white-space: nowrap;
        justify-content: space-between;
      }

      .link-text {
        color: #232323;
        width: 95%;
        text-decoration: none;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        cursor: auto;
        -webkit-user-select: text;
        -moz-select: text;
        -ms-select: text;
        user-select: text;
      }

      .link-text::-webkit-scrollbar {
        display: none;
      }

      .link-text:hover {
        text-decoration: none;
      }

      .details {
        padding-bottom: 1em;
      }

      .organizatorsWrapper {
        display: flex;
        gap: 6px;
        margin-top: 1em;
        font-size: 14px;
        font-weight: 500;
        align-items: center;
      }

      .organizator {
        display: flex;
        align-items: center;
        gap: 2px;
      }
    }
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

const OrganizatorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5em;
`;

const AddButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.text};
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 6px;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${props => props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.1)' : '#f5f5f5'};
  }

  svg {
    width: 16px;
    height: 16px;
    stroke: ${props => props.theme.text};
  }
`;

const NewOrganizerModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${props => props.theme.isDarkMode ? props.theme.modalBackground : 'white'};
  color: ${props => props.theme.text};
  padding: 2em;
  border-radius: 16px;
  width: 90%;
  max-width: 500px;
  z-index: 1100;
  box-shadow: ${props => props.theme.isDarkMode ? 
    '0 4px 6px -1px rgba(0, 0, 0, 0.3), 0 2px 4px -2px rgba(0, 0, 0, 0.2)' : 
    '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)'};
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
`;

const Label = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.text};
`;

const Input = styled.input`
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.isDarkMode ? '#404040' : '#e5e5e5'};
  font-size: 16px;
  font-family: inherit;
  background-color: ${props => props.theme.isDarkMode ? '#1A1A1A' : 'white'};
  color: ${props => props.theme.text};

  &:focus {
    outline: none;
    border-color: ${props => props.theme.isDarkMode ? '#C7C7C5' : '#232323'};
  }
`;

const TextArea = styled.textarea`
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.isDarkMode ? '#404040' : '#e5e5e5'};
  font-size: 16px;
  font-family: inherit;
  min-height: 120px;
  resize: vertical;
  background-color: ${props => props.theme.isDarkMode ? '#1A1A1A' : 'white'};
  color: ${props => props.theme.text};

  &:focus {
    outline: none;
    border-color: ${props => props.theme.isDarkMode ? '#C7C7C5' : '#232323'};
  }
`;

const QuillEditor = styled(ReactQuill)`
  .ql-container {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background: ${props => props.theme.isDarkMode ? '#1A1A1A' : '#fff'};
    font-family: inherit;
    font-size: 16px;
    min-height: 120px;
    border-color: ${props => props.theme.isDarkMode ? '#404040' : '#ccc'};
    color: ${props => props.theme.text};
  }
  
  .ql-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background: ${props => props.theme.isDarkMode ? '#262626' : '#f9f9f9'};
    border-color: ${props => props.theme.isDarkMode ? '#404040' : '#ccc'};
    
    .ql-stroke {
      stroke: ${props => props.theme.isDarkMode ? '#C7C7C5' : '#444'};
    }
    
    .ql-fill {
      fill: ${props => props.theme.isDarkMode ? '#C7C7C5' : '#444'};
    }
    
    .ql-picker {
      color: ${props => props.theme.text};
    }
  }
  
  .ql-editor {
    min-height: 120px;
    color: ${props => props.theme.text};
    
    &.ql-blank::before {
      color: ${props => props.theme.isDarkMode ? '#666' : '#999'};
    }
  }
`;

const Select = styled.select`
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${props => props.theme.isDarkMode ? '#404040' : '#e5e5e5'};
  font-size: 16px;
  font-family: inherit;
  background-color: ${props => props.theme.isDarkMode ? '#1A1A1A' : 'white'};
  color: ${props => props.theme.text};

  &:focus {
    outline: none;
    border-color: ${props => props.theme.isDarkMode ? '#C7C7C5' : '#232323'};
  }
  
  option {
    background-color: ${props => props.theme.isDarkMode ? '#1A1A1A' : 'white'};
    color: ${props => props.theme.text};
  }
`;

const TimeInputsWrapper = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
  width: 100%;
`;

const CheckboxGroup = styled.div`
  display: flex;
  gap: 2em;
  margin-top: 0.5em;
`;

const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5em;
  cursor: pointer;

  input[type="checkbox"] {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  margin-top: 0.5em;
`;

const Tag = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 6px 12px;
  border-radius: 20px;
  background-color: ${props => props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.1)' : '#f5f5f5'};
  font-size: 14px;
  color: ${props => props.theme.text};

  button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${props => props.theme.isDarkMode ? '#A7A7A5' : '#666'};

    &:hover {
      color: ${props => props.theme.text};
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  margin-top: 1em;
`;

const Button = styled.button`
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;

  ${props =>
    props.primary
        ? `
        background-color: ${props.theme.buttonBackground};
        color: ${props.theme.buttonText};
        border: none;

        &:hover {
          background-color: ${props.theme.buttonHoverBackground};
        }
      `
        : `
        background-color: ${props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'white'};
        color: ${props.theme.text};
        border: 1px solid ${props.theme.isDarkMode ? '#404040' : '#e5e5e5'};

        &:hover {
          background-color: ${props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.15)' : '#f5f5f5'};
        }
      `}
`;

const DateTimeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const CloseButton = styled.button`
    background-color: ${props => props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)'};
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    color: ${props => props.theme.text};
    padding: 10px;

    &:hover {
        background-color: ${props => props.theme.isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)'};
        transform: rotate(90deg);
    }

    svg {
        width: 30px;
        height: 30px;
    }
`;

const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    h2 {
        font-size: 2.5rem;
        font-weight: 800;
        letter-spacing: -0.02em;
        color: ${props => props.theme.text};
        margin: 0;

        @media (max-width: 1080px) {
            font-size: 2rem;
        }
    }
`;

const CloseIcon = () => (
    <svg
        height="40px"
        width="40px"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        aria-hidden="true"
    >
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
    </svg>
);

const CreateModal = ({
                         event: initialEvent,
                         onClose,
                         onSubmit,
                         tags = [],
                         organizators = [],
                         participants = [],
                         onCreateOrganizator,
                         onCreateParticipant,
                         onCreateTag,
                     }) => {
    const { isDarkMode, theme } = useTheme();
    const [showNewOrganizerModal, setShowNewOrganizerModal] = useState(false);
    const [newOrganizer, setNewOrganizer] = useState({ name: '' });
    const [showNewParticipantModal, setShowNewParticipantModal] = useState(false);
    const [newParticipant, setNewParticipant] = useState({ name: '' });
    const [showNewTagModal, setShowNewTagModal] = useState(false);
    const [newTag, setNewTag] = useState({ name: '', color: '' });

    const [formData, setFormData] = useState({
        title: initialEvent?.title || '',
        description: initialEvent?.description || '',
        date: initialEvent ? moment(initialEvent.start).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        startTime: initialEvent ? moment(initialEvent.start).format('HH:mm') : '09:00',
        endTime: initialEvent ? moment(initialEvent.end).format('HH:mm') : '10:00',
        hasTime: initialEvent?.hasTime ?? true,
        allDay: initialEvent?.allDay ?? false,
        location: initialEvent?.location || 'Online',
        url: initialEvent?.url || null,
        selectedTags: [],
        participants: initialEvent?.participants || [],
        tags: initialEvent?.tags || [],
        organizator: initialEvent?.organizators || [],
    });

    useEffect(() => {
        if (initialEvent) {
            setFormData({
                title: initialEvent.title || '',
                description: initialEvent.description || '',
                date: moment(initialEvent.start).format('YYYY-MM-DD'),
                startTime: moment(initialEvent.start).format('HH:mm'),
                endTime: moment(initialEvent.end).format('HH:mm'),
                hasTime: initialEvent.hasTime,
                allDay: initialEvent.allDay,
                location: initialEvent.location || 'Online',
                url: initialEvent.url || null,
                selectedTags: initialEvent.tags || [],
                participants: initialEvent.participants || [],
                tags: initialEvent.tags || [],
                organizator: initialEvent.organizators?.[0]?.name || '',
            });
        }
    }, [initialEvent]);

    const handleInputChange = (name, value) => {
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const tagIds = formData.selectedTags
            .map(tag => typeof tag === 'object' ? tag.id : tags.find(t => t.name === tag)?.id)
            .filter(id => id !== null && id !== undefined);

        const participantIds = formData.participants
            .map(participant => participant.id)
            .filter(id => id !== null);

        const organizatorIds = formData.organizator
            ? [organizators.find(o => o.name === formData.organizator)?.id].filter(id => id !== null)
            : [];

        const eventData = {
            ...formData,
            start: new Date(formData.date),
            end: new Date(formData.date),
            participants: participantIds.join(','),
            tags: tagIds.join(','),
            organizator: organizatorIds.join(','),
        };

        if (!formData.allDay && formData.hasTime) {
            const [startHours, startMinutes] = formData.startTime.split(':');
            const [endHours, endMinutes] = formData.endTime.split(':');

            eventData.start.setHours(parseInt(startHours), parseInt(startMinutes));
            eventData.end.setHours(parseInt(endHours), parseInt(endMinutes));
        }
        if (initialEvent) {
            eventData.id = initialEvent.id;
        }
        onSubmit(eventData);
    };

    return (
        <ModalContainer>
            <div className="EventModal">
                <Header>
                    <h2>{initialEvent ? 'Update Event' : 'Create Event'}</h2>
                    <CloseButton onClick={onClose} aria-label="Close modal">
                        <CloseIcon />
                    </CloseButton>
                </Header>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label>Event Title</Label>
                        <Input
                            type="text"
                            value={formData.title}
                            onChange={(e) => handleInputChange('title', e.target.value)}
                            placeholder="Enter event title"
                            required
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label>Description</Label>
                        <QuillEditor
                            value={formData.description || ''}
                            onChange={(content) => handleInputChange('description', content)}
                            placeholder="Enter event description"
                            modules={{
                                toolbar: [
                                    [{ 'header': [1, 2, 3, false] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{'list': 'ordered'}, {'list': 'bullet'}],
                                    ['link', 'image'],
                                    ['clean']
                                ],
                            }}
                        />
                    </FormGroup>

                    <DateTimeContainer>
                        <FormGroup>
                            <Label>Date</Label>
                            <Input
                                type="date"
                                value={formData.date}
                                onChange={(e) => handleInputChange('date', e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label>Time Settings</Label>
                            <CheckboxGroup>
                                <CheckboxWrapper>
                                    <input
                                        type="checkbox"
                                        checked={formData.hasTime}
                                        onChange={(e) => handleInputChange('hasTime', e.target.checked)}
                                    />
                                    <span>Specify Time</span>
                                </CheckboxWrapper>

                                <CheckboxWrapper>
                                    <input
                                        type="checkbox"
                                        checked={formData.allDay}
                                        onChange={(e) => handleInputChange('allDay', e.target.checked)}
                                    />
                                    <span>All Day</span>
                                </CheckboxWrapper>
                            </CheckboxGroup>

                            {formData.hasTime && !formData.allDay && (
                                <TimeInputsWrapper>
                                    <FormGroup>
                                        <Label>Start Time</Label>
                                        <Input
                                            type="time"
                                            value={formData.startTime}
                                            onChange={(e) => handleInputChange('startTime', e.target.value)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>End Time</Label>
                                        <Input
                                            type="time"
                                            value={formData.endTime}
                                            onChange={(e) => handleInputChange('endTime', e.target.value)}
                                        />
                                    </FormGroup>
                                </TimeInputsWrapper>
                            )}
                        </FormGroup>
                    </DateTimeContainer>

                    <DateTimeContainer>
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
                                type="text"
                                value={formData.location || "Online"}
                                onChange={(e) => handleInputChange('location', e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label>Virtual Meeting Link</Label>
                            <Input
                                type="url"
                                value={formData.virtual}
                                onChange={(e) => handleInputChange('virtual', e.target.value)}
                                placeholder="Enter meeting link"
                            />
                        </FormGroup>
                    </DateTimeContainer>

                    <FormGroup>
                        <OrganizatorHeader>
                            <Label>Tags</Label>
                            <AddButton type="button" onClick={() => setShowNewTagModal(true)}>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                                Add New
                            </AddButton>
                        </OrganizatorHeader>

                        <Select
                            value=""
                            onChange={(e) => {
                                if (e.target.value) {
                                    const selectedTag = tags.find(t => t.name === e.target.value);
                                    if (selectedTag && !formData.selectedTags.find(t => 
                                        (typeof t === 'object' && t.id === selectedTag.id) || 
                                        t === selectedTag.name
                                    )) {
                                        handleInputChange('selectedTags', [...formData.selectedTags, selectedTag]);
                                    }
                                }
                            }}
                        >
                            <option value="">Select a tag</option>
                            {tags
                                .filter(tag => !formData.selectedTags.some(
                                    t => (typeof t === 'object' && t.id === tag.id) || t === tag.name
                                ))
                                .map((tag) => (
                                    <option key={tag.id} value={tag.name}>
                                        {tag.name}
                                    </option>
                                ))
                            }
                        </Select>
                        
                        <TagsContainer>
                            {formData.selectedTags.map((tag, index) => (
                                <Tag key={index}>
                                    {typeof tag === 'object' ? tag.name : tag}
                                    <button type="button" onClick={() => {
                                        handleInputChange('selectedTags',
                                            formData.selectedTags.filter((_, i) => i !== index)
                                        );
                                    }}>
                                        <svg height="14px" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </Tag>
                            ))}
                        </TagsContainer>
                    </FormGroup>

                    <FormGroup>
                        <OrganizatorHeader>
                            <Label>Organizator</Label>
                            <AddButton type="button" onClick={() => setShowNewOrganizerModal(true)}>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                                Add New
                            </AddButton>
                        </OrganizatorHeader>
                        <Select
                            value={formData.organizator || {}}
                            onChange={(e) => handleInputChange('organizator', e.target.value)}
                        >
                            <option value="">Select an organizator</option>
                            {organizators.map((org) => (
                                <option key={org.id} value={org.name}>
                                    {org.name}
                                </option>
                            ))}
                        </Select>
                    </FormGroup>

                    <FormGroup>
                        <OrganizatorHeader>
                            <Label>Participants</Label>
                            <AddButton type="button" onClick={() => setShowNewParticipantModal(true)}>
                                <svg fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                                Add New
                            </AddButton>
                        </OrganizatorHeader>
                        <Select
                            value=""
                            onChange={(e) => {
                                const selectedParticipant = participants.find(p => p.id === parseInt(e.target.value));
                                if (selectedParticipant && !formData.participants.find(p => p.id === selectedParticipant.id)) {
                                    handleInputChange('participants', [...formData.participants, selectedParticipant]);
                                }
                            }}
                        >
                            <option value="">Select a participant</option>
                            {participants
                                .filter(p => !formData.participants.find(selected => selected.id === p.id))
                                .map((participant) => (
                                    <option key={participant.id} value={participant.id}>
                                        {participant.name}
                                    </option>
                                ))}
                        </Select>
                        <TagsContainer>
                            {formData.participants.map((participant) => (
                                <Tag key={participant.id}>
                                    {participant.name}
                                    <button type="button" onClick={() => {
                                        handleInputChange('participants',
                                            formData.participants.filter(p => p.id !== participant.id)
                                        );
                                    }}>
                                        <svg height="14px" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </Tag>
                            ))}
                        </TagsContainer>
                    </FormGroup>

                    <ButtonGroup>
                        <Button type="button" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button type="submit" primary>
                            {initialEvent ? 'Update Event' : 'Create Event'}
                        </Button>
                    </ButtonGroup>
                </Form>
            </div>
            {showNewOrganizerModal && (
                <>
                    <Overlay onClick={() => setShowNewOrganizerModal(false)} />
                    <NewOrganizerModal>
                        <div className="header">
                            <h3>New Organizator</h3>
                            <button onClick={() => setShowNewOrganizerModal(false)}>
                                <svg height="24px" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>

                        <Form onSubmit={async (e) => {
                            e.preventDefault();
                            const newOrg = await onCreateOrganizator(newOrganizer);
                            if (newOrg) {
                                handleInputChange('organizator', [...formData.organizator, newOrg]);
                                setShowNewOrganizerModal(false);
                                setNewOrganizer({ name: '' });
                            }
                        }}>
                            <FormGroup>
                                <Label>Name</Label>
                                <Input
                                    type="text"
                                    value={newOrganizer.name || ''}
                                    onChange={(e) => setNewOrganizer({ ...newOrganizer, name: e.target.value })}
                                    placeholder="Enter organizator name"
                                    required
                                />
                            </FormGroup>
                            <ButtonGroup>
                                <Button type="button" onClick={() => setShowNewOrganizerModal(false)}>
                                    Cancel
                                </Button>
                                <Button type="submit" primary>
                                    Create
                                </Button>
                            </ButtonGroup>
                        </Form>
                    </NewOrganizerModal>
                </>
            )}
            {showNewParticipantModal && (
                <>
                    <Overlay onClick={() => setShowNewParticipantModal(false)} />
                    <NewOrganizerModal>
                        <div className="header">
                            <h3>New Participant</h3>
                            <button onClick={() => setShowNewParticipantModal(false)}>
                                <svg height="24px" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>

                        <Form onSubmit={async (e) => {
                            e.preventDefault();
                            const newPart = await onCreateParticipant(newParticipant);
                            if (newPart) {
                                handleInputChange('participant', newPart);
                                setShowNewParticipantModal(false);
                                setNewParticipant({ name: '' });
                            }
                        }}>
                            <FormGroup>
                                <Label>Name</Label>
                                <Input
                                    type="text"
                                    value={newParticipant.name || ''}
                                    onChange={(e) => setNewParticipant({ ...newParticipant, name: e.target.value })}
                                    placeholder="Enter participant name"
                                    required
                                />
                            </FormGroup>
                            <ButtonGroup>
                                <Button type="button" onClick={() => setShowNewParticipantModal(false)}>
                                    Cancel
                                </Button>
                                <Button type="submit" primary>
                                    Create
                                </Button>
                            </ButtonGroup>
                        </Form>
                    </NewOrganizerModal>
                </>
            )}
            {showNewTagModal && (
                <>
                    <Overlay onClick={() => setShowNewTagModal(false)} />
                    <NewOrganizerModal>
                        <div className="header">
                            <h3>New Tag</h3>
                            <button onClick={() => setShowNewTagModal(false)}>
                                <svg height="24px" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>

                        <Form onSubmit={async (e) => {
                            e.preventDefault();
                            const newTagResponse = await onCreateTag(newTag);
                            if (newTagResponse) {
                                handleInputChange("selectedTags", [...formData.selectedTags, newTagResponse]);
                                setShowNewTagModal(false);
                                setNewTag({ name: '', color: 'default' });
                            }
                        }}>
                            <FormGroup>
                                <Label>Name</Label>
                                <Input
                                    type="text"
                                    value={newTag.name || ''}
                                    onChange={(e) => setNewTag({ ...newTag, name: e.target.value })}
                                    placeholder="Enter tag name"
                                    required
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Color</Label>
                                <Select
                                    value={newTag.color || 'default'}
                                    onChange={(e) => setNewTag({ ...newTag, color: e.target.value })}
                                >
                                    <option value="default">Default</option>
                                    <option value="red">Red</option>
                                    <option value="blue">Blue</option>
                                    <option value="green">Green</option>
                                    <option value="yellow">Yellow</option>
                                    <option value="purple">Purple</option>
                                    <option value="pink">Pink</option>
                                    <option value="brown">Brown</option>
                                    <option value="orange">Orange</option>
                                    <option value="gray">Gray</option>
                                </Select>
                            </FormGroup>
                            <ButtonGroup>
                                <Button type="button" onClick={() => setShowNewTagModal(false)}>
                                    Cancel
                                </Button>
                                <Button type="submit" primary>
                                    Create
                                </Button>
                            </ButtonGroup>
                        </Form>
                    </NewOrganizerModal>
                </>
            )}
        </ModalContainer>
    );
};

export default CreateModal;
